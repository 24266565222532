/**
 * AppErrorFallback component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { HTMLAttributes, ReactNode } from 'react';

import { useI18n } from '@/hooks/core';

import { Button, Caption, Text, Title } from '@exode.ru/vkui';
import { Icon16Replay, Icon56WifiOutline } from '@vkontakte/icons';

import { Satellite } from '@/images/kits';

import { If } from '@/components/Utils';


interface Props extends HTMLAttributes<HTMLDivElement> {
    onClick: React.MouseEventHandler<HTMLElement>;
    buttonText?: string;
    isBoundary?: boolean;
    message?: ReactNode;
    caption?: ReactNode;
}


const ErrorFallback = (props: Props) => {

    const { t } = useI18n('components.App.ErrorFallback');

    const {
        onClick,
        caption,
        isBoundary = false,
        buttonText = t('repeatRequest'),
        ...rest
    } = props;

    const message = (
        <>
            <Title level="1">
                {t('occurredError')}
            </Title>

            <Text weight="regular">
                {
                    isBoundary
                        ? t('tryAgainLaterOrContactToSupport')
                        : t('oopsNoConnectionToServer')
                }
            </Text>
        </>
    );

    const icon = isBoundary
        ? <img alt="Satellite" src={Satellite} className="mt-10"/>
        : <Icon56WifiOutline width={72} height={72}/>;

    const boundaryClass = isBoundary
        ? 'justify-start mt-20 font-vk-sans-display gap-8 !text-lg'
        : 'justify-center gap-4';

    return (
        <div className={[
            boundaryClass,
            'flex flex-col items-center text-center p-5',
        ].join(' ')} {...rest}>
            <>{icon}</>

            <>{message}</>

            <Button className="mt-2" size="m" onClick={onClick} before={<Icon16Replay/>}>
                {buttonText}
            </Button>

            <If is={!!caption}>
                <Caption className="mt-2 text-secondary">
                    {caption}
                </Caption>
            </If>
        </div>
    );
};


export { ErrorFallback };
