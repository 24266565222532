/**
 * School store
 *
 * @author: exode <hello@exode.ru>
 */

import { makeAutoObservable } from 'mobx';
import { observer } from 'mobx-react';

import { SchoolManageFragmentFragment } from '@/codegen/graphql';


class School {

    constructor() {
        makeAutoObservable(this);
    }

    /**
     * Id текущей школы
     * @type {number}
     */
    schoolId: number = window.exode.school?.id as number;

    /** Информация о текущей школе (синхронизируется при обновлении) */
    school: SchoolManageFragmentFragment | null = window.exode.school;

    /** Получение настроек блоков */
    get blocks() {
        return this.school?.blockSettings;
    }

    /** Установка блоков */
    setBlocks(
        blockSettings: Partial<SchoolManageFragmentFragment['blockSettings']>,
    ) {
        if (this.school?.blockSettings) {
            this.school.blockSettings = {
                ...this.school.blockSettings,
                ...blockSettings,
            };
        }
    }

    /**
     * Установка значений в поля store
     * @param partial
     */
    merge(partial: Partial<School>) {
        Object.assign(this, partial);
    }

}

const SchoolStore = new School();


export { observer, SchoolStore };
