/**
 * AddMainInfoView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer } from '@/pages/Core';
import { ConfigStore } from '@/store/core/config';

import { MyUserSettingsQuery, ProfileRole, ProfileUpdateMutationResult, UserStateKey } from '@/codegen/graphql';

import { GqlResult } from '@/types/graphql';
import { DocumentEvent } from '@/types/window';

import { Graphql } from '@/cutils';
import { Storage } from '@/api/storage';
import { useLocation } from '@/router/index';

import { Router } from '@/services/Utils/Router';
import { useSetUserState } from '@/hooks/apollo';

import { UserSettingsPersonalInfoView } from '@/pages/User/Settings/views/PersonalInfoView';


interface Props {
    mode: 'page' | 'modal';
}


const AddMainInfoView = observer((props: Props) => {

    const { setUserState } = useSetUserState();
    const { route: { params: { to, toModalParams } } } = useLocation();

    const onUpdate = async (
        profile: GqlResult<ProfileUpdateMutationResult>['profileUpdate'],
    ) => {
        await setUserState(
            UserStateKey.PersonalInfoFilled,
            true,
            { cacheOnly: true },
        );

        /** Dispatch event */
        document.dispatchEvent(
            new CustomEvent(
                DocumentEvent.ProfilePersonalInfoFilledSuccess,
            ),
        );

        Storage.set(
            'user:event-stream',
            { event: DocumentEvent.ProfilePersonalInfoFilledSuccess },
        );

        /** If the next page/modal is empty */
        if (!toModalParams) {
            if (profile.role === ProfileRole.Tutor) {
                await Router.replaceModalWithTimeout();

                return ConfigStore.isDesktop
                    ? Router.pushModal('short-form-partner-become')
                    : Router.pushPage('/become/partner');
            }

            if (!to) {
                Router.replaceModalOrPushPage('/education');
            }
        }
    };

    return (
        <MyUserSettingsQuery children={(result) => (
            <>
                <Graphql.Loading result={result}>
                    <UserSettingsPersonalInfoView isSkeleton placement={props.mode} userInfo={{} as any}/>
                </Graphql.Loading>

                <Graphql.Success result={result}>
                    {({ userGetMyInfo: userInfo }) => (
                        <UserSettingsPersonalInfoView userInfo={userInfo} placement={props.mode} onUpdate={onUpdate}/>
                    )}
                </Graphql.Success>

                <Graphql.Error result={result}/>
            </>
        )}/>
    );
});


export { AddMainInfoView };
