/**
 * UseProductLaunch
 *
 * @author: exode <hello@exode.ru>
 */

import moment from 'moment';

import React from 'react';

import { FormikHelpers, FormikProps } from 'formik';

import { LazyQueryExecFunction } from '@apollo/client';

import { useI18n } from '@/hooks/core';
import { GqlResult } from '@/types/graphql';
import { CourseFindOneManage } from '@/types/course';

import { Notify } from '@/cutils';
import { Router } from '@/services/Utils/Router';

import {
    CheckLaunchProductInput,
    Exact,
    ProductLaunchFindManyDocument,
    ProductLaunchFindManyQuery,
    ProductLaunchFindOneCrossedQuery,
    ProductLaunchFindOneQueryResult,
    PublishLaunchProductInput,
    UpdateLaunchProductInput,
    useProductLaunchCreateMutation,
    useProductLaunchFindOneCrossedLazyQuery,
    useProductLaunchUpdateMutation,
    useProductPublicationManualPublishLaunchMutation,
} from '@/codegen/graphql';

import { ManageLaunchesPageStore } from '@/pages/Manage/Courses/Launches/store';

import { defaultLaunchesFilter } from '@/pages/Manage/Courses/Launches/filter';


interface Props {
    courseId: number;
    launch?: GqlResult<ProductLaunchFindOneQueryResult>['productLaunchFindOne'];
    filter?: Partial<typeof defaultLaunchesFilter>;
    list?: typeof ManageLaunchesPageStore['state']['list'];
    sort?: typeof ManageLaunchesPageStore['state']['sort'];
}


export const useProductLaunch = (props: Props) => {

    const { courseId, launch, filter, sort, list } = props;

    const { t } = useI18n('hooks.apollo.product');

    const getVariables = () => ({
        list: { ...list },
        filter: { ...filter },
        sort: { ...sort },
    });

    const [ getCrossedLaunch, { loading: getCrossedLaunchLoading } ] = useProductLaunchFindOneCrossedLazyQuery({
        notifyOnNetworkStatusChange: true,
        onError: (error) => console.error(error),
    });

    const initialLaunchUpdateData = {
        name: launch?.name || '',
        saleStartAt: launch?.saleStartAt || null,
        saleFinishAt: launch?.saleFinishAt || null,
        educationStartAt: launch?.educationStartAt || null,
        educationFinishAt: launch?.educationFinishAt || null,
    } as UpdateLaunchProductInput;

    const [ createLaunch, { loading: createLaunchLoading } ] = useProductLaunchCreateMutation({
        onError: error => console.error(error),
        update: (cache, { data }) => {
            const variables = getVariables();

            const cachedLaunches = cache.readQuery<ProductLaunchFindManyQuery>({
                query: ProductLaunchFindManyDocument,
                variables,
            });

            if (!cachedLaunches) {
                return console.warn('[Cache]: cachedLaunches отсутствуют в кэше');
            }

            if (data?.productLaunchCreate) {
                const { productLaunchCreate } = data;

                cache.writeQuery<ProductLaunchFindManyQuery>({
                    query: ProductLaunchFindManyDocument,
                    variables,
                    data: {
                        productLaunchFindMany: {
                            __typename: 'ListLaunchProductOutput',
                            count: (cachedLaunches.productLaunchFindMany.count ?? 1) + 1,
                            page: cachedLaunches.productLaunchFindMany.page ?? 1,
                            pages: cachedLaunches.productLaunchFindMany.pages ?? 1,
                            items: [ productLaunchCreate, ...(cachedLaunches.productLaunchFindMany.items ?? []) ],
                        },
                    },
                });

                Router.pushPage(
                    '/manage/course/:courseId([0-9]+)/launches/:page([0-9]+)/:launchId([0-9]+)/settings',
                    {
                        courseId: `${courseId}`,
                        launchId: `${productLaunchCreate.id}`,
                    },
                );
            }

            /** Refetch lessons count in course card */
            cache.evict({
                id: `CourseEntity:${courseId}`,
                fieldName: 'product',
            });
        },
    });

    const [ updateLaunch, { loading: updateLaunchLoading } ] = useProductLaunchUpdateMutation({
        onError(error) {
            console.log(error);

            Notify.vkui({
                message: t('errorSomethingWentWrong'),
                appearance: 'error',
            });
        },
    });

    const [ _manualPublishLaunch, {
        loading: manualPublishLaunchLoading,
    } ] = useProductPublicationManualPublishLaunchMutation({
        onError: error => console.error(error),
    });

    const manualPublishLaunch = (
        launchId: number,
        publication: PublishLaunchProductInput,
    ) => {
        return _manualPublishLaunch({
            variables: { launchId, publication },
        });
    };

    return {
        createLaunch,
        createLaunchLoading,
        updateLaunch,
        updateLaunchLoading,
        getCrossedLaunch,
        getCrossedLaunchLoading,
        manualPublishLaunch,
        manualPublishLaunchLoading,
        initialLaunchUpdateData,
    };
};

export const useLaunchValidation = () => {

    const { t } = useI18n('hooks.apollo.product');

    const getMaxSaleFinishAtDate = (
        course: CourseFindOneManage,
        educationFinishAt: Date,
    ) => {
        return moment(educationFinishAt)
            .subtract(course.minDurationInDays, 'days')
            .toDate();
    };

    const customDateValidation = async (
        formikRef: React.RefObject<FormikProps<UpdateLaunchProductInput>>,
        getCrossedLaunch: LazyQueryExecFunction<ProductLaunchFindOneCrossedQuery, Exact<{
            launchId: number,
            launch: CheckLaunchProductInput
        }>>,
        course: CourseFindOneManage,
        launchId: number,
        field: 'saleStartAt' | 'saleFinishAt' | 'educationFinishAt',
        setFieldError: FormikHelpers<any>['setFieldError'],
    ) => {
        const {
            educationFinishAt,
            saleStartAt,
            saleFinishAt,
        } = formikRef.current?.values || {};

        const isValidEducationFinishBorder = moment(saleFinishAt)
            .add(course.minDurationInDays, 'days')
            .isSameOrBefore(educationFinishAt);

        if (!isValidEducationFinishBorder) {
            setFieldError(
                'saleFinishAt',
                t('shouldBeEarlierThan', {
                    date: moment(educationFinishAt)
                        .subtract(course.minDurationInDays, 'days')
                        .format('DD.MM.YY HH:mm'),
                }),
            );
        }

        /** Check crossed launch */
        if ([ 'saleStartAt', 'saleFinishAt' ].includes(field)) {
            if (!saleStartAt || !saleFinishAt) {
                return;
            }

            const { data: crossedData } = await getCrossedLaunch({
                fetchPolicy: 'network-only',
                variables: {
                    launchId,
                    launch: { saleStartAt, saleFinishAt },
                },
                onError: () => {
                    setFieldError(field, t('invalidDateSpecified'));
                },
            });

            const crossedLaunch = crossedData?.productLaunchFindOneCrossed;

            if (!!crossedLaunch) {
                const message = t('theSaleDatesOverlappedWith', {
                        name: crossedLaunch.name,
                        from: moment(crossedLaunch.saleStartAt).format('DD.MM.YY'),
                        to: moment(crossedLaunch.saleFinishAt).format('DD.MM.YY'),
                    },
                );

                setFieldError(field, message);
            }
        }
    };

    return { getMaxSaleFinishAtDate, customDateValidation };
};
