/**
 * DeleteIconButton component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { cloneElement, ReactElement, useEffect, useState } from 'react';

import { If } from '@/cutils';
import { Time } from '@/utils';
import { useConfirm, useI18n } from '@/hooks/core';

import { IconButton } from '@mui/material';
import { Spinner } from '@exode.ru/vkui';
import { TextTooltip } from '@exode.ru/vkui/unstable';
import { Placement } from '@exode.ru/vkui/dist/components/Popper/Popper';
import { Icon24CancelOutline, Icon24DeleteOutline } from '@vkontakte/icons';

import { ConfirmModalProps } from '@/modals/Utils/Confirmation';


interface Props {
    callback: Function;
    confirmation?: 'click' | 'useConfirm';
    text?: string;
    placement?: Placement;
    replaceDelay?: number;
    regularButton?: ReactElement;
    confirmRegularButton?: ReactElement;
    stopPropagation?: boolean;
    offsetSkidding?: number;
    offsetDistance?: number;
    noWrapper?: boolean;
    loading?: boolean;
    className?: string;
    iconFill?: string;
    dataTest?: string;
    confirmProps?: Record<any, any>;
    initialIcon?: ReactElement;
    confirmIcon?: ReactElement;
    useConfirmProps?: ConfirmModalProps;
}


const DeleteIconButton = (props: Props) => {

    const { t } = useI18n('components.Atoms.DeleteIconButton');

    const {
        callback,
        regularButton,
        confirmRegularButton,
        stopPropagation,
        offsetSkidding,
        offsetDistance,
        noWrapper,
        iconFill,
        initialIcon,
        confirmIcon,
        loading,
        useConfirmProps,
        dataTest,
        confirmation = 'click',
        className = '',
        confirmProps = {},
        text = t('confirmDeletion'),
        placement = 'top',
        replaceDelay = 4000,
    } = props;

    const [ status, setStatus ] = useState<'confirm-delete' | 'delete'>('delete');

    const { openConfirm: openConfirmDelete } = useConfirmProps
        ? useConfirm(useConfirmProps)
        : { openConfirm: () => {} };

    const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        if (confirmation === 'useConfirm') {
            return openConfirmDelete();
        }

        if (status === 'delete') {
            setStatus('confirm-delete');
        }

        if (status === 'confirm-delete') {
            callback();
            setStatus('delete');
        }

        stopPropagation && e.stopPropagation();
    };

    const replaceIcon = async () => {
        await Time.timer(replaceDelay);

        setStatus('delete');
    };

    const children = regularButton
        ? cloneElement(
            status === 'confirm-delete' && confirmRegularButton || regularButton,
            {
                dataTest,
                className,
                onClick: handleClick,
                ...(status === 'confirm-delete' ? confirmProps : {}),
            },
        )
        : (
            <IconButton disabled={loading}
                        data-test={dataTest}
                        onClick={handleClick}
                        className={noWrapper ? className : ''}>
                <If is={!loading}>
                    <If is={status === 'delete'}>
                        {initialIcon || <Icon24CancelOutline fill={iconFill || 'var(--text_secondary)'}/>}
                    </If>

                    <If is={status === 'confirm-delete'}>
                        {confirmIcon || <Icon24DeleteOutline fill="var(--destructive)"/>}
                    </If>
                </If>

                <If is={!!loading}>
                    <Spinner/>
                </If>
            </IconButton>
        );

    useEffect(() => {
        status === 'confirm-delete' && replaceIcon();
    }, [ status ]);

    const Tooltip = (component: ReactElement) => (
        <TextTooltip text={text}
                     children={component}
                     placement={placement}
                     offsetSkidding={offsetSkidding}
                     offsetDistance={offsetDistance}/>
    );

    return (
        <>
            <If is={status === 'confirm-delete'}>
                <If is={!!noWrapper}>
                    {Tooltip(children)}
                </If>

                <If is={!noWrapper}>
                    {Tooltip(
                        <div className={className}>
                            {children}
                        </div>,
                    )}
                </If>
            </If>

            <If is={status === 'delete'}>
                <If is={!!noWrapper}>
                    {children}
                </If>

                <If is={!noWrapper}>
                    <div className={className}>
                        {children}
                    </div>
                </If>
            </If>
        </>
    );
};


export { DeleteIconButton };
