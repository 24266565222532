/**
 * Serve types
 *
 * @author: exode <hello@exode.ru>
 */

export enum AppServeDomain {
    Ru = 'Ru',
    Uz = 'Uz',
    Kz = 'Kz',
    Biz = 'Biz',
    /** Abstract for all */
    Global = 'Global',
}
