/**
 * NotificationHeaderItemPart
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useContext } from 'react';

import { observer } from '@/pages/Core';
import { RouterStore } from '@/store/core/router';

import { useI18n } from '@/hooks/core';

import { Counter, TabbarItem } from '@exode.ru/vkui';
import { Icon28Notifications } from '@vkontakte/icons';

import { If, Link } from '@/cutils';
import { FcmContext } from '@/components/App/FcmProvider/FcmProvider';

import { HeaderItem, StyledTooltip } from '../DesktopPanelHeader.styled';


interface Props {
    count?: number;
}


const NotificationHeaderItemPart = observer(({ count }: Props) => {

    const { t } = useI18n('components.Desktop.Panel.Header');

    const { granted, getWebFcmToken } = useContext(FcmContext);

    const headerItem = (
        <HeaderItem>
            <TabbarItem indicator={(
                <If is={!!count}>
                    <Counter size="s" mode="primary" className={count! <= 9 ? 'small-count' : ''}>
                        {count! > 9 ? '9+' : count}
                    </Counter>
                </If>
            )}>
                <Icon28Notifications fill="var(--accent)"/>
            </TabbarItem>
        </HeaderItem>
    );

    return (
        <>
            <If is={granted}>
                <Link pushPage={{ id: '/notifications' }}>
                    {headerItem}
                </Link>
            </If>

            <If is={!granted}>
                <StyledTooltip offsetDistance={-5}
                               placement="bottom"
                               text={t('allowNotification')}
                               shown={!RouterStore.hasOverlay}>
                    <div onClick={getWebFcmToken}>
                        {headerItem}
                    </div>
                </StyledTooltip>
            </If>
        </>
    );
});


export { NotificationHeaderItemPart };
