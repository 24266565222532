/**
 * FooterLegalPart component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer } from '@/store/core/config';
import { PlatformStore } from '@/store/platform';

import { Link, useRedirect } from '@/cutils';
import { getTranslated, useI18n } from '@/hooks/core';

import { Caption } from '@exode.ru/vkui';


const FooterLegalPart = observer(() => {

    const { COMMON, legalDocs } = PlatformStore;

    const { t } = useI18n('components.Desktop.Navigation.Menu');

    const { redirect } = useRedirect();

    return (
        <div className="flex m:flex-col gap-5 mb-3 mt-1 d:pr-11 pb-safe-1">
            <Link pushPage={{ id: '/about' }}>
                <Caption className="text-secondary flex items-center">
                    <span className="fs-15 pt-0.5 mr-1">©</span>
                    <span>
                        {COMMON?.organization.info.name}
                        {' '}
                        {new Date().getFullYear()}
                    </span>
                </Caption>
            </Link>

            <Link onClick={() => redirect(
                getTranslated(legalDocs?.typical.DataPolicy),
                true,
            )}>
                <Caption className="text-secondary">
                    {t('politics')}
                </Caption>
            </Link>

            <Link onClick={() => redirect(
                getTranslated(legalDocs?.typical.DataConsent),
                true,
            )}>
                <Caption className="text-secondary">
                    {t('dataPolicy')}
                </Caption>
            </Link>
        </div>
    );
});


export { FooterLegalPart };
