/**
 * LessonCard styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';

import { responsive } from '@/styles/modules/responsive';

import { LessonCardProps } from '@/components/Course/LessonCard/LessonCard';


export const Container = styled.div<LessonCardProps>`
    overflow: hidden;
    border-radius: 16px !important;

    .vkuiAvatar {
        height: ${(props) => props.size === 'compact' ? '42px' : '60px'} !important;
        width: ${(props) => props.size === 'compact' ? '42px' : '60px'} !important;
        border-right: 8px !important;
    }

    .Avatar__img {
        will-change: transform;
        transform: translate3d(0, 0, 0);
    }

    .vkuiRichCell {
        padding-left: 8px !important;
        border-radius: 16px !important;
        box-shadow: ${(props) => props.active ? 'inset 0 0 0 2px var(--accent)' : ''};

        ${responsive([ 'mobile', 'tablet' ])} {
            background-color: ${(props) => !props.colorful ? 'var(--background_hover)' : ''};
        }

        &__children {
            color: var(--accent)
        }

        &__content {
            height: 100%;

            &-after {
                align-self: center;
            }
        }
    }

    .vkuiRichCell__in {
        justify-content: flex-start;
    }
`;
