/**
 * TaskQuestionMappingInput
 *
 * @author: exode <hello@exode.ru>
 */

import 'reflect-metadata';

import { EducationSubject, TaskAnswerType, TaskComplexity, TaskContentType, TaskVisibility } from '@/codegen/graphql';

import {
    IsBoolean,
    IsEnum,
    IsIn,
    IsInt,
    IsNotEmpty,
    IsOptional,
    IsString,
    Max,
    Min,
    ValidateNested,
} from 'class-validator';

import { Type } from 'class-transformer';


export class TaskQuestionMappingInput {

    @IsString({ each: true })
    readonly titles: string[];

    @IsString({ each: true })
    readonly variants: string[];

    @IsString({ each: true })
    readonly answers: string[];

}


export class TaskExplanationInput {

    @IsOptional()
    @IsString()
    readonly text?: string;

    @IsOptional()
    @IsString()
    readonly video?: string;

}


export class TaskQuestionInput {

    @IsNotEmpty()
    readonly title: string;

    @IsNotEmpty()
    readonly text: string;

    @IsOptional()
    @IsString()
    readonly subtext?: string;

    @IsOptional()
    @IsInt({ each: true })
    readonly storageFileIds?: number[];

    @IsOptional()
    @ValidateNested()
    @Type(() => TaskQuestionMappingInput)
    readonly mapping?: TaskQuestionMappingInput;

    @IsOptional()
    @IsString({ each: true })
    readonly variants?: string[];

}


export class UpsertTaskInput {

    @IsEnum(EducationSubject)
    readonly subject: EducationSubject;

    @IsInt()
    readonly subjectCategoryId: number;

    @IsEnum(TaskContentType, { each: true })
    readonly contentTypes: TaskContentType[];

    @IsEnum(TaskAnswerType)
    readonly answerType: TaskAnswerType;

    @ValidateNested()
    @Type(() => TaskQuestionInput)
    readonly question: TaskQuestionInput;

    @IsNotEmpty({ each: true })
    readonly answer: string[];

    @IsOptional()
    @IsEnum(TaskComplexity)
    readonly complexity?: TaskComplexity;

    @IsOptional()
    @IsEnum(TaskVisibility)
    @IsIn([
        TaskVisibility.Organization,
        TaskVisibility.Personal,
    ])
    readonly visibility?: TaskVisibility;

    @IsOptional()
    @ValidateNested()
    @Type(() => TaskExplanationInput)
    readonly explanation?: TaskExplanationInput;

    @IsOptional()
    @IsInt()
    @Min(0)
    @Max(20)
    readonly maxPoint?: number;

    @IsOptional()
    @IsBoolean()
    readonly archived?: boolean;

}
