/**
 * SectionPart
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { cloneElement, Fragment } from 'react';

import { SellerStore } from '@/store/user/seller';
import { observer, UserAuthStore } from '@/store/user/auth';

import { Separator, SimpleCell, Title } from '@exode.ru/vkui';

import { If, Link, Portal } from '@/cutils';
import { useI18nStatic } from '@/hooks/core';
import { UserRbacService } from '@/services/User/Rbac';
import { PageAction, Router } from '@/services/Utils/Router';

import { DrawerSectionType, manageDrawerSections, ManageServiceItem } from '@/types/manage';

import { MenuIconPart } from './MenuIcon';
import { MenuItem } from '../DesktopManageServicesList.styled';


interface Props {
    section: DrawerSectionType;
}


const SectionPart = observer((props: Props) => {

    const { section } = props;

    const { t: tStatic } = useI18nStatic('static.types.drawer');

    const handlePushPage = ({ id, params }: PageAction) => {
        SellerStore.toggleDrawer(false);

        Router.pushPage(id, params);
    };

    const items = _.map(
        _.entries(manageDrawerSections(tStatic)[section].items),
        ([ section, subSection ]) => {
            const hasPermission = UserRbacService.canAny(
                UserAuthStore.combinedPermissions,
                ...subSection.permissions,
            );

            return {
                section,
                subSection,
                hasPermission,
            };
        },
    );

    return (
        <>
            {items.filter(({ subSection }) => (
                subSection.path && Router.filterByPageId(subSection.path?.id)
            )).map(({ section, hasPermission, subSection }) => {

                const { icon, subfields, title, path, spaces } = subSection as ManageServiceItem;

                const isActiveByPageId = Router.isActiveBySpaces(spaces);

                return (
                    <If key={section} is={hasPermission}>
                        <MenuItem className={isActiveByPageId ? 'bg-accent text-white' : ''}
                                  onClick={(() => path && !_.isEmpty(path)
                                          ? handlePushPage(path)
                                          : SellerStore.openSection(section)
                                  )}>
                            <MenuIconPart title={title} icon={cloneElement(icon, {
                                fill: isActiveByPageId ? 'var(--white)' : 'currentColor',
                            })}/>
                        </MenuItem>

                        <If is={SellerStore.drawer.section === section}>
                            <Portal id="menu:context-inner">
                                <>
                                    {(subfields as ManageServiceItem['subfields'])?.map((field, i) => {
                                        if (field.type === 'item') {
                                            return (
                                                <div key={i} onClick={() => SellerStore.toggleDrawer(false)}>
                                                    <Link key={i} pushPage={field.path}>
                                                        <SimpleCell before={field.icon} subtitle={field.description}>
                                                            {field.title}
                                                        </SimpleCell>
                                                    </Link>
                                                </div>
                                            );
                                        }

                                        return (
                                            <Fragment key={i}>
                                                <If is={!!field.divider}>
                                                    <Separator wide className="pt-3 pb-2"/>
                                                </If>

                                                <Title level="2"
                                                       weight="regular"
                                                       className="px-4 text-secondary uppercase">
                                                    <span className="fs-12">
                                                        {field.section}
                                                    </span>
                                                </Title>
                                            </Fragment>
                                        );
                                    })}
                                </>
                            </Portal>
                        </If>
                    </If>
                );
            })}

            <If is={items.some(({ hasPermission }) => hasPermission)}>
                <Separator className="w-full my-3"/>
            </If>
        </>
    );
});


export { SectionPart };
