/**
 * Use photo editor hook
 *
 * @author: exode <hello@exode.ru>
 */

import { CropperProps } from 'react-mobile-cropper';

import { Router } from '@/services/Utils/Router';

import { PhotoEditorModalStore } from '@/modals/Utils/PhotoEditor';


const usePhotoEditor = () => {

    const openPhotoEdit = (
        cropper: CropperProps,
        onSubmit: (value: File) => void,
        onCancel: () => void,
    ) => {
        PhotoEditorModalStore.openPhotoEdit({ cropper, onSubmit, onCancel });

        Router.pushModal('photo-editor');
    };

    return { openPhotoEdit };
};


export { usePhotoEditor };
