/**
 * ImagePart
 *
 * @author: exode <hello@exode.ru>
 */

import React, { CSSProperties } from 'react';

import { PhotoProvider, PhotoView } from 'react-photo-view';

import { Router } from '@/services/Utils/Router';

import { If } from '@/cutils';
import { Caption } from '@exode.ru/vkui';
import { Skeleton } from '@mui/material';

import { InnerHtml } from '@/components/Atoms/InnerHtml';
import { PhotoViewer } from '@/components/Atoms/PhotoViewer';
import { ImageLoader } from '@/components/Atoms/ImageLoader';
import { ImageBlurOnLoad } from '@/components/Atoms/ImageBlurOnLoad/ImageBlurOnLoad';


interface Props {
    data: {
        file: any;
        caption?: string
        stretched?: boolean
        withBorder?: boolean
        withBackground?: boolean
    };

    style?: {
        img?: CSSProperties
        figure?: CSSProperties
        figcaption?: CSSProperties
    };

    classNames?: {
        img?: string
        figure?: string
        figcaption?: string
    };
}


const ImagePart = (props: Props) => {

    const {
        file,
        caption,
        stretched,
        withBorder,
        withBackground,
    } = props?.data || {};

    const maxHeight = '100%';
    const maxWidth = 'max-w-full';

    const imageClassName = 'max-w-full shadow-none';
    const wrapperClassName = 'flex w-full h-full items-center justify-center max-w-full';

    const { width, height } = stretched
        ? { width: '100%', height: '100%' }
        : { width: undefined, height: undefined };

    return (
        <PhotoProvider maskOpacity={0.9}
                       onVisibleChange={(visible) => Router.updateParams({ photoViewer: `${visible}` })}
                       overlayRender={(props) => (<PhotoViewer.OverlayRender {...props} />)}>
            <PhotoView src={file?.location}>
                <div className={[
                    withBorder ? 'thin-border' : '',
                    withBackground ? 'bg-hover' : '',
                    (withBorder || withBackground) ? 'p-4' : '',
                    'flex flex-1 items-center justify-center vk-rounded cursor-zoom-in mb-2',
                ].join(' ')}>
                    <ImageLoader src={file?.location} wrapperClassName={wrapperClassName} loaded={(
                        <ImageBlurOnLoad width={width}
                                         height={height}
                                         maxHeight={maxHeight}
                                         className={imageClassName}
                                         location={file?.location}
                                         wrapperClassName={wrapperClassName}/>
                    )} placeholder={(
                        <ImageLoader wrapperClassName={wrapperClassName}
                                     src={file?.imageSizes?.small || file?.location}
                                     loaded={(
                                         <ImageBlurOnLoad blur
                                                          width="100%"
                                                          height="100%"
                                                          maxHeight={maxHeight}
                                                          className={imageClassName}
                                                          wrapperClassName={wrapperClassName}
                                                          location={file?.imageSizes?.small || file?.location}/>
                                     )}
                                     placeholder={(
                                         <Skeleton width={width}
                                                   height={height}
                                                   variant="rectangular"
                                                   className={[ 'vk-rounded', maxWidth ].join(' ')}/>
                                     )}/>
                    )}/>
                </div>
            </PhotoView>

            <If is={!!caption}>
                <Caption className="text-secondary fs-13 text-center p-2 mb-2">
                    <InnerHtml content={caption}/>
                </Caption>
            </If>
        </PhotoProvider>
    );
};


export { ImagePart };
