/**
 * CellTagItemPart component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { SimpleCell } from '@exode.ru/vkui';

import { CellTagSelectProps } from '../CellTagSelect';

import styles from '../CellTagSelect.module.scss';


export interface CellTagItemPartProps extends Partial<CellTagSelectProps> {
    value: Exclude<CellTagSelectProps['options'], undefined>[number]['value'];
    label: Exclude<CellTagSelectProps['options'], undefined>[number]['label'];
    appearance?: CellTagSelectProps['appearance'];
    hasActive?: boolean;
    onClick?: (value: any) => void;
    isSelected?: boolean;
    selectedClassName?: string;
    unSelectedClassName?: string;
    selectedBefore?: ReactElement;
    unSelectedBefore?: ReactElement;
}


const CellTagItemPart = (props: CellTagItemPartProps) => {

    const {
        value,
        label,
        onClick,
        hasActive,
        isSelected,
        selectedBefore,
        unSelectedBefore,
        className = '',
        appearance = 'primary',
    } = props;

    const [ _selectedClassName, _unSelectedClassName ] = ({
        onDark: [
            'bg-content dark:bg-white dark:text-black',
            'bg-transparent text-white thin-border white-border',
        ],
        secondary: [
            'bg-button-muted text-primary thin-border',
            'bg-selected thin-border',
        ],
        primary: [
            'bg-accent text-white',
            'bg-content thin-border',
        ],
    })[appearance];

    const {
        selectedClassName = _selectedClassName,
        unSelectedClassName = _unSelectedClassName,
    } = props;

    return (
        <SimpleCell hasActive={hasActive}
                    onClick={() => onClick?.(value)}
                    before={isSelected ? selectedBefore : unSelectedBefore}
                    className={[
                        className,
                        styles.simpleCell,
                        '!rounded-[16px] select-none min-h-[28px] d:max-w-[300px] m:max-w-[260px]',
                        isSelected ? selectedClassName : unSelectedClassName,
                    ].join(' ')}>
            {label}
        </SimpleCell>
    );
};


export { CellTagItemPart };
