/**
 * ManageCourseCreateModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Form, Formik } from 'formik';

import { createValidator } from 'class-validator-formik';

import { observer } from 'mobx-react';

import { PlatformStore } from '@/store/platform';

import { Field, If, Link, Modal } from '@/cutils';

import { useStableModalParams } from '@/router/index';
import { useI18n, useI18nStatic } from '@/hooks/core';

import { ContentCategorySpace, CourseType, ProductCurrency } from '@/codegen/graphql';

import { Icon24AddSquareOutline } from '@vkontakte/icons';
import { Button, FormItem, Input, SegmentedControl, Title } from '@exode.ru/vkui';

import { useCourseManage } from '@/hooks/apollo';
import { CreateCourseInput } from '@/libs/class-validator/course';

import { InnerHtml } from '@/components/Atoms/InnerHtml';
import { FieldBottomLimiter } from '@/components/Atoms/FieldBottomLimiter';
import { SubjectSearchSelect } from '@/components/Subject/SubjectSearchSelect';
import { ContentCategorySearchSelect } from '@/components/Content/CategorySearchSelect';


interface Props {
    id: string;
}


const ManageCourseCreateModal = observer((props: Props) => {

    const { COMMON } = PlatformStore;

    const { t } = useI18n('modals.Course.Create');
    const { t: tStatic } = useI18nStatic('static.dictionary.declension.course');

    const [ { type } ] = useStableModalParams<{ type: CourseType }>();

    const { createCourse, createCourseLoading, createInitialValues } = useCourseManage();

    const handleSubmit = async (values: typeof createInitialValues) => {
        await createCourse({ type, ...values });
    };

    return (
        <Modal.Card id={props.id} header={(
            <Title level="2" weight="3" className="modal-title first-letter:capitalize">
                {t('createCourseModalTitle', {
                    courseType: tStatic(`single.parental.${type}`).toLowerCase(),
                })}
            </Title>
        )} subheader={t('nextYouWillGoToAdvancedSettings')}>
            <Modal.Body isScrollable className="p-3">
                <Formik validateOnBlur
                        validateOnChange
                        validateOnMount
                        onSubmit={handleSubmit}
                        initialValues={createInitialValues}
                        validate={createValidator(CreateCourseInput)}>
                    {({
                          values,
                          isValid,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          setFieldValue,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                            <FormItem className="px-0 pb-0"
                                      status={Field.status(errors, touched, 'name')}
                                      top={t('courseNameModal', {
                                          courseType: tStatic(`single.parental.${type}`).toLowerCase(),
                                      })}
                                      bottom={(
                                          <FieldBottomLimiter maxLength={120}
                                                              value={values.name}
                                                              message={Field.message(errors, touched, 'name')}/>
                                      )}>
                                <Input autoFocus
                                       name="name"
                                       maxLength={120}
                                       onBlur={handleBlur}
                                       value={values.name}
                                       data-test="course.name"
                                       placeholder={t('enterCourseNamePlaceholder')}
                                       onChange={Field.transform.ignoreSpaceOnChange(handleChange)}/>
                            </FormItem>

                            <FormItem className="px-0" top={t('categoryModal')}>
                                <ContentCategorySearchSelect selectedContentCategoryIds={[ values?.contentCategoryId ]}
                                                             onSelect={({ value }) => {
                                                                 setFieldValue('contentCategoryId', value);
                                                                 setFieldValue('subject', []);
                                                             }}
                                                             filter={{
                                                                 visible: true,
                                                                 spaces: [ ContentCategorySpace.Course ],
                                                             }}/>
                            </FormItem>

                            <FormItem className="px-0" top={t('subjectModal')} bottom={(
                                <If is={!!COMMON?.organization.info.messengerSupportUrl}>
                                    <div>
                                        Нет вашей категории или предмета? Напишите в{' '}
                                        <Link blank toOuter={COMMON?.organization.info.messengerSupportUrl}>
                                            <span className="text-accent">поддержку.</span>
                                        </Link>
                                    </div>
                                </If>
                            )}>
                                <SubjectSearchSelect selectedSubjects={[ values.subject ]}
                                                     select={{ disabled: !values.contentCategoryId }}
                                                     onSelect={({ value }) => setFieldValue('subject', value)}
                                                     filter={{
                                                         contentCategoryIds: [ values.contentCategoryId ].filter(e => e) as number[],
                                                     }}/>
                            </FormItem>

                            <FormItem top={t('accessTypeModal')} className="px-0 mb-5" bottom={(
                                <span>
                                    <InnerHtml content={t('reminderAboutAccessType')}/>
                                </span>
                            )}>
                                <SegmentedControl className="thin-border"
                                                  value={values.product.currency}
                                                  onChange={(value) => setFieldValue('product.currency', value)}
                                                  options={[
                                                      {
                                                          value: createInitialValues.product.currency,
                                                          label: t('courseTypePaid'),
                                                      },
                                                      {
                                                          value: ProductCurrency.Free,
                                                          label: t('courseTypeFree'),
                                                      },
                                                  ]}/>
                            </FormItem>

                            <Button stretched
                                    size="l"
                                    type="submit"
                                    mode="commerce"
                                    data-test="course.create-new"
                                    loading={createCourseLoading}
                                    before={<Icon24AddSquareOutline/>}
                                    disabled={!isValid || createCourseLoading}>
                                {t('createCourseBtnModal')}
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal.Card>
    );
});


export { ManageCourseCreateModal };
