/**
 * ProgressItem component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { ConfigStore } from '@/store/core/config';

import { CourseService } from '@/services/Course/Course';

import { Link } from '@/cutils';
import { useI18n } from '@/hooks/core';

import { Icon16Flag } from '@vkontakte/icons';
import { Caption, List } from '@exode.ru/vkui';
import { TextTooltip } from '@exode.ru/vkui/unstable';

import { CourseCardProps } from '../CourseCard';


interface Props {
    course: CourseCardProps['course'];
}


const ProgressItem = (props: Props) => {

    const { t } = useI18n('pages.Course.CourseCard');

    const { course, course: { currentLesson, lessons } } = props;

    return (
        <>
            <div className="flex items-center mt-8 mb-3 d:mb-0 gap-1.5">
                <Icon16Flag className="mt-[1px] min-w-[16px]"/>

                <span className="fs-14 whitespace-nowrap">
                    {CourseService.getCompletedLessonsCount(lessons)} / {lessons?.length}
                </span>

                <span>•</span>

                <Caption className="fs-14 pr-2 overflow-ellipsis">
                    {(
                        currentLesson
                            ? currentLesson?.name
                            : t('lessons', { count: lessons?.length || 0 })
                    )}
                </Caption>
            </div>

            <List className="progress-list mb-0.5">
                {lessons?.map(({ id, order, name }) => (
                    <Link key={id} stopPropagation disabled={!ConfigStore.isDesktop} pushPage={{
                        id: '/courses/:page([0-9]+)/:courseId([0-9]+)/study/:lessonId([0-9]+)',
                        params: { courseId: `${course.id}`, lessonId: `${id}` },
                    }}>
                        <div className="progress-item overflow-hidden first:rounded-l-lg last:rounded-r-lg">
                            <TextTooltip placement="bottom" offsetDistance={4} text={name}>
                                <div key={id} className={[
                                    'w-auto h-[8px]',
                                    (id !== currentLesson?.id
                                            ? ((order || 0) < (currentLesson?.order || 1)
                                                    ? 'bg-positive'
                                                    : 'bg-[#0000002e]'
                                            )
                                            : (CourseService.lessonIsCompleted(currentLesson)
                                                    ? 'bg-positive'
                                                    : 'bg-accent'
                                            )
                                    ),
                                ].join(' ')}/>
                            </TextTooltip>
                        </div>
                    </Link>
                ))}
            </List>
        </>
    );
};


export { ProgressItem };
