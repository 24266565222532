/**
 * UseSchoolManage
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { useEffect } from 'react';

import { APP_DOMAIN } from '@/root/src/env';

import { PlatformStore, SchoolStore } from '@/store/platform';
import { SaveStoreDeepKeys, SaveStoreKeys, SavingStore } from '@/store/core/saving';

import { ArrayUtil, Url } from '@/utils';
import { apolloClient } from '@/api/graphql';

import { GqlResult } from '@/types/graphql';
import { SchoolItem } from '@/types/school';
import { RoutePathType } from '@/router/paths';
import { DraggingIndexes } from '@/types/common';

import { UserAuthService } from '@/services/User/Auth';

import {
    CreateSchoolInput,
    SchoolAccessType,
    SchoolBlockMode,
    SchoolDomainType,
    SchoolFindOneDocument,
    SchoolFindOneQuery,
    SchoolLeftMenuBlock,
    SchoolManageCreateWithSellerMutationResult,
    UpdateSchoolInput,
    useSchoolManageCreateWithSellerMutation,
    useSchoolManageUpdateMutation,
} from '@/codegen/graphql';


interface Props {
    withSavingStore?: boolean;
}


export const useSchoolManage = (props?: Props) => {

    const setSchoolEditing = (editing = true) => {
        SavingStore.setEditing(
            SaveStoreKeys.School,
            SaveStoreDeepKeys.SchoolInformation,
            editing,
        );
    };

    const getInitialValues = (school: SchoolItem) => {

        const { blockSettings } = school;

        return {
            ...school,
            name: school?.name || '',
            active: !!school?.active,
            baseDomain: school?.baseDomain || '',
            description: school?.description || '',
            customDomain: school?.customDomain || '',
            domainType: school?.domainType || SchoolDomainType.Base,
            accessType: school?.accessType || SchoolAccessType.Public,
            addresses: school?.addresses || [],
            contacts: school?.contacts || [],
            blockSettings: {
                headerMenu: [],
                mobileMenu: [],
                footer: blockSettings?.footer || [],
                leftMenu: blockSettings?.leftMenu || [],
                logo: {
                    mode: blockSettings?.logo?.mode || SchoolBlockMode.Text,
                    text: blockSettings?.logo?.text || school?.name,
                    url: blockSettings?.logo?.url || null,
                },
                catalog: {
                    color: blockSettings?.catalog?.color || '#000000',
                    mode: blockSettings?.catalog?.mode || SchoolBlockMode.Color,
                    title: blockSettings?.catalog?.title || school?.name,
                    subtitle: blockSettings?.catalog?.subtitle || school?.description,
                    url: blockSettings?.catalog?.url || null,
                },
            },
        } as UpdateSchoolInput;
    };

    const moveToDomainSchool = (
        school: SchoolItem,
        options: {
            target: '_self' | '_blank';
            /** Without params */
            pathname?: RoutePathType;
            params?: Record<string, string>;
        },
    ) => {
        UserAuthService.saveAuthStateToCookie();

        const currentLocation = new URL(window.location.href);

        const pathname = (options.pathname || currentLocation.pathname).slice(1);
        const params = options.params || Url.parseQuery(currentLocation.search);

        const openUrl = new URL([
            Url.getSchoolDomainUrl('url', APP_DOMAIN, school),
            [
                pathname,
                !_.isEmpty(params) && Url.objectToQuery(params, '&', true),
            ].filter(e => e).join(''),
        ].join(''));

        setImmediate(() => {
            if (options.target === '_blank') {
                window.close();
                window.location.reload();
            }
        });

        window.open(openUrl, options.target)?.focus();
    };

    const [ _createSchoolWithSeller, {
        loading: createSchoolWithSellerLoading,
        error: createSchoolWithSellerError,
    } ] = useSchoolManageCreateWithSellerMutation();

    const createSchoolWithSeller = (
        school: CreateSchoolInput,
        onCompleted?: (
            school: GqlResult<SchoolManageCreateWithSellerMutationResult>['schoolManageCreateWithSeller'],
        ) => void,
    ) => {
        return _createSchoolWithSeller({
            variables: { school },
            onCompleted: ({ schoolManageCreateWithSeller }) => {
                onCompleted?.(schoolManageCreateWithSeller);
            },
        });
    };

    const [ _updateSchool, {
        loading: updateSchoolLoading,
        error: updateSchoolError,
    } ] = useSchoolManageUpdateMutation();

    const updateSchool = (
        schoolId: number,
        school: UpdateSchoolInput,
    ) => {
        return _updateSchool({
            variables: { schoolId, school },
            onCompleted: ({ schoolManageUpdate }) => {
                if (schoolManageUpdate.baseDomain !== SchoolStore.school?.baseDomain) {
                    moveToDomainSchool(schoolManageUpdate, { target: '_blank' });
                }

                SchoolStore.merge({ school: schoolManageUpdate });

                PlatformStore.merge({
                    COMMON: schoolManageUpdate.platformConfig,
                });
            },
        });
    };

    const updateSchoolCache = (
        variables: { schoolId: number },
        partial: Partial<SchoolItem>,
    ) => {
        const { schoolFindOne } = apolloClient?.cache.readQuery<SchoolFindOneQuery>({
            variables,
            query: SchoolFindOneDocument,
        }) || {};

        schoolFindOne && apolloClient?.cache.writeQuery<SchoolFindOneQuery>({
            variables,
            query: SchoolFindOneDocument,
            data: {
                schoolFindOne: {
                    ...schoolFindOne,
                    ...partial,
                    blockSettings: {
                        ...schoolFindOne.blockSettings,
                        ...partial.blockSettings,
                    },
                },
            },
        });
    };

    const reorderLeftMenu = (
        schoolId: number,
        indexes: DraggingIndexes,
        menuItems: SchoolLeftMenuBlock[] = SchoolStore.blocks?.leftMenu || [],
    ) => {
        const leftMenu = ArrayUtil.reorderList(indexes, menuItems);

        /** Store and cache update */
        SchoolStore.setBlocks({ leftMenu });
        updateSchoolCache({ schoolId }, { blockSettings: { leftMenu } });

        return updateSchool(schoolId, {
            blockSettings: { leftMenu },
        });
    };

    const deleteLeftMenuItem = (
        schoolId: number,
        index: number,
        menuItems: SchoolLeftMenuBlock[] = SchoolStore.blocks?.leftMenu || [],
    ) => {
        const leftMenu = ([ ...menuItems ] || []).filter((__, i) => index !== i);

        /** Store and cache update */
        SchoolStore.setBlocks({ leftMenu });
        updateSchoolCache({ schoolId }, { blockSettings: { leftMenu } });

        return updateSchool(schoolId, {
            blockSettings: { leftMenu },
        });
    };

    useEffect(() => {
        if (props?.withSavingStore) {
            SavingStore.setSaving(
                SaveStoreKeys.School,
                SaveStoreDeepKeys.SchoolInformation,
                updateSchoolLoading,
            );
        }
    }, [ updateSchoolLoading, props?.withSavingStore ]);

    return {
        setSchoolEditing,
        createSchoolWithSeller,
        createSchoolWithSellerLoading,
        createSchoolWithSellerError,
        updateSchool,
        updateSchoolError,
        updateSchoolLoading,
        getInitialValues,
        moveToDomainSchool,
        reorderLeftMenu,
        deleteLeftMenuItem,
    };
};
