/**
 * TooltipsView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core';

import { Subhead } from '@exode.ru/vkui/tokenized';
import { Icon16HelpOutline } from '@vkontakte/icons';
import { RichTooltip } from '@exode.ru/vkui/unstable';

import { InnerHtml } from '@/components/Atoms/InnerHtml';


const PriceTagsTooltip = () => {

    const { t } = useI18n('pages.Manage.Course.Launches.Pricing.views');

    return (
        <RichTooltip content={(
            <Subhead className="max-w-[280px] p-2">
                <InnerHtml content={t('pricingTagsExamples')}/>
            </Subhead>
        )}>
            <Icon16HelpOutline className="cursor-pointer"/>
        </RichTooltip>
    );
};

const PriceAccessPeriodTooltip = () => {

    const { t } = useI18n('pages.Manage.Course.Launches.Pricing.views');

    return (
        <RichTooltip content={(
            <Subhead className="max-w-[180px] p-2">
                {t('materialAccessPeriod')}
            </Subhead>
        )}>
            <Icon16HelpOutline className="cursor-pointer"/>
        </RichTooltip>
    );
};


export { PriceTagsTooltip, PriceAccessPeriodTooltip };
