/**
 * Use formik draft hook
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { MutableRefObject, useState } from 'react';

import { FormikHelpers } from 'formik';

import { Storage } from '@/api/storage';
import { EditorJSMapRef } from '@/hooks/core';
import { LocalStorageKeyType } from '@/types/config';


interface Props {
    id?: number | string;
    storageKey: LocalStorageKeyType;
    useDraft?: boolean;
    transformValuesToForm?: (draft: Record<any, any>) => typeof draft;
    editorCore?: MutableRefObject<EditorJSMapRef>;
    editorJsRenders?: { key: string, valueField: string }[];
}


const useFormikDraft = (props: Props) => {

    const {
        storageKey,
        id = 'create',
        useDraft = true,
        editorCore = null,
        editorJsRenders = [],
        transformValuesToForm = (v) => v,
    } = props;

    const getDraftValue = () => {
        const value = Storage.get(storageKey);

        return value?.[id];
    };

    const [ showDraft, setShowDraft ] = useState(Boolean(useDraft) && !!getDraftValue());

    const handleRestore = (setValues: FormikHelpers<any>['setValues']) => {
        setShowDraft(false);
        const value = Storage.get(storageKey);

        if (value?.[id]) {
            const values = getDraftValue();
            setValues(transformValuesToForm(values));

            setImmediate(() => {
                editorJsRenders.map(({ key, valueField }) => {
                    editorCore?.current?.[key]?.render?.(_.get(values, valueField));
                });
            });
        }
    };

    const handleSaveDraft = (values: Record<any, any>) => {
        if (storageKey && values) {
            setShowDraft(false);

            Storage.set(storageKey, { ...Storage.get(storageKey), [id]: values });
        }
    };

    const handleDeleteDraft = () => {
        setShowDraft(false);

        Storage.set(storageKey, _.omit(Storage.get(storageKey), id));
    };

    return {
        showDraft,
        handleRestore,
        handleSaveDraft,
        handleDeleteDraft,
    };
};


export { useFormikDraft };
