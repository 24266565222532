/**
 * HeaderPlaceholderView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core';

import { CompanyHeader } from '@/components/Company/About';

import { Title } from '@exode.ru/vkui';


const CompanyHeaderPlaceholderView = () => {

    const { t } = useI18n('pages.Company.About');

    return (
        <CompanyHeader paddings="pt-12 pb-6" iconSize={120} header={(
            <Title level="2" weight="2" className="mt-5 mb-3 px-5 text-3xl">
                {t('yourPlatformForDevelopment')}
            </Title>
        )}/>
    );
};


export { CompanyHeaderPlaceholderView };
