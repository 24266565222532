/**
 * PdfViewer
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useState } from 'react';

import { Document, Page, pdfjs } from 'react-pdf';
import { DocumentCallback } from 'react-pdf/src/shared/types';

import { useI18n, useNative } from '@/hooks/core';
import { ConfigStore } from '@/store/core/config';

import { Icon24Cancel } from '@vkontakte/icons';
import { Alert, AppBar, IconButton, Toolbar, Typography } from '@mui/material';

import { Container, DocumentContainer } from './PdfViewer.styled';


interface Props {
    url: string;
    title?: string;
    onClose?: () => void;
}


const PdfViewer = (props: Props) => {

    const { t } = useI18n('components.Atoms.PdfViewer');

    const { url, onClose, title = t('docutmentView') } = props;

    const { triggerHaptic } = useNative();

    const [ loadProgress, setLoadProgress ] = useState(0);
    const [ pagesCount, setPagesCount ] = useState<number>();

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const onDocumentLoadSuccess = (options: DocumentCallback) => {
        triggerHaptic();
        setPagesCount(options.numPages);
    };

    const options = {
        cMapUrl: '/cmaps/',
        standardFontDataUrl: '/standard_fonts/',
    };

    const loading = (
        <div className="flex items-center h-[calc(var(--app-height)_-_50px)]">
            <span className={[
                'flex items-center justify-center font-semibold',
                'font-vk-sans-display text-white text-center',
            ].join(' ')}>
                <div className="fs-50 min-w-[80px]">
                    {`${Math.round(loadProgress)}`}
                </div>

                <span className="fs-30">%</span>
            </span>
        </div>
    );

    const error = (
        <div className="flex items-center h-[calc(var(--app-height)_-_50px)]">
            <Alert severity="error">
                {t('notAbleToDownloadFile')}
            </Alert>
        </div>
    );

    return (
        <Container>
            <AppBar sx={{
                height: 50,
                position: 'fixed',
                background: 'var(--gray_smoked)',
                '& .MuiToolbar-root': {
                    minHeight: 50,
                    paddingTop: 'calc(env(safe-area-inset-top, 0px))',
                },
            }}>
                <Toolbar className="bg-[var(--gray\_smoked)]">
                    <Typography variant="h6" component="div" sx={{ flex: 1 }}>
                        <span className="font-vk-sans-display line-clamp-1">
                            {title}
                        </span>
                    </Typography>

                    <IconButton edge="start"
                                size="small"
                                color="inherit"
                                sx={{ background: 'var(--gray_gunmetal) !important' }}
                                onClick={() => {
                                    onClose?.();
                                    triggerHaptic();
                                }}>
                        <Icon24Cancel/>
                    </IconButton>
                </Toolbar>
            </AppBar>

            <DocumentContainer>
                <Document file={url}
                          error={error}
                          loading={loading}
                          options={options}
                          onLoadSuccess={onDocumentLoadSuccess}
                          onLoadProgress={({ loaded, total }) => {
                              const newProgress = loaded / total * 100;

                              if (loadProgress < newProgress) {
                                  setLoadProgress(newProgress);
                              }
                          }}>
                    {Array.from(new Array(pagesCount), (__, index) => (
                        <Page key={index}
                              error={<></>}
                              loading={<></>}
                              pageNumber={index + 1}
                              width={Math.min(ConfigStore.width, 800)}/>
                    ))}
                </Document>
            </DocumentContainer>
        </Container>
    );
};


export { PdfViewer };
