/**
 * VkService
 *
 * @author: exode <hello@exode.ru>
 */

import { IS_WEB } from '@/root/src/env';

import { DocumentEvent } from '@/types/window';

import { BaseService } from '@/services/Core/Base';
import { NativeService } from '@/services/Native/Native';

import { Storage } from '@/api/storage';

import { initFloatingTap } from '@/services/Vk/VkLogin';
import { VkMiniAppService } from '@/services/Vk/VkMiniApp';


class VkService extends BaseService {

    /**
     * Регистрация сервиса
     */
    static register() {
        this.listenVkUiEvents();
    }

    /**
     * Инициализация сервиса
     */
    static init() {
        VkMiniAppService.init();

        if (IS_WEB && !Storage.get('user:auth-token')) {
            initFloatingTap();
        }
    }

    /**
     * Handle VKUI lib events
     */
    static listenVkUiEvents() {
        document.addEventListener(DocumentEvent.VkUiHapticEventFired, () => {
            NativeService.sendToBridge({
                module: 'HapticFeedback',
                method: 'trigger',
            });
        });
    }

}


export { VkService };
