/**
 * Subscription store
 *
 * @author: exode <hello@exode.ru>
 */

import { ChatFolderType } from '@/types/chat';

import { makeAutoObservable } from 'mobx';
import { observer } from 'mobx-react';


class Subscription {

    constructor() {
        makeAutoObservable(this);
    }

    /** Профили на которые подписался пользователь */
    private profileStatuses: Set<number> = new Set;

    /** Турниры на которые подписался пользователь */
    private tournaments: Set<number> = new Set;

    /** Турниры на участников которых подписался пользователь */
    private tournamentMembers: Set<number> = new Set;

    /** Прогресс участников турнира на которые подписался пользователь */
    private tournamentMembersProgress: Set<number> = new Set;

    /** Подписка на сообщения чата в лобби турнира  */
    private tournamentChatMessages: Set<number> = new Set;

    /** Подписка на сообщения чата */
    private chatIds: Set<number> = new Set;

    /** Подписка на typing чата */
    private chatTypingIds: Set<number> = new Set;

    /** Подписка на кол-во непрочитанных сообщений папки чатов */
    private chatFolderUnreadCount: Set<ChatFolderType> = new Set;

    /**
     * Получение списка подписок на статус профиля
     * @returns {Set<number>}
     */
    get subscribedProfileStatuses() {
        return this.profileStatuses;
    }

    /**
     * Получение списка подписок на турниры
     * @returns {Set<number>}
     */
    get subscribedTournaments() {
        return this.tournaments;
    }

    /**
     * Получение списка подписок турниров на его участников
     * @returns {Set<number>}
     */
    get subscribedTournamentMembers() {
        return this.tournamentMembers;
    }

    /**
     * Получение списка подписок на прогресс участников турнира
     * @returns {Set<number>}
     */
    get subscribedTournamentMembersProgress() {
        return this.tournamentMembersProgress;
    }

    /**
     * Получение списка сообщений в чате лобби турнира с подпиской на их сообщения
     * @returns {Set<number>}
     */
    get subscribedTournamentChatMessages() {
        return this.tournamentChatMessages;
    }

    /**
     * Получение списка чатов с подпиской на их сообщения
     * @returns {Set<number>}
     */
    get subscribedChatIds() {
        return this.chatIds;
    }

    /**
     * Получение списка чатов с подпиской на их typing
     * @returns {Set<number>}
     */
    get subscribedChatTypingsIds() {
        return this.chatTypingIds;
    }

    /**
     * Получение списка папок чата с подпиской на кол-во непрочитанных сообщений
     * @returns {Set<number>}
     */
    get subscribedChatTypeUnreadCount() {
        return this.chatFolderUnreadCount;
    }

    /**
     * Добавление подписки на статус профиля
     * @param {number} profileId
     * @returns {void}
     */
    subscribeToProfileStatus(profileId: number | undefined) {
        if (!profileId || this.subscribedProfileStatuses.has(profileId)) {
            return;
        }

        this.profileStatuses.add(profileId);
    }

    /**
     * Добавление подписки на турнир
     * @param {number} tournamentId
     * @returns {void}
     */
    subscribeToTournament(tournamentId: number | undefined) {
        if (!tournamentId || this.subscribedTournaments.has(tournamentId)) {
            return;
        }

        this.tournaments.add(tournamentId);
    }

    /**
     * Добавление подписки на участников турнира
     * @param {number} tournamentId
     * @returns {void}
     */
    subscribeToTournamentMembers(tournamentId: number | undefined) {
        if (!tournamentId || this.subscribedTournamentMembers.has(tournamentId)) {
            return;
        }

        this.tournamentMembers.add(tournamentId);
    }

    /**
     * Добавление подписки на прогресс участников в турнире
     * @param {number} tournamentId
     * @returns {void}
     */
    subscribeToTournamentMembersProgress(tournamentId: number | undefined) {
        if (!tournamentId || this.subscribedTournamentMembersProgress.has(tournamentId)) {
            return;
        }

        this.tournamentMembersProgress.add(tournamentId);
    }

    /**
     * Добавление подписки на сообщения чата в лобби турнира
     * @param {number} tournamentId
     * @returns {void}
     */
    subscribeToTournamentChatMessages(tournamentId: number | undefined) {
        if (!tournamentId || this.subscribedTournamentChatMessages.has(tournamentId)) {
            return;
        }

        this.tournamentChatMessages.add(tournamentId);
    }

    /**
     * Добавление подписки на сообщения чата
     * @param {number} chatId
     * @returns {void}
     */
    subscribeToChatIds(chatId: number | undefined) {
        if (!chatId || this.subscribedChatIds.has(chatId)) {
            return;
        }

        this.chatIds.add(chatId);
    }

    /**
     * Добавление подписки на typing чата
     * @param {number} chatId
     * @returns {void}
     */
    subscribeToChatTypingIds(chatId: number | undefined) {
        if (!chatId || this.subscribedChatTypingsIds.has(chatId)) {
            return;
        }

        this.chatTypingIds.add(chatId);
    }

    /**
     * Добавление подписки на кол-во непрочитанных сообщений папки
     * @param {ChatFolderType | 'All'} type
     */
    subscribeToChatTypeUnreadCount(type: ChatFolderType) {
        if (!type || this.subscribedChatTypeUnreadCount.has(type)) {
            return;
        }

        this.chatFolderUnreadCount.add(type);
    }

}

const SubscriptionStore = new Subscription();

export { observer, SubscriptionStore };
