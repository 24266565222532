/**
 * TournamentCreationPage store
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { makeObservable } from 'mobx';
import { observer, PageStore } from '@/pages/Core';

import { EducationSubject, TournamentStartMode, TournamentType } from '@/codegen/graphql';


class TournamentCreationPage extends PageStore {

    constructor() {
        super();
        makeObservable(this);
    }

    state = {
        input: {
            bet: 100,
            startAt: '2',
            taskCount: 5,
            type: TournamentType.Public,
            subject: EducationSubject.EgeRussian,
            startMode: TournamentStartMode.Event,
            membersLimit: 0,
            subjectCategoryIds: [] as number[],
        },
    };

}

const TournamentCreationPageStore = new TournamentCreationPage();


export { observer, TournamentCreationPageStore };
