/**
 * AgoraProvider component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { createContext, Dispatch, ReactElement, SetStateAction, useContext, useState } from 'react';
import { FloatingAgoraButtons } from '@/components/Atoms/FloatingAgoraButtons';


export interface AgoraCallStateState {
    isOnCall: boolean;
    appId: string;
    channelName: string,
    token: string,
}

interface AgoraProviderProps {
    children: ReactElement | ReactElement[];
}

interface UseAgora {
    state: AgoraCallStateState;
    setState: (patch: Partial<AgoraCallStateState> | ((previousState: AgoraCallStateState) => Partial<AgoraCallStateState>)) => void;
}


/**
 * Initial state
 */
const AgoraCallState = {
    isOnCall: false,
    appId: '',
    channelName: '',
    token: '',
};

/**
 * Creating Agora context
 * @returns {ISocketContext}
 */
const AgoraContext = createContext({
    state: AgoraCallState,
    setState: Function as Dispatch<SetStateAction<any>>,
});

/**
 * Agora provider
 * @param {AgoraProviderProps} props
 * @constructor
 */
const AgoraProvider = (props: AgoraProviderProps) => {
    const [ state, setState ] = useState<AgoraCallStateState>(AgoraCallState);

    return (
        <AgoraContext.Provider value={{ state, setState }}>
            {props.children}
            <FloatingAgoraButtons/>
        </AgoraContext.Provider>
    );
};

/**
 * UseAgora hook
 * @returns {useAgora}
 */
const useAgora = (): UseAgora => useContext(AgoraContext);


export { useAgora, AgoraProvider };
