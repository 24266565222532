/**
 * App profile subscriptions
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer, SubscriptionStore } from '@/store/subscription/subscription';

import { ProfileStatusSubscription } from './ProfileStatusSubscription';


const AppProfileSubscriptions = observer(() => {
    return (
        <>
            {[ ...SubscriptionStore.subscribedProfileStatuses ].map((id) => (
                <ProfileStatusSubscription id={id} key={id}/>
            ))}
        </>
    );
});


export { AppProfileSubscriptions };
