/**
 * ChatSnackbar component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { forwardRef } from 'react';

import { SnackbarContent } from 'notistack';

import { ConfigStore } from '@/store/core/config';
import { ChatListenNewListEventSubscriptionResult } from '@/codegen/graphql';

import { useI18n } from '@/hooks/core';
import { If, Link, Notify } from '@/cutils';
import { GqlResult } from '@/types/graphql';
import { ChatService } from '@/services/Chat/Chat';

import { Icon28Users } from '@vkontakte/icons';
import { Avatar, Button, ModalDismissButton, RichCell, Snackbar as VkUiSnackbar } from '@exode.ru/vkui';

import { BaseSnackbarProps } from '../interfaces';

import { Container } from '../NotificationSnackbar/NotificationSnackbar.styled';


export interface ChatSnackbarProps extends BaseSnackbarProps {
    chat: Exclude<GqlResult<ChatListenNewListEventSubscriptionResult>['chatListenNewListEvent']['chat'], null | undefined>;
}


const ChatSnackbar = forwardRef<HTMLDivElement, ChatSnackbarProps>((props: ChatSnackbarProps, ref) => {

    const { chat, options, ...rest } = props;

    const { t } = useI18n('components.Atoms.Notistack.ChatSnackbar');

    return (
        <SnackbarContent ref={ref} role="alert" {...rest}>
            <Container appearance="muted">
                <VkUiSnackbar layout="vertical"
                              duration={Notify.calcDuration(options)}
                              after={ConfigStore.isDesktop && <ModalDismissButton/>}
                              onClose={() => Notify.closeSnackbar(options?.key || props.id)}
                              onClick={() => Notify.closeSnackbar(options?.key || props.id)}
                              {...rest}>

                    <RichCell disabled
                              multiline
                              text={chat.name}
                              afterCaption={ChatService.parseChatDate(chat.lastMessage?.createdAt)}
                              caption={(
                                  <span className="line-clamp-2">
                                      {ChatService.lastMessageText(chat?.lastMessage)}
                                  </span>
                              )}
                              before={(
                                  <Avatar size={50} src={chat?.icon || ''}>
                                      <If is={!chat?.icon}>
                                          <Icon28Users/>
                                      </If>
                                  </Avatar>
                              )}
                              actions={(
                                  <Link pushPage={{ id: '/chat', params: { chatId: `${chat?.id}` } }}>
                                      <Button size="s" mode="primary">
                                          {t('openChat')}
                                      </Button>
                                  </Link>
                              )}>
                        {t('newMessage')}
                    </RichCell>
                </VkUiSnackbar>
            </Container>
        </SnackbarContent>
    );
});


export { ChatSnackbar };
