/**
 * TagsItem component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { SvgComponent } from '@/cutils';
import { educationSubjectColors, educationSubjectImages, educationSubjects } from '@/types/subject';

import { Caption } from '@exode.ru/vkui';
import { FeedbackRateAndParticipantCount } from '@/components/Course/CourseCard';

import { CourseCardProps } from '../CourseCard';


interface Props {
    course: CourseCardProps['course'];
}


const TagsItem = (props: Props) => {

    const {
        course,
        course: {
            subject,
            product,
        },
    } = props;

    return (
        <>
            <div className={[
                'tags-wrapper m-5 m:m-4 gap-2 w-[calc(100%_-_128px)]',
            ].join(' ')}>
                <div className="tag-item w-max" style={{ maxWidth: 'clamp(50%, calc(100% - 77px), 100%)' }}>
                    <div className={[
                        educationSubjectColors[subject],
                        'rounded-full w-[18px] h-[18px] flex flex-shrink-0 items-center justify-center bg-gradient-to-r',
                    ].join(' ')}>
                        <SvgComponent element={educationSubjectImages[subject]}
                                      svgProps={{ width: 10, height: 10, marginRight: 4 }}/>
                    </div>

                    <Caption level="2" className="ml-2 overflow-hidden text-ellipsis whitespace-nowrap">
                        {educationSubjects[subject]}
                    </Caption>
                </div>

                <div className="tag-item">
                    <FeedbackRateAndParticipantCount course={course}
                                                     className="ml-1 text-muted"
                                                     rate={product?.feedbackRating}
                                                     participants={product?.participantsCount}/>
                </div>
            </div>
        </>
    );
};


export { TagsItem };
