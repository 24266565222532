/**
 * Vk login
 *
 * @author: exode <hello@exode.ru>
 */

import toast from 'react-hot-toast';

import { Config, Connect, ConnectEvents, VKAuthButtonCallbackResult } from '@vkontakte/superappkit';

import { UserAuthStore } from '@/store/user/auth';
import { ConfigStore } from '@/store/core/config';
import { RouterStore } from '@/store/core/router';
import { PreferenceStore } from '@/store/preference/preference';

import { restApiUrl } from '@/api/constants';
import { Router } from '@/services/Utils/Router';
import { UserAuthService } from '@/services/User/Auth';

import { VkAuthMode, VkIdLoginMode } from '@/codegen/graphql';
import { DocumentEvent } from '@/types/window';


Config.init({ appId: +process.env.REACT_APP_VK_ID_APP_ID! });

/***
 * Vk login button click handler
 * @param {string} query
 */
export const redirectAuthHandler = ({ query }: { query?: string } = {}) => Connect.redirectAuth({
    url: `${restApiUrl}auth/vkId/redirect`,
    state: encodeURIComponent(Router.getStateAfterLogin(query)),
    scheme: PreferenceStore.isDark ? 'space_gray' : 'bright_light',
});


/**
 * Show the VK Floating auth panel
 */
export const initFloatingTap = () => {
    const oneTapButton = Connect.floatingOneTapAuth({
        options: {
            scheme: PreferenceStore.isDark ? 'space_gray' : 'bright_light',
            styles: {
                ...(ConfigStore.isDesktop ? { top: 50 } : { bottom: 19 }),
                zIndex: 9999,
            },
        },
        callback: async (event: VKAuthButtonCallbackResult) => {
            switch (event?.type) {
                case ConnectEvents.OneTapAuthEventsSDK.LOGIN_SUCCESS:
                    const { params } = RouterStore;

                    const { success, token } = await UserAuthService.vkTokenLogin(VkAuthMode.VkId, {
                        mode: VkIdLoginMode.VkId,
                        payload: JSON.stringify(event?.payload || {}),
                    });

                    oneTapButton?.destroy();

                    if (success && token) {
                        await UserAuthStore.authByToken(token);

                        if (params.to || params.toModalParams) {
                            await Router.openAfterLogin(params);
                        }
                    }

                    if (!success) {
                        toast.error('Что-то пошло не так :(');
                    }

                    break;
            }

            return;
        },
    });

    const frame = oneTapButton?.getFrame();

    frame && document.body.appendChild(frame);

    document.addEventListener(DocumentEvent.AuthStateChanged, () => {
        if (UserAuthStore.isLoggedIn) {
            oneTapButton?.destroy();
        }
    });
};
