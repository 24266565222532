/**
 * StoryModal styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


export const Container = styled.div`
  img {
    object-fit: cover !important;
  }

  > div > div:nth-child(2) > div {
    width: 100%;
    height: 100%;

    & > img,
    & > video {
      object-fit: cover !important;
      height: 100% !important;
      min-height: 100% !important;
      width: 100% !important;
      min-width: 100% !important;
    }
  }
`;
