/**
 * NativeFirebaseService
 *
 * @author: exode <hello@exode.ru>
 */

import { DocumentEvent } from '@/types/window';


class NativeFirebaseService {

    /**
     * Получение токена с нативного моста
     * @param {string} token
     */
    static handleNewFcmToken(token: string) {
        const customEvent = new CustomEvent(
            DocumentEvent.FcmNativeTokenReceived,
            { detail: { token } },
        );

        document.dispatchEvent(customEvent);
    }

}


export { NativeFirebaseService };
