/**
 * Content types
 *
 * @author: exode <hello@exode.ru>
 */

export enum ContentCategorySlug {
    Ege = 'ege',
    Marketing = 'marketing',
    Finance = 'finance',
    Development = 'development',
    PersonalGrowth = 'personal-growth',
    Music = 'music',
    Design = 'design',
    Photo = 'photo',
    Video = 'video',
    Preschool = 'preschool',
    Language = 'language',
    Sport = 'sport',
    Oge = 'oge',
}
