/**
 * CompanyHeader component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { useI18n } from '@/hooks/core';

import { Text, Title } from '@exode.ru/vkui';

import { IconExodeIcon } from '@/images/icons';


interface Props {
    maxWidth?: number;
    icon?: ReactElement;
    header?: ReactElement;
    text?: string | ReactElement;
    paddings?: string;
    iconSize?: number;
    className?: string;
}


const CompanyHeader = (props: Props) => {

    const { t } = useI18n('components.Company.About.Header');

    const {
        className,
        iconSize = 48,
        maxWidth = 140,
        paddings = 'pt-14 pb-16',
        text = t('makingEducationAvailable'),
        header = (
            <Title level="2" weight="2" className="my-5 text-3xl">
                {t('yourPlatformForDevelopment')}
            </Title>
        ),
        icon = (
            <IconExodeIcon className="w-2/6 mb-5 shadow-2xl xs:!max-w-[50px] xs:!max-h-[50px]" style={{
                maxWidth,
                width: iconSize,
                height: iconSize,
                borderRadius: '32%',
            }}/>
        ),
    } = props;

    return (
        <div className={[
            className,
            paddings,
            'flex flex-col items-center text-center',
        ].join(' ')}>
            {icon}
            {header}

            <Text weight="medium" className="px-5 d:px-4 text-subhead d:fs-14">
                {text}
            </Text>
        </div>
    );
};


export { CompanyHeader };
