/**
 * UseChatSendMessage
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { useRef } from 'react';

import { customAlphabet } from 'nanoid';

import { useApolloClient } from '@apollo/client';

import { toJS } from 'mobx';

import { Canceler } from 'axios';

import { useStore } from '@/pages/Core';
import { ConfigStore } from '@/store/core/config';
import { UserAuthStore } from '@/store/user/auth';
import { ProfileStore } from '@/store/user/profile';
import { ChatDialogsPageStore } from '@/pages/Chat/Dialog/store';
import { SaveStoreKeys, SavingStore } from '@/store/core/saving';

import { Http } from '@/utils';
import { Notify } from '@/cutils';
import { GqlResult } from '@/types/graphql';
import { fileTypeMapping } from '@/types/storage';

import { Router } from '@/services/Utils/Router';
import { UploadService } from '@/services/Upload/Upload';
import { UploadedFilePreviews, useI18n } from '@/hooks/core';

import {
    ChatFindManyDocument,
    ChatFindManyQuery,
    ChatMessageEntity,
    ChatMessageFindManyDocument,
    ChatMessageFindManyQuery,
    ChatMessageFindManyQueryResult,
    ChatMessageFindManyQueryVariables,
    ChatMessageStatus,
    ChatMessageType,
    MessageMediaType,
    SendMessageChatInput,
    StorageFileEntity,
    StorageFileType,
    StorageImageSize,
    StorageSpace,
    useChatMessageForwardMutation,
    useChatMessageSendMutation,
} from '@/codegen/graphql';


export interface SendChatMessagePayload {
    chatId: number;
    message: SendMessageChatInput;
    ignoreCache?: boolean;
    previews?: UploadedFilePreviews[];
    onStateChange?: (
        state: 'inserted' | 'sent' | 'error',
        payload: SendChatMessagePayload,
        messageId: number,
    ) => void;
}


export const useChatSendMessage = () => {

    const { t } = useI18n('hooks.apollo.chat');

    const { cache } = useApolloClient();

    const userTabUuid = ConfigStore.tabUuid;

    const getMediaUploadKey = (chatId: number | 'create' = 'create') => {
        return `chat:${chatId}:uploading-files`;
    };

    const getMediaIsUploading = (chatId?: number | 'create') => {
        return SavingStore.itemIsSavingRecord(
            SaveStoreKeys.Uploading,
            getMediaUploadKey(chatId),
        );
    };

    const {
        list,
        filter,
        sort,
        store,
        input,
        stater: { selectedMessages },
        params: { forwardChatId },
    } = useStore(ChatDialogsPageStore);

    const [ sendMessage, { loading: sendChatMessageLoading } ] = useChatMessageSendMutation({
        onError: (error) => console.error(error),
    });

    const cancelFileUploadRef = useRef<Canceler | null>(null);

    const getCursor = (cachedMessages: ChatMessageFindManyQuery | null) => {
        return {
            __typename: 'CursorOutput' as const,
            hasPrev: !!cachedMessages?.chatMessageFindMany.cursor.hasPrev ?? false,
            hasNext: !!cachedMessages?.chatMessageFindMany.cursor.hasNext ?? false,
            before: cachedMessages?.chatMessageFindMany.cursor.before || null,
            after: cachedMessages?.chatMessageFindMany.cursor.after || null,
        };
    };

    const getCachedMessages = (cacheVariables: ChatMessageFindManyQueryVariables) => {
        return cache.readQuery<ChatMessageFindManyQuery>({
            variables: cacheVariables,
            query: ChatMessageFindManyDocument,
        });
    };

    const getMessageMediaPayload = async (
        previews: SendChatMessagePayload['previews'],
        chatId?: number,
    ) => {
        const medias: StorageFileEntity[] = previews?.length
            ? await uploadMessageMedias(previews, chatId)
            : [];

        return medias.map(({ fileType, id }) => ({
            storageFileId: id,
            type: fileType === StorageFileType.Image
                ? MessageMediaType.PhotoVideo
                : MessageMediaType.File,
        }));
    };

    const uploadMessageMedias = async (
        previews: UploadedFilePreviews[],
        chatId?: number | 'create',
    ) => {
        const uploadKey = getMediaUploadKey(chatId);

        const formData = new FormData();

        formData.append('space', StorageSpace.User);
        Http.appendFormData(formData, 'meta', previews.map(({ info }) => info));

        previews.map(({ file }) => formData.append('files', file));

        const { success, exception, payload: filePayloads } = await UploadService.uploadFiles(
            uploadKey,
            formData,
            cancelFileUploadRef,
            undefined,
            [ StorageImageSize.Small, StorageImageSize.Medium ],
        );

        if (exception || !success) {
            Notify.vkui({
                appearance: 'error',
                message: t('loadFileFailed'),
            });

            return [];
        }

        SavingStore.setSavingRecord(SaveStoreKeys.Uploading, uploadKey, false);

        return filePayloads;
    };

    const sendChatMessage = async (
        payload: SendChatMessagePayload,
        onCompleted?: () => void,
    ) => {
        const {
            chatId,
            onStateChange,
            ignoreCache,
            previews,
            message: { text, replyMessageId },
        } = payload;

        const localMessageId = +(customAlphabet('1234567890', 10))();

        const messageVariables = {
            chatId,
            list: { ...list.messages },
            filter: { ...filter.messages },
            sort: { ...sort.messages },
        };

        const chatVariables = {
            list: { ...list.chats },
            filter: { ...filter.chats },
            sort: { ...sort.chats },
        };

        const chatMessageSend = {
            __typename: 'ChatMessageEntity' as const,
            id: localMessageId,
            text,
            userTabUuid,
            createdAt: new Date(),
            editedAt: null,
            updatedAt: null,
            isEditable: false,
            isMine: true,
            isRead: false,
            type: ChatMessageType.User,
            status: ChatMessageStatus.Sending,
            replyMessage: input.replyMessage[chatId]
                ? toJS(input.replyMessage[chatId])
                : null,
            forwardUser: null,
            user: {
                __typename: 'UserEntity' as const,
                id: UserAuthStore.user?.id || 0,
                profile: {
                    __typename: 'ProfileEntity' as const,
                    ..._.pick(ProfileStore, [
                        'id',
                        'fullName',
                        'title',
                        'emojiTitle',
                    ]),
                    titleState: {
                        ...ProfileStore.titleState,
                        __typename: 'ProfileTitleState' as const,
                    },
                    avatar: {
                        id: ProfileStore.avatar.id || 0,
                        medium: ProfileStore.avatar.medium || '',
                    },
                },
            },
            medias: previews
                ? previews.map(({ type, info }) => ({
                    __typename: 'ChatMessageMediaEntity' as const,
                    id: +(customAlphabet('1234567890', 18))(),
                    widget: null,
                    storage: {
                        __typename: 'StorageFileEntity' as const,
                        id: +(customAlphabet('1234567890', 18))(),
                        location: '',
                        createdAt: new Date(),
                        fileType: fileTypeMapping[type],
                        imageSizes: {
                            small: '',
                            medium: '',
                            maximum: '',
                        },
                        meta: {
                            __typename: 'StorageFileMeta' as const,
                            size: 1,
                            originalName: info?.name || '',
                            duration: info?.duration || null,
                            mimeType: info?.fileExtension || '',
                            dimensions: info?.dimensions || null,
                        },
                    },
                }))
                : [],
        };

        const cachedMessages = getCachedMessages(messageVariables);

        const cachedChats = cache.readQuery<ChatFindManyQuery>({
            variables: chatVariables,
            query: ChatFindManyDocument,
        });

        if (!ignoreCache) {
            cache.writeQuery<ChatMessageFindManyQuery>({
                variables: messageVariables,
                query: ChatMessageFindManyDocument,
                data: {
                    chatMessageFindMany: {
                        __typename: 'ListMessageChatOutput',
                        cursor: getCursor(cachedMessages),
                        items: [
                            ...(cachedMessages?.chatMessageFindMany.items ?? []),
                            chatMessageSend,
                        ],
                    },
                },
            });

            const chat = cachedChats?.chatFindMany.items?.find(({ id }) => id === chatId);

            if (chat) {
                cache.writeQuery<ChatFindManyQuery>({
                    variables: chatVariables,
                    query: ChatFindManyDocument,
                    data: {
                        chatFindMany: {
                            __typename: 'ListChatOutput',
                            cursor: cachedChats?.chatFindMany.cursor || {
                                after: undefined,
                                before: undefined,
                                hasPrev: false,
                                hasNext: false,
                            },
                            count: cachedChats?.chatFindMany.count || 0,
                            items: [
                                { ...chat, lastMessage: chatMessageSend },
                                ..._.orderBy(
                                    cachedChats?.chatFindMany?.items?.filter(({ id }) => id !== chatId),
                                    'lastAction',
                                    'desc',
                                ),
                            ],
                        },
                    },
                });
            }
        }

        setImmediate(() => onStateChange?.('inserted', payload, localMessageId));

        if (ConfigStore.disconnected) {
            store.addUnsentMessage({
                ...payload,
                messageId: localMessageId,
            });
        }

        await sendMessage({
            variables: {
                chatId,
                message: {
                    text,
                    userTabUuid,
                    replyMessageId,
                    medias: await getMessageMediaPayload(previews, chatId),
                },
            },
            onCompleted: () => {
                onCompleted?.();
            },
            onError: () => {
                onStateChange?.('error', payload, localMessageId);
            },
            update: (cache, { data }) => {
                if (ignoreCache) {
                    return;
                }

                const cachedMessages = getCachedMessages(messageVariables);

                const newMessageItems = [
                    ...(cachedMessages?.chatMessageFindMany.items || []).map((message) => (
                        message.id !== localMessageId ? message : data?.chatMessageSend
                    )),
                ] as GqlResult<ChatMessageFindManyQueryResult>['chatMessageFindMany']['items'];

                data?.chatMessageSend && cache.writeQuery<ChatMessageFindManyQuery>({
                    variables: messageVariables,
                    query: ChatMessageFindManyDocument,
                    data: {
                        chatMessageFindMany: {
                            __typename: 'ListMessageChatOutput',
                            items: newMessageItems,
                            cursor: getCursor(cachedMessages),
                        },
                    },
                });

                onStateChange?.('sent', payload, localMessageId);
            },
        });
    };

    const removeUnsentChatMessage = (chatId: number, messageId: number) => {
        const cacheVariables = {
            chatId,
            list: { ...list.messages },
            filter: { ...filter.messages },
            sort: { ...sort.messages },
        };

        const cachedMessages = getCachedMessages(cacheVariables);

        cache.writeQuery<ChatMessageFindManyQuery>({
            variables: cacheVariables,
            query: ChatMessageFindManyDocument,
            data: {
                chatMessageFindMany: {
                    __typename: 'ListMessageChatOutput',
                    items: cachedMessages?.chatMessageFindMany.items?.filter(({ id }) => id !== messageId),
                    cursor: getCursor(cachedMessages),
                },
            },
        });
    };

    const [ _forwardChatMessage ] = useChatMessageForwardMutation();

    const forwardChatMessage = (
        targetChatId: number,
        forwardingMessages: ChatMessageEntity[],
    ) => {
        return _forwardChatMessage({
            variables: {
                targetChatId,
                chatId: +forwardChatId,
                message: {
                    userTabUuid,
                    forwardMessageIds: _.map(forwardingMessages, 'id'),
                },
            },
            update: (cache, { data }) => {
                const variables = {
                    chatId: targetChatId,
                    list: { ...list.messages },
                    filter: { ...filter.messages },
                    sort: { ...sort.messages },
                };

                const cachedMessages = getCachedMessages(variables);

                if (!cachedMessages) {
                    return console.warn('[Cache]: cachedChatMessage отсутствуют в кэше');
                }

                data && cache.writeQuery<ChatMessageFindManyQuery>({
                    query: ChatMessageFindManyDocument,
                    variables,
                    data: {
                        chatMessageFindMany: {
                            __typename: 'ListMessageChatOutput',
                            cursor: cachedMessages.chatMessageFindMany.cursor,
                            items: [
                                ...(cachedMessages.chatMessageFindMany.items ?? []),
                                ...(
                                    data?.chatMessageForward.sent
                                        ? [ data?.chatMessageForward.sent ]
                                        : []
                                ),
                                ...(data?.chatMessageForward.forwarded ?? []),
                            ],
                        },
                    },
                });
            },
            onCompleted: () => {
                store.setSelectedMessages({ ...selectedMessages, [+forwardChatId]: [] });

                Router.replacePage('/chat', { chatId: `${targetChatId}` });
            },
        });
    };

    return {
        sendChatMessage,
        forwardChatMessage,
        getMediaIsUploading,
        getMessageMediaPayload,
        sendChatMessageLoading,
        removeUnsentChatMessage,
    };
};
