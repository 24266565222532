/**
 * Seller types
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { useI18n } from '@/hooks/core/useI18n';

import {
    GetUserSellersQueryResult,
    SellerBalanceOperationFragmentFragment,
    SellerBalanceSource,
    SellerBecomeRequestFindOneQueryResult,
    SellerBecomeRequestStatus,
    SellerCurrentUserSellerQueryResult,
    SellerOrganizationDocumentFragmentFragment,
    SellerOrganizationDocumentType,
    SellerOrganizationForm,
    SellerType,
} from '@/codegen/graphql';

import { GqlResult } from './graphql';

import {
    Icon28ArrowDownOutline,
    Icon28ArrowUpOutline,
    Icon28ArrowUturnLeftOutline,
    Icon28BankOutline,
    Icon28EducationOutline,
    Icon28MoneyCircleOutline,
    Icon28MoneyRequestOutline,
    Icon28MoneyWadOutline,
    Icon28PaymentCardOutline,
    Icon28UserTagOutline,
} from '@vkontakte/icons';

import { gradient as stylesGradient } from '@/styles/modules/gradient';


export type SellerOfCurrentUser = GqlResult<SellerCurrentUserSellerQueryResult>['sellerCurrentUserSeller'];
export type SellersOfCurrentUser = Exclude<GqlResult<GetUserSellersQueryResult>['sellerFindManyByUser']['items'], undefined | null>;
export type SellerBecomeRequestItem = GqlResult<SellerBecomeRequestFindOneQueryResult>['sellerBecomeRequestFindOne'];
export type SellerBalanceOperationItem = SellerBalanceOperationFragmentFragment;
export type SellerDocumentItem = SellerOrganizationDocumentFragmentFragment;

/**
 * Виды организаций продавца
 */
export const sellerOrganizationForm: { [organization in SellerOrganizationForm]: string } = {
    [SellerOrganizationForm.Ip]: 'ИП',
    [SellerOrganizationForm.SelfEmployed]: 'Самозанятый',
    [SellerOrganizationForm.Ooo]: 'ООО',
    [SellerOrganizationForm.Oao]: 'ОАО',
    [SellerOrganizationForm.Ao]: 'АО',
    [SellerOrganizationForm.Zao]: 'ЗАО',
    [SellerOrganizationForm.Pao]: 'ПАО',
    [SellerOrganizationForm.Ano]: 'АНО',
    [SellerOrganizationForm.Nao]: 'НАО',
    [SellerOrganizationForm.Nko]: 'НКО',
    [SellerOrganizationForm.Fgbnu]: 'ФГБНУ',
    [SellerOrganizationForm.Mbuk]: 'МБУК',
    [SellerOrganizationForm.Pk]: 'ПК',
    [SellerOrganizationForm.Up]: 'УП',
    [SellerOrganizationForm.GosEdOrg]: 'Образовательное гос. учреждение',
    [SellerOrganizationForm.SelfHostedSchool]: 'Школа',
} as const;

/**
 * Сортированные формы организации
 */
const sellerOrganizationFormOrdered = [
    SellerOrganizationForm.Ip,
    SellerOrganizationForm.SelfEmployed,
    SellerOrganizationForm.Ooo,
    SellerOrganizationForm.Oao,
    SellerOrganizationForm.Ao,
    SellerOrganizationForm.Zao,
    SellerOrganizationForm.Pao,
    SellerOrganizationForm.Ano,
    SellerOrganizationForm.Nao,
    SellerOrganizationForm.Nko,
    SellerOrganizationForm.Fgbnu,
    SellerOrganizationForm.Mbuk,
    SellerOrganizationForm.Pk,
    SellerOrganizationForm.Up,
    SellerOrganizationForm.GosEdOrg,
];

/**
 * Виды организаций по виду продавца
 */
export const sellerTypeOrganizationType: { [type in SellerType]: SellerOrganizationForm[] } = {
    [SellerType.Tutor]: [
        SellerOrganizationForm.SelfEmployed,
        SellerOrganizationForm.Ip,
    ],
    [SellerType.Producer]: [
        ...Object.values(sellerOrganizationFormOrdered).filter(e => ![
            SellerOrganizationForm.SelfEmployed,
            SellerOrganizationForm.GosEdOrg,
        ].includes(e)),
    ],
    [SellerType.University]: [
        SellerOrganizationForm.GosEdOrg,
    ],
    [SellerType.School]: [
        SellerOrganizationForm.SelfHostedSchool,
    ],
};

/**
 * Виды продавца
 */
export const sellerType = () => {

    const { t } = useI18n('types.seller');

    return {
        [SellerType.Tutor]: t('tutor'),
        [SellerType.Producer]: t('producer'),
        [SellerType.University]: t('university'),
        [SellerType.School]: t('school'),
    } as const;
};

/**
 * Иконки вида продавца
 */
export const sellerTypeIcons: { [type in SellerType]: ReactElement } = {
    [SellerType.Tutor]: <Icon28UserTagOutline/>,
    [SellerType.Producer]: <Icon28BankOutline/>,
    [SellerType.School]: <Icon28BankOutline/>,
    [SellerType.University]: <Icon28EducationOutline/>,
};

/**
 * Иконки баланса продавца
 */
export const sellerBalanceOperationCategory = () => {

    const { t } = useI18n('types.seller');

    return {
        [SellerBalanceSource.BalanceUpPayment]: {
            icon: <Icon28PaymentCardOutline/>,
            badge: <Icon28ArrowDownOutline/>,
            gradient: stylesGradient.purple500ToBlue500,
            text: t('topUpUponInvoicePayment'),
        },
        [SellerBalanceSource.BalanceDownRefund]: {
            icon: <Icon28MoneyWadOutline/>,
            badge: <Icon28ArrowUturnLeftOutline/>,
            gradient: stylesGradient.gray500ToGray400,
            text: t('payoutUponReturnOfInvoice'),
        },
        [SellerBalanceSource.BalanceUpScheduled]: {
            icon: <Icon28MoneyCircleOutline/>,
            badge: <Icon28ArrowDownOutline/>,
            gradient: stylesGradient.green500ToLime600,
            text: t('topUpOfBalanceForPayout'),
        },
        [SellerBalanceSource.BalanceDownPayout]: {
            icon: <Icon28MoneyRequestOutline/>,
            badge: <Icon28ArrowUpOutline/>,
            gradient: stylesGradient.green500ToLime600,
            text: t('payoutOfFunds'),
        },
    } as const;
};

/**
 * Статусы "заявка в партнеры"
 */
export const requestStatusName = () => {

    const { t } = useI18n('types.seller');

    return {
        [SellerBecomeRequestStatus.Initialized]: t('requestCreated'),
        [SellerBecomeRequestStatus.FormDataChecking]: t('applicationUnderConsideration'),
        [SellerBecomeRequestStatus.FormDataDeclined]: t('requestRejected'),
        [SellerBecomeRequestStatus.OnAgreementSigning]: t('waitingForAnAgreement'),
        [SellerBecomeRequestStatus.AgreementChecking]: t('agreementPending'),
        [SellerBecomeRequestStatus.AgreementDataDeclined]: t('agreementRejected'),
        [SellerBecomeRequestStatus.Verified]: t('requestVerified'),
    } as const;
};

/**
 * Статусы заявки в короткой форме
 */
export const requestStatusShortName = () => {

    const { t } = useI18n('types.seller');

    return {
        [SellerBecomeRequestStatus.FormDataChecking]: t('underReview'),
        [SellerBecomeRequestStatus.FormDataDeclined]: t('formRejected'),
        [SellerBecomeRequestStatus.OnAgreementSigning]: t('onWaitingForSigning'),
        [SellerBecomeRequestStatus.AgreementChecking]: t('underReview'),
        [SellerBecomeRequestStatus.AgreementDataDeclined]: t('agreementRejected'),
    } as { [type in SellerBecomeRequestStatus]?: string };
};

/**
 * Определение успешности/ожидания/отклонения по статусу
 * @type {{waiting: any[], success: any[], error: any[]}}
 */
export const requestStatusDefinition = {
    success: [
        SellerBecomeRequestStatus.Initialized,
        SellerBecomeRequestStatus.Verified,
    ],
    waiting: [
        SellerBecomeRequestStatus.AgreementChecking,
        SellerBecomeRequestStatus.FormDataChecking,
        SellerBecomeRequestStatus.OnAgreementSigning,

    ],
    error: [
        SellerBecomeRequestStatus.AgreementDataDeclined,
        SellerBecomeRequestStatus.FormDataDeclined,
    ],
};

/**
 * Define request status
 * @param { | null | undefined} status
 */
export const requestStatusDefine = (
    status: SellerBecomeRequestStatus | null | undefined,
) => {
    if (!status) {
        return null;
    }

    for (const key in requestStatusDefinition) {
        if (requestStatusDefinition[key as keyof typeof requestStatusDefinition].includes(status)) {
            return key as keyof typeof requestStatusDefinition;
        }
    }

    return 'success';
};

/**
 * Статусы "заявка в партнеры"
 */
export const sellerOrganizationDocumentType = () => {

    const { t } = useI18n('types.seller');

    return {
        [SellerOrganizationDocumentType.AgreementToSign]: t('agreementToSign'),
        [SellerOrganizationDocumentType.PassportMain]: t('passportMainPage'),
        [SellerOrganizationDocumentType.AgreementSigned]: t('signedAgreement'),
        [SellerOrganizationDocumentType.PassportRegistration]: t('passportRegistration'),
        [SellerOrganizationDocumentType.SelfUploaded]: t('selfUploaded'),
    } as const;
};


/***
 * Check is organization
 * @param {SellerOrganizationForm} value
 * @returns {boolean}
 */
export const checkIsOrganization = (value: SellerOrganizationForm) => {
    return ![ SellerOrganizationForm.SelfEmployed ].includes(value);
};
