/**
 * PriceAmountPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If } from '@/cutils';
import { AmountText } from '@/components/Atoms/AmountText';

import { CoursePriceCardProps } from '../CoursePriceCard';


interface Props extends Pick<CoursePriceCardProps['price'],
    'amount' |
    'previousAmount' |
    'amountExchangeRates'> {
    currency: CoursePriceCardProps['currency'];
    currenClassName?: string;
    previousClassName?: string;
}


const PriceAmountPart = (props: Props) => {

    const {
        amount,
        currency,
        previousAmount,
        amountExchangeRates,
        currenClassName = 'fs-20 flex mt-1',
        previousClassName = 'line-through-diagonally fs-14 mt-1 ml-2',
    } = props;

    return (
        <>
            <span className={currenClassName}>
                <AmountText semibold={false} amount={amount} currency={currency} exchange={amountExchangeRates}/>
            </span>

            <If is={!!previousAmount}>
                <span className={previousClassName}>
                    <AmountText semibold={false}
                                currency={currency}
                                amount={previousAmount || 0}
                                exchange={amountExchangeRates}/>
                </span>
            </If>
        </>
    );
};


export { PriceAmountPart };
