/**
 * FloatingAgoraButtons component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core';

import { useLocation } from '@/router/index';

import { Button, ButtonGroup } from '@exode.ru/vkui';

import { If, Link } from '@/cutils';
import { useAgora } from '@/components/App/AgoraProvider';

import { Container } from './FloatingAgoraButtons.styled';


const FloatingAgoraButtons = () => {

    const { t } = useI18n('components.Atoms.FloatingAgoraButtons');

    const { route: { pageId } } = useLocation();

    const { state, setState } = useAgora();

    const leave = () => setState({});

    return (
        <If is={state.isOnCall && pageId !== '/bookmarks'}>
            <Container>
                <ButtonGroup gap="m">
                    <Link pushPage={{ id: '/bookmarks' }}>
                        <Button size="s" appearance="positive">
                            {t('backToCall')}
                        </Button>
                    </Link>

                    <Button onClick={leave} size="s" mode="overlay_primary">
                        {t('leave')}
                    </Button>
                </ButtonGroup>
            </Container>
        </If>
    );
};


export { FloatingAgoraButtons };
