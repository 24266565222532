/**
 * ManageCourseInformationPage store
 *
 * @author: exode <hello@exode.ru>
 */

import { CourseLessonStatus, SortDirection } from '@/codegen/graphql';

import { makeObservable } from 'mobx';
import { observer, PageStore } from '@/pages/Core';


class ManageCourseContentPage extends PageStore {

    constructor() {
        super();
        makeObservable(this);
    }

    state = {
        list: {
            skip: 0,
            take: 100,
        },
        filter: {
            statuses: [
                CourseLessonStatus.Draft,
                CourseLessonStatus.ReadyToPublish,
                CourseLessonStatus.Published,
            ],
        },
        sort: { createdAt: SortDirection.Asc },
    };

}

const ManageCourseContentPageStore = new ManageCourseContentPage();


export { observer, ManageCourseContentPageStore };
