/**
 * MessagesMenuPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer } from '@/pages/Core';

import { Link } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { RoutePathType } from '@/router/paths';

import { SimpleCell } from '@exode.ru/vkui';
import { Icon24MessageOutline, Icon28MessageOutline } from '@vkontakte/icons';

import { ChatIndicatorPart } from '@/components/Navigation/Tab/BarItems/parts/ChatIndicatorPart';


interface Props {
    isMobile?: boolean;
    isCurrentPathClassName?: (paths: RoutePathType[]) => string;
}


const MessagesMenuPart = observer((props: Props) => {

    const { t } = useI18n('components.Desktop.Navigation.Menu');

    const { isCurrentPathClassName, isMobile = false } = props;

    return (
        <Link pushPage={{ id: '/chat' }}>
            <SimpleCell expandable={!isMobile}
                        className={isCurrentPathClassName?.([ '/chat' ])}
                        after={<ChatIndicatorPart counterProps={{ size: 'm' }}/>}
                        before={isMobile ? <Icon28MessageOutline/> : <Icon24MessageOutline/>}>
                {t('chat')}
            </SimpleCell>
        </Link>
    );
});


export { MessagesMenuPart };
