/**
 * UseSchoolLegal
 *
 * @author: exode <hello@exode.ru>
 */

import { ManageSchoolLegalPageStore } from '@/pages/Manage/School/Legal/store';

import { useStore } from '@/pages/Core';

import {
    CreateOrganizationDocumentSellerInput,
    SellerOrganizationDocumentFindManyDocument,
    SellerOrganizationDocumentFindManyQuery,
    useSellerOrganizationDocumentCreateMutation,
    useSellerOrganizationDocumentUpdateMutation,
} from '@/codegen/graphql';


export const useSchoolLegal = () => {

    const { list, filter, sort } = useStore(ManageSchoolLegalPageStore);

    const [ _documentCreate, {
        loading: documentCreateLoading,
    } ] = useSellerOrganizationDocumentCreateMutation({
        update: (cache, { data }) => {

            const variables = {
                list: { ...list },
                filter: { ...filter },
                sort: { ...sort },
            };

            const cachedDocuments = cache.readQuery<SellerOrganizationDocumentFindManyQuery>({
                query: SellerOrganizationDocumentFindManyDocument,
                variables,
            });

            if (!cachedDocuments) {
                return console.warn('[Cache]: cachedDocuments отсутствуют в кэше');
            }

            const { count, items, page, pages } = cachedDocuments.sellerOrganizationDocumentFindMany;

            data && cache.writeQuery<SellerOrganizationDocumentFindManyQuery>({
                query: SellerOrganizationDocumentFindManyDocument,
                variables,
                data: {
                    sellerOrganizationDocumentFindMany: {
                        page,
                        pages,
                        count: data.sellerOrganizationDocumentCreate && ((count ?? 1) + 1),
                        items: data?.sellerOrganizationDocumentCreate
                            ? [ data?.sellerOrganizationDocumentCreate, ...(items || []) ]
                            : (items || []),
                    },
                },
            });
        },
    });

    const documentCreate = (
        document: CreateOrganizationDocumentSellerInput,
        onCompleted?: () => void,
    ) => {
        return _documentCreate({
            variables: { document },
            onCompleted: () => onCompleted?.(),
        });
    };

    const [ _documentUpdate, {
        loading: documentUpdateLoading,
    } ] = useSellerOrganizationDocumentUpdateMutation();

    const documentUpdate = (
        documentId: number,
        document: CreateOrganizationDocumentSellerInput,
        onCompleted?: () => void,
    ) => {
        return _documentUpdate({
            variables: { documentId, document },
            onCompleted: () => onCompleted?.(),
        });
    };

    return {
        documentCreate,
        documentCreateLoading,
        documentUpdate,
        documentUpdateLoading,
    };
};