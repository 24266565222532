/**
 * AppProvider
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { QueryClient, QueryClientProvider } from 'react-query';

import { Toaster } from 'react-hot-toast';

import { apolloClient } from '@/api/graphql';
import { ApolloProvider } from '@apollo/client';
import { observer, PreferenceStore } from '@/store/preference/preference';

import { If } from '@/cutils';
import { LocaleProviderContext, ScreenSpinner } from '@exode.ru/vkui';

import { AgoraProvider } from '@/components/App/AgoraProvider';
import { AppPreloader } from '@/components/App/AppPreloader';
import { ConfigProvider } from '@/components/App/ConfigProvider';
import { ErrorBoundary } from '@/components/App/ErrorBoundary';
import { FcmProvider } from '@/components/App/FcmProvider';
import { NotistackProvider } from '@/components/App/NotistackProvider';
import { SellerProvider } from '@/components/App/SellerProvider';
import { SocketProvider } from '@/components/App/SocketProvider';


export const AppProvider = ({ children }: { children: ReactElement }) => (
    <AppCoreProviders>
        <AppLibsProviders>
            <AppUiProviders>
                {children}
            </AppUiProviders>
        </AppLibsProviders>
    </AppCoreProviders>
);

const AppCoreProviders = ({ children }: { children: ReactElement }) => (
    <ErrorBoundary>
        <React.StrictMode>
            {children}
        </React.StrictMode>
    </ErrorBoundary>
);

const AppLibsProviders = ({ children }: { children: ReactElement }) => {

    const queryClient = new QueryClient();

    return (
        <ApolloProvider client={apolloClient}>
            <QueryClientProvider client={queryClient}>
                <SocketProvider>
                    <FcmProvider>
                        <AgoraProvider>
                            <SellerProvider>
                                {children}
                            </SellerProvider>
                        </AgoraProvider>
                    </FcmProvider>
                </SocketProvider>
            </QueryClientProvider>
        </ApolloProvider>
    );
};

const AppUiProviders = observer(({ children }: { children: ReactElement }) => (
    <ConfigProvider>
        <NotistackProvider>
            <LocaleProviderContext.Provider value="ru">
                {children}
            </LocaleProviderContext.Provider>

            <If is={PreferenceStore.loading}>
                <AppPreloader dark={PreferenceStore.isDark}/>
            </If>

            <If is={PreferenceStore.spinner}>
                <ScreenSpinner className="z-10"/>
            </If>

            <Toaster containerClassName="pt-safe pb-safe mb-2" toastOptions={{
                className: 'vk-rounded',
                position: 'bottom-center',
            }}/>
        </NotistackProvider>
    </ConfigProvider>
));
