/**
 * PdfViewer styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 0 0 0;
    padding: 15px;
    height: 100%;
    overflow-y: auto;
    background-color: var(--black_jet);
`;

export const DocumentContainer = styled.div`
    margin-top: calc(env(safe-area-inset-top, 0px));
`;
