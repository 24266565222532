/**
 * LeftPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Link, Responsive } from '@/cutils';

import { DesktopNavigationMenuDrawer } from '@/components/Desktop/Navigation';

import { HeaderLogoPart } from './HeaderLogo';


interface Props {

}


const LeftPart = (props: Props) => {
    return (
        <div className="viewport__left-menu vkuiSplitCol--spaced h-full">
            <div className="flex items-center h-full">
                <DesktopNavigationMenuDrawer/>

                <Responsive.DesktopOnly>
                    <Link pushPage={{ id: '/' }}>
                        <HeaderLogoPart/>
                    </Link>
                </Responsive.DesktopOnly>
            </div>
        </div>
    );
};


export { LeftPart };
