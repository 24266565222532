/**
 * App tournament subscriptions
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer, SubscriptionStore } from '@/store/subscription/subscription';

import { TournamentSubscription } from './TournamentSubscription';
import { TournamentHandleDisconnect } from './TournamentHandleDisconnect';
import { TournamentMembersSubscription } from './TournamentMembersSubscription';
import { TournamentChatMessagesSubscription } from './TournamentChatMessagesSubscription';
import { TournamentMembersProgressSubscription } from './TournamentMembersProgressSubscription';


const AppTournamentSubscription = observer(() => {
    return (
        <>
            <TournamentHandleDisconnect/>

            {[ ...SubscriptionStore.subscribedTournaments ].map((id) => (
                <TournamentSubscription key={id} id={id}/>
            ))}

            {[ ...SubscriptionStore.subscribedTournamentMembers ].map((id) => (
                <TournamentMembersSubscription key={id} id={id}/>
            ))}

            {[ ...SubscriptionStore.subscribedTournamentMembersProgress ].map((id) => (
                <TournamentMembersProgressSubscription key={id} id={id}/>
            ))}

            {[ ...SubscriptionStore.subscribedTournamentChatMessages ].map((id) => (
                <TournamentChatMessagesSubscription key={id} id={id}/>
            ))}
        </>
    );
});


export { AppTournamentSubscription };
