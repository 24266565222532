/**
 * TournamentSubscription component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { DocumentEvent } from '@/types/window';

import { useTournamentListenNewMember, useTournamentSetState } from '@/hooks/apollo';


interface Props {
    id: number;
}


const TournamentSubscription = (props: Props) => {

    const { id } = props;

    useTournamentSetState(id, {
        onStateChange: (next) => {
            const customEvent = new CustomEvent(
                DocumentEvent.TournamentOnStateChange,
                { detail: { next } },
            );

            document.dispatchEvent(customEvent);
        },
    });

    useTournamentListenNewMember(id);

    return (<></>);
};


export { TournamentSubscription };
