/**
 * App
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import './libs/i18n/index';

import { RouterContext } from 'router.tsx';

import { RouterStore } from '@/store/core/router';
import { ConfigStore, observer } from '@/store/core/config';
import { PreferenceStore } from '@/store/preference/preference';

import { IS_VK } from '@/root/src/env';
import { router } from '@/router/index';
import { AdaptivityProvider, AppRoot, ConfigProvider, SplitLayout, WebviewType } from '@exode.ru/vkui';

import { DesktopPanelHeader } from '@/components/Desktop/Panel';
import { FooterBlock } from '@/components/Navigation/Block';
import { PermanentRender } from '@/components/App/PermanentRender';

import { Modals } from './root-views/Modals';
import { AppProvider } from './app/AppProvider';
import { AppSplitColumn } from './app/AppSplitColumn';


const App = observer(() => {
    return (
        <RouterContext.Provider value={router}>
            <AppProvider>
                <ConfigProvider isWebView={ConfigStore.isWebView}
                                webviewType={!IS_VK ? WebviewType.INTERNAL : undefined}
                                appearance={PreferenceStore.scheme === 'space_gray' ? 'dark' : 'light'}>
                    <AdaptivityProvider>
                        <AppRoot className={PreferenceStore.isDark ? 'dark' : ''}>

                            <PermanentRender/>

                            <SplitLayout className="viewport" modal={<Modals/>} popout={RouterStore.popoutElement}>
                                <DesktopPanelHeader/>

                                <AppSplitColumn type={RouterStore.type}/>
                            </SplitLayout>

                            <FooterBlock/>

                        </AppRoot>
                    </AdaptivityProvider>

                </ConfigProvider>
            </AppProvider>
        </RouterContext.Provider>
    );
});


export { App };
