/**
 * Environment variables
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { Url } from '@/utils';
import { AppPlatformType } from '@/types/core';
import { getSessionLauncher } from '@/helpers/app';
import { SessionLauncher } from '@/codegen/graphql';
import { AppServeDomain, PageSpace } from '@/shared/types';

import packageJson from '@/root/package.json';


/** CHANGEABLE: */

/** Состояние приложения 'production' | 'staging' | 'development' */
export const NODE_ENV: string = process.env.REACT_APP_NODE_ENV?.trim()
    || process.env.NODE_ENV?.trim()
    || 'development';

/** Поддомен развертывания */
export const SUBDOMAIN = typeof window !== 'undefined'
    ? window.location.hostname.split('.').splice(0, 1)?.[0]
    : '';

/** Домен развертывания (ru, uz, kz, ...) */
export const SERVE_DOMAIN = typeof window !== 'undefined'
    ? _.capitalize(window.location.hostname.split('.').at(-1)) as AppServeDomain
    : AppServeDomain.Ru;

/** Домен развертывания (exode) */
const DOMAIN = typeof window !== 'undefined'
    ? window.location.hostname.split('.').at(-2)
    : 'exode';

/** Целевая платформа релиза */
export const OS_PLATFORM: AppPlatformType = [ 'android', 'ios' ].includes(SUBDOMAIN)
    ? SUBDOMAIN as AppPlatformType
    : (
        process.env.REACT_APP_PLATFORM
            ? process.env.REACT_APP_PLATFORM.trim() as AppPlatformType
            : 'web'
    );

/** Версия API в запросах приложения */
export const API_VERSION: string = '2';

/** BUSINESS-PLATFORM: */

/** Контекст платформы "Маркетплейс" */
export const MARKETPLACE = typeof window !== 'undefined'
    ? window.exode.marketplace
    : null;

/** Контекст платформы "Школа" */
export const IS_SCHOOL: boolean = typeof window !== 'undefined'
    ? !!window.exode.school
    : false;

/** Платформа управления аккаунтами .biz */
export const IS_BIZ_WELCOME = SUBDOMAIN === 'welcome';

/** Текущая платформа */
export const CURRENT_PLATFORM = IS_SCHOOL
    ? PageSpace.SchoolPlatform
    : (
        IS_BIZ_WELCOME
            ? PageSpace.BizWelcomePlatform
            : PageSpace.MarketplacePlatform
    );

/** DEPENDENT: */

/** Текущая версия приложения */
export const APP_VERSION = ((): string => {
    const search = Url.parseQuery(
        typeof window !== 'undefined'
            ? window.location.search
            : '',
    );

    return search?.APP_VERSION || packageJson.version;
})();

export const BUILD_HASH = packageJson.buildhash;

/** Приложение для встраивания в native web view */
export const IS_NATIVE: boolean = [ 'android', 'ios' ].includes(OS_PLATFORM);

/** Приложение для vk mini apps */
export const IS_VK: boolean = [ OS_PLATFORM, SUBDOMAIN ].includes('vk');

/** Приложение для браузера (веб-сайта) */
export const IS_WEB: boolean = !IS_NATIVE && !IS_VK && OS_PLATFORM === 'web';

/** Лаунчер */
export const LAUNCHER: SessionLauncher = getSessionLauncher();

/** Состояние приложения в разработке */
export const IS_DEV: boolean = NODE_ENV === 'development';

/** Состояние приложения в тестировании */
export const IS_STAGING: boolean = NODE_ENV === 'staging';

/** Состояние приложения в продакшене */
export const IS_PROD: boolean = NODE_ENV === 'production';

/** Хост для express приложения */
export const SERVER_HOST: string = process.env.HOST || process.env.REACT_APP_SERVER_HOST || '0.0.0.0';

/** Порт для express приложения */
export const SERVER_PORT: number = Number(process.env.PORT || process.env.REACT_APP_SERVER_PORT || 3006);

/** Протокол обращения к API */
export const HTTP_PROTOCOL: 'http' | 'https' = IS_DEV ? 'http' : 'https';

/** Протокол обращения к GraphQL WS */
export const WS_PROTOCOL: 'ws' | 'wss' = IS_DEV ? 'wss' : 'wss';

/** Домен API */
export const API_DOMAIN: string = IS_DEV
    ? 'localhost:3010'
    : (IS_STAGING ? `staging.${DOMAIN}.${SERVE_DOMAIN}` : `${DOMAIN}.${SERVE_DOMAIN}`);


/** Домен сайта (без протокола) */
export const APP_DOMAIN: string = IS_DEV
    ? ('localhost:' + SERVER_PORT)
    : (IS_STAGING ? `staging.${DOMAIN}.${SERVE_DOMAIN}` : `${DOMAIN}.${SERVE_DOMAIN}`).toLowerCase();

/** Url сайта (домашняя страница) */
export const APP_URL: string = `${HTTP_PROTOCOL}://${IS_SCHOOL ? SUBDOMAIN + '.' : ''}${APP_DOMAIN}`;

/** Файловое хранилище (max size in bytes) */
export const STORAGE_UPLOAD_MAX_SIZE = 5120 * 1e9;

/** Стартовый капитал пользователя */
export const WALLET_START_CAPITAL = 3000;
