/**
 * RecoverPasswordInput
 *
 * @author: exode <hello@exode.ru>
 */

import { IsEmail, IsNotEmpty } from 'class-validator';

import { isEmail, isNotEmpty } from '../constants';


export class RecoverPasswordInput {

    @IsNotEmpty({ message: isNotEmpty('Логин') })
    @IsEmail(undefined, { message: isEmail })
    readonly login: string;

}
