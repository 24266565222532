/**
 * StoriesView
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { useI18n } from '@/hooks/core';
import { Graphql, If, Link } from '@/cutils';

import { FilterStoriesInput, StoriesFindManyQuery } from '@/codegen/graphql';

import { Icon24StoryOutline } from '@vkontakte/icons';
import { Counter, Header, HorizontalScroll } from '@exode.ru/vkui';

import { Placeholder } from '@/components/Atoms/Placeholder';
import { EducationStoryCard } from '@/components/Education/StoryCard';
import { EducationStoriesSubSkeleton } from '@/components/Atoms/Skeleton/EducationStories';

import { gradient } from '@/styles/modules/gradient';


interface Props {
    filter?: FilterStoriesInput;
}

export const storiesList = { list: { skip: 0 } };


const StoriesView = ({ filter }: Props) => {

    const { t } = useI18n('tabs.Education.views');

    return (
        <StoriesFindManyQuery children={(result) => (
            <>
                <Graphql.Loading result={result}>
                    <EducationStoriesSubSkeleton/>
                </Graphql.Loading>

                <Graphql.Success result={result}>
                    {({ storiesFindMany: { items, count } }) => (
                        <>
                            <Header mode="primary" aside={(
                                <Counter className={[
                                    'px-1 py-0.5 bg-gradient-to-r text-white',
                                    gradient.blue600ToPurple500Gradient,
                                ].join(' ')} mode="primary" size="s">
                                    {t('stories', { count })}
                                </Counter>
                            )}>
                                <span className="text-underline-marker h-after-4">
                                    {t('briefAndInformative')}
                                </span>
                            </Header>

                            <If is={!_.isEmpty(items)}>
                                <HorizontalScroll showArrows
                                                  className="mt-2"
                                                  getScrollToLeft={(i) => i - 120}
                                                  getScrollToRight={(i) => i + 120}>
                                    <div className="flex mb-4">
                                        {items?.map(({ id, title, wrapper, hasView }, index) => (
                                            <Link key={id} pushModal={{
                                                id: 'story',
                                                params: { storyIndex: `${index}` },
                                            }}>
                                                <EducationStoryCard title={title}
                                                                    hasView={hasView}
                                                                    image={wrapper || ''}/>
                                            </Link>
                                        ))}
                                    </div>
                                </HorizontalScroll>
                            </If>

                            <If is={_.isEmpty(items)}>
                                <Placeholder innerPadding="0px" iconSize={72} header={t('storiesNotFound')} icon={(
                                    <Icon24StoryOutline className="pt-0 text-accent opacity-[0.85]"/>
                                )} content={(
                                    <span className="m:w-full w-9/10 flex mx-auto">
                                        {t('newStoriesWillAppearHere')}
                                    </span>
                                )}/>
                            </If>
                        </>
                    )}
                </Graphql.Success>

                <Graphql.Error result={result}/>
            </>
        )} variables={{ ...storiesList, filter: filter || {} }}/>
    );
};


export { StoriesView };
