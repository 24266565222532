/**
 * EducationStoriesSubSkeleton component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core';
import { Skeleton } from '@mui/material';
import { Counter, Header, HorizontalScroll } from '@exode.ru/vkui';

import { gradient } from '@/styles/modules/gradient';


const EducationStoriesSubSkeleton = () => {

    const { t } = useI18n('components.Atoms.Skeleton');

    return (
        <>
            <Header className="mb-2" mode="primary" aside={(
                <Counter size="s" mode="primary" className={[
                    'px-1 py-0.5 bg-gradient-to-r text-white',
                    gradient.blue600ToPurple500Gradient,
                ].join(' ')}>
                    {t('stories', { count: 0 })}
                </Counter>
            )}>
                <span className="text-underline-marker h-after-4">
                    {t('briefAndInformative')}
                </span>
            </Header>

            <HorizontalScroll showArrows className="mt-2">
                <div className="flex mb-4 first:ml-[10px]">
                    {Array(8).fill(0).map((_, index) => (
                        <Skeleton key={index}
                                  width={125}
                                  height={125}
                                  variant="rectangular"
                                  className="mx-1.5 min-w-[125px] rounded-[18px]"/>
                    ))}
                </div>
            </HorizontalScroll>
        </>
    );
};


export { EducationStoriesSubSkeleton };
