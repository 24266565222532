/**
 * ProfileNotificationCard component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { NotificationsQueryResult, NotificationStatus } from '@/codegen/graphql';
import { ButtonGroup, RichCell } from '@exode.ru/vkui';

import { Time } from '@/utils';
import { GqlResult } from '@/types/graphql';

import { NotificationIcon } from '../Icon/NotificationIcon';
import { NotificationSignal } from '../Signal/NotificationSignal';

import { Container } from './ProfileNotificationCard.styled';
import { ProfileNotificationActionButton } from '@/components/Profile/Notifications/ActionButton';


export interface ProfileNotificationCardProps {
    notification: Exclude<GqlResult<NotificationsQueryResult>['notificationFindMany']['items'], null | undefined>[number];
    isInSnackbar?: boolean;
}


const ProfileNotificationCard = (props: ProfileNotificationCardProps) => {

    const {
        isInSnackbar = false,
        notification: {
            id,
            text,
            title,
            type,
            actions,
            status,
            createdAt,
            actor,
            icons: {
                main,
            },
        },
    } = props;

    const after = status === NotificationStatus.Unread
        ? <NotificationSignal notificationId={id} status={status}/>
        : <></>;

    return (
        <Container isInSnackbar={isInSnackbar}>
            <RichCell key={id}
                      disabled
                      multiline
                      text={text}
                      after={!isInSnackbar && after}
                      caption={Time.parseRelative(createdAt)}
                      before={<NotificationIcon iconUrl={main.url} actorId={actor.identifier} notificationType={type}/>}
                      actions={(
                          <ButtonGroup>
                              {actions?.map(({ title, arguments: methodArguments, method }, i) => (
                                  <ProfileNotificationActionButton key={i}
                                                                   index={i}
                                                                   title={title}
                                                                   method={method}
                                                                   arguments={methodArguments}/>
                              ))}
                          </ButtonGroup>
                      )}>
                {title}
            </RichCell>
        </Container>
    );
};


export { ProfileNotificationCard };
