/**
 * PlatformLogo
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { UserAuthStore } from '@/store/user/auth';
import { SchoolStore } from '@/store/platform';
import { observer, PreferenceStore } from '@/store/preference/preference';

import { If, Link, Platform } from '@/cutils';

import { IconButton } from '@mui/material';
import { Icon28PicturePlusOutline } from '@vkontakte/icons';

import { IconBiz, IconBizWhite, IconExodeIcon } from '@/images/icons';


interface Props {
    size?: number;
}


const PlatformLogo = observer((props: Props) => {

    const { size = 28 } = props;

    return (
        <>
            <Platform.Market>
                <IconExodeIcon width={size}
                               height={size}
                               className="mr-3 min-w-[28px]"
                               style={{ borderRadius: '32%', marginLeft: 17 }}/>
            </Platform.Market>

            <Platform.School>
                <If is={(!SchoolStore?.school?.iconUrl) && !!UserAuthStore.combinedPermissions.length}>
                    <Link stopPropagation pushPage={{ id: '/manage/school/settings/preferences' }}>
                        <div>
                            <IconButton>
                                <Icon28PicturePlusOutline fill="var(--text_primary)"/>
                            </IconButton>
                        </div>
                    </Link>
                </If>

                <If is={!!SchoolStore?.school?.iconUrl}>
                    <img width={size}
                         height={size}
                         alt="IconSquare"
                         className="mr-3 min-w-[28px]"
                         src={SchoolStore?.school?.iconUrl || ''}
                         style={{ borderRadius: '32%', marginLeft: 17 }}/>
                </If>
            </Platform.School>

            <Platform.BizWelcome>
                <img width={size}
                     height={size}
                     alt="IconSquare"
                     className="mr-3 min-w-[28px]"
                     style={{ borderRadius: '32%', marginLeft: 17 }}
                     src={PreferenceStore.isDark ? IconBizWhite : IconBiz}/>
            </Platform.BizWelcome>
        </>
    );
});


export { PlatformLogo };
