/**
 * CancelUploadPart component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core';

import { Icon24CancelCircleFillRed } from '@vkontakte/icons';

import { DeleteIconButton } from '@/components/Atoms/DeleteIconButton';


interface Props {
    clearAndCancelUploading: () => void;
}


const CancelUploadPart = (props: Props) => {

    const { t } = useI18n('components.Atoms.FileUpload.parts');

    return (
        <div className="z-[100] absolute right-0 m-2 rounded-full bg-content-50--blur thin-border top-0">
            <DeleteIconButton text={t('cancelLoad?')}
                              iconFill="var(--dynamic_red)"
                              callback={props.clearAndCancelUploading}
                              confirmIcon={<Icon24CancelCircleFillRed/>}/>
        </div>
    );
};


export { CancelUploadPart };
