/**
 * CommonFooterItem component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core';
import { SvgComponent } from '@/cutils';
import { courseTypeIcons } from '@/types/course';
import { CourseService } from '@/services/Course/Course';

import { Caption } from '@exode.ru/vkui';

import { CourseCardProps } from '../CourseCard';


interface Props {
    mode: CourseCardProps['mode'];
    course: CourseCardProps['course'];
}


const CommonFooterItem = (props: Props) => {

    const { mode, course: { type, lessons, product } } = props;

    const { t } = useI18n('components.Course');

    const { educationStartAt, educationFinishAt } = product.currentSaleLaunch || {};

    const educationDuration = CourseService.getDuration(
        educationStartAt,
        educationFinishAt,
    );

    return (
        <div className={[
            'flex items-center gap-2',
            mode === 'cart' ? 'mt-auto' : '',
        ].join(' ')}>
            <SvgComponent element={courseTypeIcons[type]} svgProps={{ width: 16, height: 16 }}/>

            <Caption level="3">
                {t('days', { count: educationDuration })}
            </Caption>

            <span>•</span>

            <Caption level="3">
                {t('lessons', { count: lessons.length })}
            </Caption>
        </div>
    );
};


export { CommonFooterItem };
