/**
 * CompanyMissionView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { ConfigStore } from '@/store/core/config';

import { LinkWrapperProps } from '@/cutils';

import { MiniInfoCell } from '@exode.ru/vkui';
import { Icon20DiamondOutline } from '@vkontakte/icons';

import { aboutCompanyText } from './MainContentView';


const CompanyMissionView = (props: LinkWrapperProps<any>) => {
    return (
        <MiniInfoCell {...props} className="d:py-2"
                      before={<Icon20DiamondOutline/>}
                      textWrap={ConfigStore.isDesktop ? 'full' : 'short'}>
            {aboutCompanyText}
        </MiniInfoCell>
    );
};


export { CompanyMissionView };
