/**
 * SchoolHeaderView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer, SchoolStore } from '@/store/platform';

import { StartHeader } from '@/components/Landing/StartHeader';


const SchoolHeaderView = observer(() => {
    return (
        <StartHeader alignment="center"
                     appearance="light-content"
                     titleProps={{ weight: '1' }}
                     className="d:min-h-[300px] text-center"
                     subtitle={(
                         SchoolStore.blocks?.catalog?.subtitle
                         || SchoolStore.school?.description
                     )}
                     title={(
                         <div style={{ lineHeight: '120%' }}
                              className="flex flex-col w-full d:fs-50 m:fs-30 m:text-center">
                             <span className="bg-content-15--blur vk-rounded px-2">
                                 {(
                                     SchoolStore.blocks?.catalog?.title
                                     || SchoolStore.school?.name
                                 )}
                             </span>
                         </div>
                     )}/>
    );
});


export { SchoolHeaderView };
