/**
 * SchoolMenuPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer, SchoolStore } from '@/store/platform';

import { Link, SvgComponent } from '@/cutils';
import { RoutePathType } from '@/router/paths';

import { SimpleCell } from '@exode.ru/vkui';

import { Router } from '@/services/Utils/Router';

import { DynamicVkIcon } from '@/components/Atoms/DynamicVkIcon';


const SchoolMenuPart = observer(() => {
    return (
        <>
            {SchoolStore.blocks?.leftMenu?.map(({ link, name, icon }, index) => {

                const match = Router.matchLinkPathAppRoute(link);

                return (
                    <Link key={index} blank={!match} toOuter={!match ? link : undefined} pushPage={(
                        match
                            ? { id: match.path as RoutePathType, params: match.params }
                            : undefined
                    )}>
                        <SimpleCell expandable before={(
                            <SvgComponent element={<DynamicVkIcon vkIconName={icon}/>} svgProps={{
                                width: 24,
                                height: 24,
                                className: 'text-white',
                            }}/>
                        )}>
                            {name}
                        </SimpleCell>
                    </Link>
                );
            })}
        </>
    );
});


export { SchoolMenuPart };
