/**
 * ManageSellerLogo
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { ApolloError } from '@apollo/client';

import { observer, SellerStore } from '@/store/user/seller';
import { AuthException, SellerCurrentUserSellerQuery } from '@/codegen/graphql';

import { Avatar } from '@exode.ru/vkui';
import { Icon24CancelOutline } from '@vkontakte/icons';

import { Time } from '@/utils';
import { Graphql, If } from '@/cutils';
import { Storage } from '@/api/storage';
import { getGqlCause } from '@/api/graphql';

import { useSellerContext } from '@/components/App/SellerProvider';
import { SellerAvatar } from '@/components/Seller/Avatar/SellerAvatar';

import { ManageDrawerPart } from './ManageDrawer';
import { ManageSellerLogoPlaceholder } from './ManageSellerLogoPlaceholder';


const ManageSellerLogo = observer(() => {

    const {
        seller,
        isSeller,
        isAdmin,
        hasSelectedSeller,
        refetchSelectSellerQueries,
    } = useSellerContext();

    const handleClose = () => SellerStore.toggleDrawer(false);

    const onError = async (e: ApolloError) => {
        if (getGqlCause(e) === AuthException.Forbidden) {
            Storage.destroy('seller:id');

            await Time.timer(5000);

            refetchSelectSellerQueries();
        }
    };

    return (
        <>
            <If is={SellerStore.drawer.open}>
                <div className="cursor-pointer" onClick={handleClose}>
                    <Avatar mode="app" alt="IconSquare" size={32} className="flex mt-3 mb-2 mx-auto rounded-full">
                        <Icon24CancelOutline/>
                    </Avatar>
                </div>
            </If>

            <If is={!SellerStore.drawer.open}>
                <div className="cursor-pointer select-none" onClick={() => {
                    if (isSeller && !isAdmin || hasSelectedSeller || !!seller) {
                        SellerStore.openSection('seller');
                    } else if (isAdmin) {
                        SellerStore.openSection('admin');
                    }
                }}>
                    <If is={!isSeller || !hasSelectedSeller}>
                        <ManageSellerLogoPlaceholder isAdmin={isAdmin}
                                                     isSeller={isSeller}
                                                     hasSelectedSeller={hasSelectedSeller}/>
                    </If>

                    <If is={hasSelectedSeller}>
                        <SellerCurrentUserSellerQuery onError={onError} children={(result) => (
                            <>
                                <Graphql.Loading result={result}>
                                    <ManageSellerLogoPlaceholder loading
                                                                 isAdmin={isAdmin}
                                                                 isSeller={isSeller}
                                                                 hasSelectedSeller={hasSelectedSeller}/>
                                </Graphql.Loading>

                                <Graphql.Success result={result}>
                                    {({ sellerCurrentUserSeller: seller }) => (
                                        <SellerAvatar size={32}
                                                      sellerId={seller?.id || 0}
                                                      sellerName={seller?.organization.name}
                                                      sellerOrganizationLogo={seller?.organization?.logo || ''}
                                                      className="flex mt-3 mb-2 mx-auto items-center justify-center cursor-pointer"/>
                                    )}
                                </Graphql.Success>

                                <Graphql.Error result={result}>
                                    <ManageSellerLogoPlaceholder isAdmin={isAdmin}
                                                                 isSeller={isSeller}
                                                                 hasSelectedSeller={hasSelectedSeller}/>
                                </Graphql.Error>
                            </>
                        )}/>
                    </If>
                </div>
            </If>

            <ManageDrawerPart onError={onError}
                              handleClose={handleClose}
                              shown={SellerStore.drawer.open}
                              mode={[ isAdmin && 'admin', isSeller && 'seller' ].filter(e => e).join('-') as 'admin-seller'}/>
        </>
    );
});


export { ManageSellerLogo };
