/**
 * Chat plurals
 *
 * @author: exode <hello@exode.ru>
 */

import plural from 'plural-ru';


/**
 * Склонение "сообщение"
 * @param {number} length
 * @returns {string}
 */
export const pluralMessage = (length: number) => (
    plural(length, 'сообщение', 'сообщения', 'сообщений')
);

/**
 * Склонение "письмо"
 * @param {number} length
 * @returns {string}
 */
export const pluralMail = (length: number) => (
    plural(length, 'письмо', 'письма', 'писем')
);

/**
 * Склонение "участники"
 * @param {number} length
 * @returns {string}
 */
export const pluralChatMembers = (length: number) => (
    plural(length, 'участник', 'участника', 'участников')
);
