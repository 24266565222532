/**
 * Use platform hook
 *
 * @author: exode <hello@exode.ru>
 */

import { usePlatform as vkuiUsePlatform } from '@exode.ru/vkui';


export const usePlatform = vkuiUsePlatform as () => 'ios' | 'android';
