/**
 * ConfirmationModal
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';

import * as Yup from 'yup';

import { Form, Formik, FormikProps } from 'formik';
import { useHotkeys } from 'react-hotkeys-hook';

import { useStore } from '@/pages/Core';
import { ConfirmationModalStore } from './store';

import { useI18n } from '@/hooks/core';
import { Router } from '@/services/Utils/Router';

import { Field, If, Modal } from '@/cutils';
import { Button, FormItem, Input, ModalRootContext, Text, Textarea, Title } from '@exode.ru/vkui';

import { ConfirmationProvider } from './ConfirmationContext';


interface Props {
    id: string;
}


const ConfirmationModal = (props: Props) => {

    const { t } = useI18n('modals.Confirmation');

    const { store } = useStore(ConfirmationModalStore);

    const modalContext = useContext(ModalRootContext);

    const [ hasBeenConfirmed, setHasBeenConfirmed ] = useState(false);

    const formikRef = useRef<FormikProps<typeof initialValues>>(null);

    const {
        title,
        prompt,
        subtitle,
        onConfirm,
        onCancel,
        isLoading,
        children,
        buttons = {},
    } = store.confirmModalProps || {};

    const initialValues = {
        prompt: '',
    };

    const confirmationSchema = Yup.object().shape({
        prompt: prompt?.required
            ? Yup.string().min(prompt.minLength || 0).required()
            : Yup.string().notRequired(),
    });

    const handleConfirm = () => {
        const { prompt = '' } = formikRef.current?.values || {};

        if (!formikRef.current?.isValid || isLoading) {
            return;
        }

        onConfirm?.({ prompt });

        !_.isBoolean(isLoading)
            ? Router.replaceModal()
            : setHasBeenConfirmed(true);
    };

    const handleModalClose = () => {
        onCancel?.();
        modalContext.onClose?.();
    };

    useEffect(() => {
        if (!hasBeenConfirmed) {
            return;
        }

        !isLoading && Router.replaceModal();
    }, [ hasBeenConfirmed ]);

    useLayoutEffect(() => {
        if (!_.isFunction(onConfirm)) {
            Router.replaceModalWithTimeout().then();
        }

        return () => {
            store.resetConfirm();
        };
    }, []);

    useHotkeys('enter', () => {
        handleConfirm();
    });

    return (
        <Modal.Card id={props.id} onClose={handleModalClose} header={(
            <Title className="modal-title" level="2" weight="3">
                {title}
            </Title>
        )}>
            <Modal.Body className="d:p-1">
                <Text className={[
                    'text-center text-muted mt-3',
                    prompt ? 'mb-3' : ' mb-1.5',
                ].join(' ')}>
                    {subtitle}
                </Text>

                <Formik validateOnMount
                        validateOnChange
                        enableReinitialize
                        innerRef={formikRef}
                        onSubmit={handleConfirm}
                        initialValues={initialValues}
                        validationSchema={confirmationSchema}>
                    {({ handleSubmit, handleChange, values, isValid }) => (
                        <ConfirmationProvider value={{ formikRef }}>
                            <Form onSubmit={handleSubmit}>
                                <If is={!!prompt}>
                                    <FormItem className="mt-1 mb-1 px-0" bottom={(
                                        <div className="flex justify-between items-center">
                                            {[
                                                prompt?.required ? t('requiredField') : '',
                                                prompt?.minLength ? t('minSymbols', { symbols: prompt?.minLength }) : '',
                                            ].filter(e => e).join(', ')}

                                            <If is={!!prompt?.maxLength}>
                                                <span className="flex justify-end">
                                                    {values.prompt?.length} / {prompt?.maxLength}
                                                </span>
                                            </If>
                                        </div>
                                    )}>
                                        <If is={prompt?.mode === 'input'}>
                                            <Input autoFocus
                                                   name="prompt"
                                                   value={values.prompt}
                                                   data-test="prompt.input"
                                                   maxLength={prompt?.maxLength}
                                                   placeholder={prompt?.placeholder}
                                                   onChange={Field.transform.ignoreSpaceOnChange(handleChange)}/>
                                        </If>

                                        <If is={prompt?.mode !== 'input'}>
                                            <Textarea autoFocus
                                                      rows={3}
                                                      name="prompt"
                                                      value={values.prompt}
                                                      data-test="prompt.textarea"
                                                      placeholder={prompt?.placeholder}
                                                      onChange={Field.transform.ignoreSpaceOnChange(handleChange)}/>
                                        </If>
                                    </FormItem>
                                </If>

                                <>
                                    {children}
                                </>

                                <If is={!buttons.cancel?.hidden || !buttons.confirm?.hidden}>
                                    <div className="vkuiModalCardBase__actions ModalCardBase__actions">
                                        <div className="flex w-full d:p-1">
                                            <If is={!buttons.cancel?.hidden}>
                                                <Button size="l"
                                                        mode="secondary"
                                                        data-test="confirmation.cancel-button"
                                                        onClick={() => Router.replaceModal()}
                                                        {...buttons.cancel?.props}>
                                                    {buttons.cancel?.text || t('cancel')}
                                                </Button>
                                            </If>

                                            <If is={!buttons.confirm?.hidden}>
                                                <Button size="l"
                                                        mode="primary"
                                                        loading={isLoading}
                                                        onClick={handleConfirm}
                                                        disabled={!isValid || isLoading}
                                                        data-test="confirmation.confirm-button"
                                                        {...buttons.confirm?.props}>
                                                    {buttons.confirm?.text || t('ready')}
                                                </Button>
                                            </If>
                                        </div>
                                    </div>
                                </If>
                            </Form>
                        </ConfirmationProvider>
                    )}
                </Formik>
            </Modal.Body>
        </Modal.Card>
    );
};


export { ConfirmationModal };
