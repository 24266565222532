/**
 * PreviewDocPart component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { IconButton } from '@mui/material';

import { Icon16AddCircleFillRed } from '@vkontakte/icons';

import { FileRow } from '@/components/Atoms/FileRow';
import { FileUploadProps } from '@/components/Atoms/FileUpload';


interface Props extends FileUploadProps {
    clearAndCancelUploading: () => void;
    previewDoc: FileUploadProps['previewDoc'];
}


const PreviewDocPart = (props: Props) => {

    const { previewDoc, clearAndCancelUploading } = props;

    return (
        <FileRow location={previewDoc?.src || ''}
                 className="z-10 bg-button-secondary"
                 name={previewDoc?.fileRowProps?.name || ''}
                 mimeType={previewDoc?.fileRowProps?.mimeType || ''}
                 after={(
                     <IconButton onClick={(e) => {
                         e.stopPropagation();
                         clearAndCancelUploading();
                     }}>
                         <Icon16AddCircleFillRed fill="var(--accent)"/>
                     </IconButton>
                 )}/>
    );
};


export { PreviewDocPart };
