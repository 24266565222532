/**
 * Language types
 *
 * @author: exode <hello@exode.ru>
 */

export enum Language {
    Ru = 'Ru',
    Uz = 'Uz',
}

export const languageNames: Record<Language, string> = {
    Ru: 'Русский',
    Uz: 'O\'zbekcha',
};


export type TranslateJson = { [key in Language]?: string }
