/**
 * AvatarBlackout component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Avatar as VkUiAvatar, AvatarProps } from '@exode.ru/vkui';

import { Wrapper } from './AvatarBlackout.styled';


export interface AvatarBlackoutProps extends AvatarProps {
    blackout?: {
        background?: string
        borderRadius?: string
    };
}


const AvatarBlackout = (props: AvatarBlackoutProps) => {

    const { src, ...rest } = props;

    return (
        <VkUiAvatar src={src} {...rest}>
            <div className="relative w-full h-full">
                <Wrapper {...props} className={[
                    props.className,
                    'absolute w-[calc(100%_-_8px)] h-[calc(100%_-_8px)] top-[4px] left-[4px]',
                ].join(' ')}/>
            </div>
        </VkUiAvatar>
    );
};


export { AvatarBlackout };
