/**
 * CheckUserDomainItem
 *
 * @author: exode <hello@exode.ru>
 */

import { DebounceInput } from 'react-debounce-input';

import React, { ChangeEventHandler, Dispatch, ReactElement, SetStateAction, useLayoutEffect } from 'react';

import { useCheckUserDomainQuery } from '@/codegen/graphql';

import { useI18n } from '@/hooks/core';
import { DebouncedInput } from '@/types/html';

import { Input } from '@exode.ru/vkui';


interface Props {
    domain: string;
    domainAfter: ReactElement;
    setFieldError: Function;
    handleChange: ChangeEventHandler<HTMLInputElement>;
    setDomainState: Dispatch<SetStateAction<{ success: boolean; loading: boolean; cause: string; }>>;
    isSkeleton?: boolean;
}


const CheckUserDomainItem = (props: Props) => {

    const {
        domainAfter,
        domain,
        handleChange,
        setFieldError,
        setDomainState,
        isSkeleton,
    } = props;

    const { t } = useI18n('pages.User.Settings');

    const { data, loading } = useCheckUserDomainQuery({
        variables: { domain },
        skip: isSkeleton || !domain.length,
    });

    useLayoutEffect(() => {
        if (data?.userSettingsCheckDomain.cause) {
            setFieldError('domain', (data?.userSettingsCheckDomain.message ?? ''));
        }

        setDomainState({
            loading,
            success: !!data?.userSettingsCheckDomain.success,
            cause: data?.userSettingsCheckDomain.message ?? '',
        });
    }, [ loading, data ]);

    return (
        <DebounceInput element={Input as DebouncedInput}
                       type="text"
                       name="domain"
                       maxLength={15}
                       autoFocus={false}
                       after={domainAfter}
                       value={domain || ''}
                       debounceTimeout={500}
                       onChange={handleChange}
                       placeholder={t('userDomain')}/>
    );
};


export { CheckUserDomainItem };
