/**
 * StatusSubViewWrapper styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


const StatusSubViewWrapper = styled.div`
    .Banner__in {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        margin-bottom: 6px;
    }
`;


export { StatusSubViewWrapper };
