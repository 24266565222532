/**
 * StoryModal
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { useEffect, useLayoutEffect, useState } from 'react';

import Stories from 'react-insta-stories';

import { useLocation } from '@/router/index';

import YouTube, { YouTubeProps } from 'react-youtube';

import { IS_NATIVE, IS_VK } from '@/root/src/env';

import { Time, Url } from '@/utils';
import { usePlatform } from '@/hooks/core';
import { Router } from '@/services/Utils/Router';
import { If, Modal, Responsive } from '@/cutils';

import { Icon24Dismiss, Icon28ChevronRightOutline } from '@vkontakte/icons';

import { Page, useStore } from '@/pages/Core';
import { UserAuthStore } from '@/store/user/auth';
import { ConfigStore, observer } from '@/store/core/config';
import { ProfilePageStore } from '@/pages/Profile/Profile/store';

import { StoriesStatus, StoriesType, useStoriesFindManyQuery, useStoriesUpsertViewMutation } from '@/codegen/graphql';

import { IconButton } from '@/components/Atoms/IconButton';
import { storiesList } from '@/tabs/Education/views/StoriesView';

import styles from './StoryModal.module.scss';
import { Container } from './StoryModal.styled';


interface Props {
    id: string;
}


const StoryModal = observer((props: Props) => {

    const platform = usePlatform();

    const { route: { pageId, params: { storyIndex } } } = useLocation();

    const { store } = useStore(ProfilePageStore);

    const [ storiesIndex, setStoriesIndex ] = useState(+storyIndex);

    const [ _viewStory ] = useStoriesUpsertViewMutation({
        onError: error => console.error(error),
    });

    const { data, loading } = useStoriesFindManyQuery({
        onError: error => console.error(error),
        variables: {
            ...storiesList,
            filter: pageId === '/@:userId([0-9_A-Za-z]+)'
                ? { sellerIds: [ store.sellerId ?? 0 ], statuses: [ StoriesStatus.Published ] }
                : { statuses: [ StoriesStatus.Published ] },
        },
    });

    const stories = data?.storiesFindMany.items?.map((stories) => {
        const { id, url, duration, type, createdAt, seller, user, title, hasView } = stories;

        const baseStory = {
            id,
            url,
            hasView,
            duration: duration * 1000,
            header: {
                heading: (user.profile.fullName) + ' — ' + title,
                subheading: Time.parseRelative(createdAt).toUpperCase(),
                profileImage: seller?.organization?.logo || user.profile.avatar.medium || '',
            },
            storyContent: {
                height: '100%',
                objectFit: 'contain',
            },
        };

        if (type === StoriesType.Video) {
            return Url.videoUrlIsThirdParty(url)
                ? {
                    ...baseStory,
                    content: ({ action }: any) => {

                        useEffect(() => {
                            setTimeout(() => action('pause'), 0);
                        }, []);

                        return (
                            <YouTube opts={youTubeProps}
                                     iframeClassName=""
                                     onPause={() => action('pause')}
                                     onPlay={() => action('play')}
                                     className="w-full self-end z-[1001]"
                                     videoId={Url.parseVideoUrl(url)?.id}
                                     style={{ height: 'calc(100% - 12px)' }}
                                     onReady={(event: any) => event.target.playVideo()}
                                     onStateChange={(event: any) => event.target.playerInfo.playerState === 3 && action('pause')}/>
                        );
                    },
                }
                : { ...baseStory, type: 'video' };
        }

        return baseStory;
    });

    const viewStory = async () => {
        const currentStory = data?.storiesFindMany?.items?.[storiesIndex];

        if (!currentStory?.hasView) {
            await _viewStory({ variables: { storiesId: currentStory?.id ?? 0 } });
        }
    };

    const handleSwitch = async (direction: 'next' | 'prev') => {
        if (direction === 'next') {
            storiesIndex === (stories!.length - 1)
                ? Router.replacePopout()
                : (() => {
                    Router.updateParams({ storyIndex: `${+storyIndex + 1}` });
                    setStoriesIndex(prev => prev + 1);
                })();

        } else if (direction === 'prev' && storiesIndex !== 0) {
            Router.updateParams({ storyIndex: `${+storyIndex - 1}` });
            setStoriesIndex(prev => prev - 1);
        } else if (direction === 'prev' && storiesIndex === 0) {
            Router.replacePopout();
        }
    };

    const youTubeProps: YouTubeProps['opts'] = {
        height: '100%',
        width: '100%',
        position: 'absolute',
        playerVars: {
            autoplay: 1,
            controls: 0,
            disablekb: 1,
        },
    };

    useLayoutEffect(() => {
        data && UserAuthStore.isLoggedIn && viewStory().then();
    }, [ storiesIndex, data ]);

    return (
        <Modal.Card className={styles.storyModal} id={props.id}>
            <Modal.Body className="flex items-center d:max-h-[1000px]">
                <If is={!data && loading}>
                    <Page.Spinner className="m-auto m:w-[100vw] m:h-[calc(var(--app-height))] d:h-[calc(100vh_-_70px)]"/>
                </If>

                <If is={!!stories && !_.isEmpty(stories) && !loading}>
                    <If is={!ConfigStore.isMobileOnly}>
                        <IconButton style={{ right: '-135px' }}
                                    className="!absolute !m:hidden opacity-50 hover:opacity-70"
                                    onClick={() => handleSwitch('next')}>
                            <Icon28ChevronRightOutline fill="black" style={{ marginLeft: 2 }}/>
                        </IconButton>

                        <IconButton className="!absolute !m:hidden opacity-50 hover:opacity-70"
                                    onClick={() => handleSwitch('prev')}
                                    style={{ left: '-135px', transform: 'rotate(180deg)' }}>
                            <Icon28ChevronRightOutline fill="black" style={{ marginLeft: 2 }}/>
                        </IconButton>
                    </If>

                    <Container>
                        <Stories keyboardNavigation
                                 stories={stories || []}
                                 currentIndex={storiesIndex}
                                 onAllStoriesEnd={() => Router.replacePopout()}
                                 width={ConfigStore.isMobileOnly ? '100vw' : 440}
                                 onStoryStart={(i: number) => i !== storiesIndex && setStoriesIndex(i)}
                                 height={(
                                     ConfigStore.isMobileOnly
                                         ? `calc(100vh - env(safe-area-inset-bottom, 0px) - ${!IS_NATIVE && platform === 'ios' ? '25px' : '0px'})`
                                         : 'calc(100vh - 70px)'
                                 )}
                                 storyContainerStyles={{
                                     background: 'black',
                                     overflow: 'hidden',
                                     borderRadius: !ConfigStore.isMobileOnly || IS_NATIVE ? 12 : 0,
                                     maxHeight: ConfigStore.isMobileOnly ? 'calc(var(--app-height))' : '1000px',
                                 }}/>
                    </Container>

                    <Responsive.Tablet>
                        <div onClick={() => Router.replaceModal()} className={[
                            '!absolute !z-[1002] bg-content rounded-full p-2 m-2',
                            IS_VK ? '!top-[45px] !right-[10px]' : '!top-[12px] !right-[10px]',
                        ].join(' ')}>
                            <Icon24Dismiss fill="var(--acent)" width={28} height={28}/>
                        </div>
                    </Responsive.Tablet>
                </If>
            </Modal.Body>
        </Modal.Card>
    );
});


export { StoryModal };
