/**
 * ModalBody component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';


interface Props {
    children: ReactElement | ReactElement[];
    isScrollable?: boolean;
    className?: string;
    fullHeight?: boolean;
}


const ModalBody = (props: Props) => {

    const { children, className, isScrollable = false, fullHeight = false } = props;

    return (
        <div className={[
            className,
            isScrollable ? 'modal-body-scrollable mt-3' : '',
            fullHeight ? 'h-[100vh]' : '',
        ].join(' ')}>
            {children}
        </div>
    );
};


export { ModalBody };
