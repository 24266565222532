/**
 * SellerBecomeRequestInput
 *
 * @author: exode <hello@exode.ru>
 */

import * as yup from 'yup';

import { SellerBecomeRequestDocType, SellerOrganizationForm, SellerType } from '@/codegen/graphql';

import { checkIsOrganization } from '@/types/seller';

import { isMinMax, isNotEmpty } from '../constants';


export const SellerBecomeRequestInputSchema = yup.object().shape({
    type: yup.string()
        .oneOf(Object.values(SellerType)),

    organizationForm: yup.string()
        .oneOf(Object.values(SellerOrganizationForm)),

    bankAccountNumber: yup.string()
        .required(isNotEmpty('Расчетный счет'))
        .min(1, isMinMax(1, 'Расчетный счет', 'min')),

    bic: yup.string()
        .required(isNotEmpty('БИК'))
        .matches(/^\d{9}$/, isMinMax(9, 'БИК', 'min')),

    inn: yup.string()
        .required(isNotEmpty('ИНН'))
        .matches(/^\d{10,12}$/, isMinMax(10, 'ИНН', 'min')),

    firstName: yup.string()
        .when('organizationForm', {
            is: (organizationForm: SellerOrganizationForm) => !checkIsOrganization(organizationForm),
            then: () => yup.string().required(isNotEmpty('Имя')),
            otherwise: () => yup.string().nullable(),
        }),

    lastName: yup.string()
        .when('organizationForm', {
            is: (organizationForm: SellerOrganizationForm) => !checkIsOrganization(organizationForm),
            then: () => yup.string().required(isNotEmpty('Фамилия', 'пустой')),
            otherwise: () => yup.string().nullable(),
        }),

    surname: yup.string()
        .when('organizationForm', {
            is: (organizationForm: SellerOrganizationForm) => !checkIsOrganization(organizationForm),
            then: () => yup.string().required(isNotEmpty('Отчество', 'пустым')),
            otherwise: () => yup.string().nullable(),
        }),

    passport: yup.object()
        .when('organizationForm', {
            is: (organizationForm: SellerOrganizationForm) => !checkIsOrganization(organizationForm),
            then: () => yup.object().shape({
                registration: yup.string()
                    .required(isNotEmpty('Адрес')),

                issuedBy: yup.string()
                    .required(isNotEmpty('Название подразделения')),

                dateOfIssue: yup.date()
                    .required(isNotEmpty('Дата выдачи', 'пустой')),

                issuedByCode: yup.string()
                    .required(isNotEmpty('Код'))
                    .min(6, isMinMax(6, 'Код', 'min')),

                number: yup.string()
                    .required(isNotEmpty('Номер'))
                    .min(6, isMinMax(6, 'Номер', 'min')),

                series: yup.string()
                    .required(isNotEmpty('Серия', 'пустой'))
                    .min(4, isMinMax(4, 'Серия', 'min', 'должна')),

                pages: yup.array()
                    .max(2)
                    .min(2, 'Необходимо загрузить обе страницы паспорта')
                    .of(yup.object().shape({
                        url: yup.string().required('Фото паспорта обязательны'),
                        page: yup.string().oneOf(Object.values(SellerBecomeRequestDocType)),
                    })),
            }),
        }),
});
