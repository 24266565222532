/**
 * RbacForbiddenPage
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Page } from '@/pages/Core';
import { useI18n } from '@/hooks/core';

import { Permission } from '@/codegen/graphql';

import { ContextView } from './views/ContextView';
import { ForbiddenContentView } from './views/ForbiddenContentView';


interface Props {
    required: Permission[];
}


const RbacForbiddenPage = (props: Props) => {

    const { t } = useI18n('pages.Rbac.Forbidden');

    return (
        <Page.Wrapper>
            <Page.Head>
                <Page.Header title={t('accessDenied')}/>
            </Page.Head>

            <Page.Content>
                <Page.Row fullHeight>
                    <ForbiddenContentView required={props.required}/>
                </Page.Row>
            </Page.Content>

            <Page.Context>
                <ContextView/>
            </Page.Context>
        </Page.Wrapper>
    );
};


export { RbacForbiddenPage };
