/**
 * Use rnd video
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useEffect } from 'react';

import { PortalStore } from '@/store/core/portal';


/**
 * Set rnd video to store
 * @param {string} link
 * @returns {{provider: any, src: any}[] | {src: string}[]}
 */
export const useSetRndVideo = (link?: string | null) => {

    useEffect(() => {
        PortalStore.setRndVideoSource(link || null);
    }, [ link ]);

};
