/**
 * Template types
 *
 * @author: exode <hello@exode.ru>
 */

export enum MailTemplate {
    AdminUserDeletionRequest = 'mails/AdminUserDeletionRequest',
    Dynamic = 'mails/Dynamic',
    UserOnBoarding = 'mails/UserOnBoarding',
    UserRecoverPassword = 'mails/UserRecoverPassword',
    UserLoginVerification = 'mails/UserLoginVerification',
    UserPaymentReleased = 'mails/UserPaymentReleased',
    UserPaymentRefunded = 'mails/UserPaymentRefunded',

    SellerBecomeRequestFormDataChecking = 'mails/SellerBecomeRequestFormDataChecking',
    SellerBecomeRequestFormDataDeclined = 'mails/SellerBecomeRequestFormDataDeclined',
    SellerBecomeRequestOnAgreementSigning = 'mails/SellerBecomeRequestOnAgreementSigning',
    SellerBecomeRequestAgreementChecking = 'mails/SellerBecomeRequestAgreementChecking',
    SellerBecomeRequestAgreementDataDeclined = 'mails/SellerBecomeRequestAgreementDataDeclined',
    SellerBecomeRequestVerified = 'mails/SellerBecomeRequestVerified',
}

export enum DocTemplate {
    SellerAgreement = 'docs/SellerAgreement',
}
