/**
 * TaskSimpleCell component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { GqlResult } from '@/types/graphql';
import { taskContentType } from '@/types/task';
import { educationSubjects } from '@/types/subject';

import { TaskFindManyQueryResult } from '@/codegen/graphql';
import { Caption, Checkbox, SimpleCell } from '@exode.ru/vkui';

import { EditorJsOutput } from '@/components/Atoms/EditorJsOutput';

import { Container } from './TaskSimpleCell.styled';


interface Props {
    task: NonNullable<GqlResult<TaskFindManyQueryResult>['taskFindMany']['items']>[number];
    onClick: () => void;
}


const TaskSimpleCell = (props: Props) => {

    const { task, onClick } = props;

    const taskContentTypes = taskContentType();

    return (
        <Container className="flex items-center pl-2 vk-rounded cursor-pointer">
            <div>
                <Checkbox className="w-[30px] h-[30px]" onChange={() => {}}/>
            </div>

            <SimpleCell onClick={onClick} subtitle={(
                <Caption level="1"
                         weight="regular"
                         className="mt-0.5 text-ellipsis text-slight-gray overflow-hidden max-w-[80%]">
                    {task.contentTypes?.map((type) => (<>{taskContentTypes[type]}{' • '}</>))}

                    {educationSubjects[task.subject]}
                </Caption>
            )}>
                <EditorJsOutput data={task.question?.title}/>
            </SimpleCell>
        </Container>
    );
};


export { TaskSimpleCell };
