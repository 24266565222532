/**
 * FeedbackRateAndParticipantCount
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { CourseType } from '@/codegen/graphql';

import { If } from '@/cutils';
import { CourseFindManyItem } from '@/types/course';

import { Caption } from '@exode.ru/vkui';
import { StarRate } from '@mui/icons-material';
import { Icon16User, Icon16Videocam } from '@vkontakte/icons';


interface Props {
    rate?: number | null;
    participants?: number | null;
    course?: CourseFindManyItem;
    className?: string;
}


const FeedbackRateAndParticipantCount = (props: Props) => {

    const { rate, participants, course, className } = props;

    return (
        <div className={[
            className || 'mt-[3px]',
            'flex items-start text-slight-gray gap-1 pl-[3px]',
        ].join(' ')}>
            <StarRate sx={{ width: 16, height: 16 }} className="dark:text-yellow-500 text-orange-600"/>
            <Caption level="1" weight="regular" data-tast="course.rate" data-value={rate}>
                {rate || '—'}
            </Caption>

            <Icon16User className="ml-1 mt-[-1px] text-accent" width={18} height={18}/>
            <Caption level="1" weight="regular" data-test="course.participants" data-value={participants}>
                {participants || '—'}
            </Caption>

            <If is={course?.type === CourseType.Webinar}>
                <Icon16Videocam width={18}
                                height={18}
                                fill="var(--dynamic_green)"
                                className="ml-1 mt-[-1px] text-accent"/>
                <Caption level="1" weight="regular">{'—'}</Caption>
            </If>
        </div>
    );
};


export { FeedbackRateAndParticipantCount };
