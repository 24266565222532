/**
 * DynamicForm
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { useLayoutEffect, useState } from 'react';

import { ConfigStore, observer } from '@/store/core/config';

import { buildYup } from 'schema-to-yup';

import { Form, Formik } from 'formik';

import { ObjectUtil } from '@/utils';
import { useLocation } from '@/router/index';
import { getTranslated } from '@/hooks/core';

import { Button } from '@exode.ru/vkui';
import { TextTooltip } from '@exode.ru/vkui/dist/unstable';

import { StickyButton } from '@/components/Atoms/StickyButton';
import { CheckListTooltip } from '@/components/Atoms/CheckListTooltip';

import { FieldPart } from './parts/FieldPart';


export interface DynamicFormProps {
    onSubmit: (values: any) => void;
    savedValues: Record<any, any>;
    loading?: boolean;
    validation?: Record<any, any>;

    fields: Array<{
        type: 'input'
            | 'textarea'
            | 'select'
            | 'checkbox'
            | 'banner'
            | 'copy-input'
            | 'custom-html';
        name: string;
        top?: string;
        placeholder?: string;
        defaultValue?: string;
        title?: string;
        hidden?: boolean;
        description?: string;
        rows?: number;
        maxLength?: number;
        hideIf?: { withReceipt: boolean[] };
        options?: { label: string, value: string }[];
        props?: Record<string, any>;
    }>;

    button: {
        text: string;
        savedText?: string;
    };
}


const DynamicForm = observer((props: DynamicFormProps) => {

    const { fields, onSubmit, loading, savedValues, button } = props;

    const { route: { params } } = useLocation();

    const validation = _.cloneDeep(props.validation);

    const yupSchema = validation
        ? buildYup(validation?.schema, getTranslated(validation?.config))
        : undefined;

    const [ initialValues, setInitialValues ] = useState<Record<string, string>>({});

    useLayoutEffect(() => {
        const newInitialValues: Record<string, string> = {};

        for (const field of fields || []) {
            const { name, defaultValue } = field;

            newInitialValues[name] = _.isNil(savedValues?.[name])
                ? defaultValue
                : savedValues[name];
        }

        setInitialValues(newInitialValues);
    }, [ fields, savedValues ]);

    return (
        <Formik validateOnBlur
                validateOnMount
                validateOnChange
                enableReinitialize
                onSubmit={onSubmit}
                key={ConfigStore.language}
                validationSchema={yupSchema}
                initialValues={initialValues}>
            {(formik) => {
                const {
                    values,
                    isValid,
                    errors,
                    handleSubmit,
                } = formik;

                return (
                    <Form onSubmit={handleSubmit}>
                        {fields?.map((field, index) => (
                            <FieldPart key={index} index={index} field={field} config={formik}/>
                        ))}

                        <StickyButton className="px-0 pb-3 mt-6 z-[2]">
                            <TextTooltip placement="left" shown={!isValid && !!params.modal} hidden={isValid} text={(
                                <CheckListTooltip list={_.values(errors).map((text) => ({
                                    text: `${text}`,
                                    isSuccess: false,
                                }))}/>
                            )}>
                                <Button stretched size="l" type="submit" mode="commerce" loading={loading} disabled={(
                                    !isValid
                                    || loading
                                    || ObjectUtil.isEqual(initialValues, values)
                                )}>
                                    {ObjectUtil.isEqual(initialValues, values) && button.savedText || button.text}
                                </Button>
                            </TextTooltip>
                        </StickyButton>
                    </Form>
                );
            }}
        </Formik>
    );
});


export { DynamicForm };
