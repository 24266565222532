/**
 * ModalCard component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useEffect } from 'react';

import { PageStore } from '@/store/core/page';
import { ModalCard as VkUiModalCard, ModalCardProps as VkUiModalCardProps } from '@exode.ru/vkui';

import styles from './ModalCard.module.scss';
import { Container } from './ModalCard.styled';


export interface ModalCardProps extends VkUiModalCardProps {
    minHeight?: string;
    justifyContent?: string;
    padding?: string;
    disableManualClose?: boolean;
}


const ModalCard = (props: ModalCardProps) => {

    const { disableManualClose, className } = props;

    useEffect(() => {
        if (disableManualClose) {
            PageStore.setIgnoreCloseModalCard(true);
        }

        return () => {
            if (disableManualClose) {
                PageStore.setIgnoreCloseModalCard(false);
            }
        };
    }, [ disableManualClose ]);

    return (
        <Container {...props} className={[
            className,
            disableManualClose ? styles.disableManualClose : '',
        ].join(' ')}>
            <VkUiModalCard {...props}/>
        </Container>
    );
};


export { ModalCard };
