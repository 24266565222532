/**
 * UseProductFaq
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import {
    ProductFaqFindManyDocument,
    ProductFaqFindManyQuery,
    ProductFaqFindManyQueryResult,
    SortDirection,
    useProductFaqCreateMutation,
    useProductFaqUpdateMutation,
    useProductFaqUpdateOrderMutation,
} from '@/codegen/graphql';

import { useI18n } from '@/hooks/core';

import { Notify } from '@/cutils';
import { ArrayUtil } from '@/utils';
import { GqlResult } from '@/types/graphql';
import { apolloClient } from '@/api/graphql';
import { useLocation } from '@/router/index';
import { DraggingIndexes } from '@/types/common';
import { Router } from '@/services/Utils/Router';


export const useProductFaq = (
    productId: number,
    courseId: number,
) => {
    const { t } = useI18n('hooks.apollo.product');

    const { route: { params } } = useLocation();

    const variables = {
        list: { skip: 0 },
        filter: { productIds: [ productId ] },
        sort: { order: SortDirection.Asc },
    };

    const [ _createFaq, {
        loading: createFaqLoading,
        error: createFaqError,
    } ] = useProductFaqCreateMutation({
        onError: error => console.error(error),
        update: (cache, { data }) => {
            const cachedFaqs = cache.readQuery<ProductFaqFindManyQuery>({
                variables,
                query: ProductFaqFindManyDocument,
            });

            if (!cachedFaqs) {
                return console.warn('[Cache]: cachedFaqs отсутствуют в кэше');
            }

            const { items, count } = cachedFaqs.productFaqFindMany;

            data?.productFaqCreate && cache.writeQuery<ProductFaqFindManyQuery>({
                variables,
                query: ProductFaqFindManyDocument,
                data: {
                    productFaqFindMany: {
                        count: count + 1,
                        items: [ ...(items || []), data.productFaqCreate ],
                    },
                },
            });
        },
        onCompleted: ({ productFaqCreate: { id } }) => {
            Router.pushPage(
                '/manage/course/:courseId([0-9]+)/faq/:faqId([0-9]+)',
                {
                    courseId: `${courseId}`,
                    faqId: `${id}`,
                    ..._.pick(params, [
                        'launchId',
                        'backToCheckPublication',
                    ]),
                },
            );
        },
    });

    const createFaq = (productId: number) => _createFaq({
        variables: {
            productId,
            faq: {
                question: t('newQuestion'),
                answer: {},
                active: false,
                isOpenedInUi: false,
            },
        },
    });

    const [ updateFaq, {
        loading: updateFaqLoading,
        error: updateFaqError,
    } ] = useProductFaqUpdateMutation({
        onError: error => console.error(error),
    });

    const [ reorderFaq, {
        loading: reorderFaqListLoading,
        error: reorderFaqListError,
    } ] = useProductFaqUpdateOrderMutation({
        onError(error) {
            console.log(error);

            Notify.vkui({
                appearance: 'error',
                message: t('errorSomethingWentWrong'),
            });
        },
    });

    const reorderFaqList = async (
        indexes: DraggingIndexes,
        list: GqlResult<ProductFaqFindManyQueryResult>['productFaqFindMany']['items'],
    ) => {
        if (!list || _.isEmpty(list)) {
            return;
        }

        const newOrder = ArrayUtil.reorderList(indexes, list);

        const cachedFaqs = apolloClient?.cache.readQuery<ProductFaqFindManyQuery>({
            query: ProductFaqFindManyDocument,
            variables,
        });

        cachedFaqs && apolloClient?.cache.writeQuery<ProductFaqFindManyQuery>({
            query: ProductFaqFindManyDocument,
            variables,
            data: {
                productFaqFindMany: {
                    items: newOrder,
                    count: cachedFaqs.productFaqFindMany.count,
                },
            },
        });

        await reorderFaq({
            variables: {
                productId,
                faqIds: newOrder.map(({ id }) => id),
            },
        });
    };

    return {
        createFaq,
        createFaqError,
        createFaqLoading,
        updateFaq,
        updateFaqError,
        updateFaqLoading,
        reorderFaqList,
        reorderFaqListError,
        reorderFaqListLoading,
        variables,
    };
};
