/**
 * DesktopNavigationScroll styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


export const Container = styled.div`
  position: fixed;
  top: var(--panelheader_height);
  left: 0;
  padding-top: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: var(--panelheader_height);
  overflow: hidden;

  background-color: transparent;
  cursor: pointer;
  opacity: 0.5;

  &:hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, .05);

    body[scheme=space_gray] & {
      background-color: rgba(255, 255, 255, .05);
    }
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--accent);
    margin: 1rem;
  }
`;
