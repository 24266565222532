/**
 * Use config
 *
 * @author: exode <hello@exode.ru>
 */

import { useContext } from 'react';

import { OS_PLATFORM } from '@/root/src/env';

import { ConfigContext, UseConfigContext } from '@/components/App/ConfigProvider';


export const useConfigContext = (): UseConfigContext => useContext(ConfigContext);

export const usePlatformDigitalIsOnSale = () => {

    const { state: { digitalIsOnSale } } = useConfigContext();

    return { digitalIsOnSale: digitalIsOnSale[OS_PLATFORM] };
};

