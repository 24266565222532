/**
 * ProductPriceInput
 *
 * @author: exode <hello@exode.ru>
 */

import 'reflect-metadata';

import { IsEnum, IsInt, IsNotEmpty, IsOptional, IsString, ValidateIf } from 'class-validator';

import { ProductPriceLaunchPeriodMode } from '@/codegen/graphql';

import { DateCompare, IsBiggerThanZero, IsGreaterThan, IsOnlyDate, IsSmallerThan } from '@/shared/validators';


export class ProductPriceInput {

    @IsString()
    @IsNotEmpty({
        message: 'Название тарифа не может быть пустым',
    })
    readonly title: string;

    @IsString()
    @IsNotEmpty({
        message: 'Описание тарифа не может быть пустым',
    })
    readonly description: string;

    @IsOptional()
    readonly tags: string[];

    @IsSmallerThan(
        'previousAmount',
        true,
        { message: 'Значение должно быть меньше цены без скидки' },
    )
    @IsBiggerThanZero({ message: 'Значение должно быть больше 0' })
    readonly amount: string;

    @IsOptional()
    @IsGreaterThan(
        'amount',
        true,
        { message: 'Значение должно быть больше чем цена со скидкой' },
    )
    @IsBiggerThanZero({ message: 'Значение должно быть больше 0' }, true)
    readonly previousAmount?: string;

    @IsOptional()
    @IsEnum(ProductPriceLaunchPeriodMode)
    readonly launchPeriodMode?: ProductPriceLaunchPeriodMode;

    @ValidateIf((o) => (
        o.launchPeriodMode === ProductPriceLaunchPeriodMode.SelfDefinition
    ))
    @DateCompare(
        'isSameOrBefore',
        'activeTo',
        {
            isOptional: (o) => (
                o.launchPeriodMode !== ProductPriceLaunchPeriodMode.SelfDefinition
            ),
        },
        { message: 'Дата не может быть больше окончания' },
    )
    @IsOnlyDate({
        message: 'Поле не может быть пустым',
    })
    readonly activeFrom?: Date;

    @ValidateIf((o) => (
        o.launchPeriodMode === ProductPriceLaunchPeriodMode.SelfDefinition
    ))
    @DateCompare(
        'isSameOrAfter',
        'activeFrom',
        {
            isOptional: (o) => (
                o.launchPeriodMode !== ProductPriceLaunchPeriodMode.SelfDefinition
            ),
        },
        { message: 'Дата не может быть меньше начала' },
    )
    @IsOnlyDate({
        message: 'Поле не может быть пустым',
    })
    readonly activeTo?: Date;

    @ValidateIf((o) => (
        o.launchPeriodMode === ProductPriceLaunchPeriodMode.SelfDefinition
    ))
    @IsInt({ message: 'Количество дней доступа обязательно' })
    readonly accessDays?: number;

}
