/**
 * LocalStorageSubscription
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useEffect } from 'react';

import { ShopStore } from '@/store/user/shop';
import { observer, UserAuthStore } from '@/store/user/auth';
import { PreferenceStore } from '@/store/preference/preference';

import { Storage } from '@/api/storage';
import { LocalStorageKeyType } from '@/types/config';

import { Notify } from '@/cutils';
import { ObjectUtil, Time } from '@/utils';
import { DocumentEvent } from '@/types/window';
import { ConfigStore } from '@/store/core/config';


const LocalStorageSubscription = observer(() => {

    const { repackValue } = ObjectUtil.packTools();

    const handleTabFocus = () => {
        Storage.set('tab-uuid', ConfigStore.tabUuid);
    };

    const handleOtherTabStorageChange = async (event: StorageEvent) => {
        switch (event.key as LocalStorageKeyType) {
            case 'preference:scheme':
                if (repackValue(event.newValue) !== PreferenceStore.scheme) {
                    await PreferenceStore.toggleScheme();
                }

                break;

            case 'user:event-stream':
                const userEvent = repackValue(event.newValue);

                if (userEvent) {
                    document.dispatchEvent(
                        new CustomEvent(
                            userEvent.event,
                            { detail: userEvent.detail },
                        ),
                    );
                }

                Storage.destroy('user:event-stream');

                break;

            case 'user:auth-token':
                if (!!repackValue(event.newValue)) {
                    await UserAuthStore.authByToken(repackValue(event.newValue));
                } else {
                    UserAuthStore.cleanOnLogout(false);
                }

                break;

            case 'products:cart':
                await Time.timer(100);

                ShopStore.setCart(Storage.get('store:shop')?.cart || []);

                break;

            case 'system:event-stream':
                const systemEvent = repackValue(event.newValue);

                if (systemEvent) {
                    switch (systemEvent.type) {
                        case 'notistack':
                            Notify.handleStorageNotistackEvent(systemEvent.payload);

                            break;
                    }
                }

                Storage.destroy('system:event-stream');

                break;

            case 'cms:event-stream':
                const cmsEvent = repackValue(event.newValue);

                if (cmsEvent) {
                    switch (cmsEvent.type) {
                        case 'preview:update-completed':
                            document.dispatchEvent(
                                new CustomEvent(
                                    DocumentEvent.PreviewUpdateCompleted,
                                    { detail: cmsEvent.payload },
                                ),
                            );

                            break;
                    }
                }

                Storage.destroy('cms:event-stream');

                break;

            case 'seller:id':
                document.dispatchEvent(
                    new CustomEvent(
                        DocumentEvent.SellerIdIsChanged,
                        { detail: event.newValue },
                    ),
                );

                break;
        }
    };

    useEffect(() => {
        window.onload = () => document.hasFocus() && handleTabFocus();

        window.addEventListener('focus', handleTabFocus);
        window.addEventListener('storage', handleOtherTabStorageChange);

        return () => {
            window.addEventListener('focus', handleTabFocus);
            window.removeEventListener('storage', handleOtherTabStorageChange);
        };
    }, []);

    return <></>;
});


export { LocalStorageSubscription };
