/**
 * SectionsPart
 *
 * @author: exode <hello@exode.ru>
 */

import { MARKETPLACE } from '@/root/src/env';

import { PlatformStore } from '@/store/platform';

import { useRedirect } from '@/cutils';
import { getTranslated, useI18n } from '@/hooks/core';
import { Router as RouterService } from '@/services/Utils/Router';

import { FooterBlockItemProps } from './FooterBlockItemPart';


export const marketplaceSections: () => FooterBlockItemProps['section'][] = () => {

    const { legalDocs } = PlatformStore;

    const { redirect } = useRedirect();

    const { t } = useI18n('components.Navigation.Block.Footer.parts');

    return [
        {
            title: t('sections'),
            items: [
                { text: t('catalog'), link: '/catalog' },
                { text: t('support'), link: '/support' },
                { text: t('tournaments'), link: '/tournaments' },
            ],
        },
        {
            title: t('aboutUs'),
            items: [
                { text: t('aboutPlatform'), link: '/about' },
                {
                    text: t('partnershipProgram'),
                    onClick: () => redirect(
                        getTranslated(legalDocs?.typical.PartnerAgreement),
                        true,
                    ),
                },

            ],
        },
        {
            title: t('toPartners'),
            items: [
                {
                    text: t('publishCourse'),
                    onClick: () => RouterService.pushModal('short-form-partner-become'),
                },
                {
                    text: t('instructionsForRegistration'),
                    onClick: () => redirect(
                        getTranslated(MARKETPLACE?.docs?.guide.sellerCreateCourse),
                        true,
                    ),
                },
            ],
        },
    ];
};
