/**
 * UseMail
 *
 * @author: exode <hello@exode.ru>
 */

import {
    MailTemplateFindManyDocument,
    MailTemplateFindManyQuery,
    useMailSendDynamicMailMutation,
    useMailTemplateCreateMutation,
    useMailTemplateUpdateMutation,
} from '@/codegen/graphql';

import { useI18n } from '@/hooks/core';

import { ManageMailingPageStore } from '@/pages/Manage/Admin/Mailing/store';

import { Notify } from '@/cutils';
import { pluralMail } from '@/libs/plurals';
import { Router as RouterService } from '@/services/Utils/Router';


export const useMail = () => {

    const { t } = useI18n('hooks.apollo.admin');

    const [ sendMail, { loading: sendMailLoading } ] = useMailSendDynamicMailMutation({
        onError: (error) => {
            console.error(error);

            Notify.vkui({
                appearance: 'error',
                message: t('errorOccurredWhileSending'),
            });
        },
        onCompleted: ({ mailSendDynamicMail }) => {
            Notify.vkui({
                appearance: 'success',
                message: t('messageWasSendSuccessfully', {
                    mailCount: mailSendDynamicMail,
                    mail: pluralMail(mailSendDynamicMail),
                }),
            });
        },
    });

    const [ createTemplate, { loading: createTemplateLoading } ] = useMailTemplateCreateMutation({
        onError: (error) => console.error(error),
        onCompleted: ({ mailTemplateCreate }) => {
            RouterService.replacePage('/admin/mailing/:templateId([0-9]+)', { templateId: `${mailTemplateCreate.id}` });
        },
        update: (cache, { data }) => {
            const cachedTemplates = cache.readQuery<MailTemplateFindManyQuery>({
                query: MailTemplateFindManyDocument,
                variables: ManageMailingPageStore.state,
            });

            if (!cachedTemplates) {
                return console.warn('[Cache]: cachedTemplates отсутствуют в кэше');
            }

            data && cache.writeQuery<MailTemplateFindManyQuery>({
                query: MailTemplateFindManyDocument,
                variables: ManageMailingPageStore.state,
                data: {
                    mailTemplateFindMany: {
                        __typename: 'ListTemplateMailOutput',
                        pages: cachedTemplates?.mailTemplateFindMany.pages || 1,
                        count: cachedTemplates?.mailTemplateFindMany.count || 1,
                        items: [ data.mailTemplateCreate, ...(cachedTemplates?.mailTemplateFindMany.items || []) ],
                    },
                },
            });
        },
    });

    const [ updateTemplate, { loading: updateTemplateLoading } ] = useMailTemplateUpdateMutation({
        onError: (error) => console.error(error),
    });

    return {
        updateTemplate,
        updateTemplateLoading,
        createTemplateLoading,
        createTemplate,
        sendMail,
        sendMailLoading,
    };
};
