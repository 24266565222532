/**
 * EducationStoryCard component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { usePlatform } from '@/hooks/core';

import { DivProps, getClassName, Text } from '@exode.ru/vkui';

import { AvatarBlackout } from '@/components/Atoms/AvatarBlackout';

import { Container } from './EducationStoryCard.styled';


export interface EducationStoryCardProps extends DivProps {
    hasView: boolean;
    title?: string;
    image?: string;
}


const EducationStoryCard = (props: EducationStoryCardProps) => {

    const { title, hasView, image = '', ...rest } = props;

    const containerClass = getClassName('vkuiHorizontalCell', usePlatform());

    return (
        <Container className={containerClass} hasView={hasView} {...rest}>
            <div className="education-card-base">
                <AvatarBlackout mode="app" src={image} className="vk-rounded"/>

                <Text className="education-card-base__title">
                    {title}
                </Text>
            </div>
        </Container>
    );
};


export { EducationStoryCard };
