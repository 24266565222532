/**
 * FooterContactItemPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If, Link } from '@/cutils';
import { RoutePathType } from '@/router/paths';

import { Router } from '@/services/Utils/Router';

import { FooterBlockItemBody } from './FooterBlockItemPart';


interface Props {
    item: FooterBlockItemBody;
}


const FooterContactItemPart = (props: Props) => {

    const { item } = props;

    const { contact, link } = item;

    const { name, role, phone, email } = contact || {};

    const match = Router.matchLinkPathAppRoute(link || '');

    return (
        <div className="flex flex-col gap-2">
            <span className="fs-13 m-0">
                {role}
            </span>

            <Link blank={!match}
                  toOuter={!match ? link : undefined}
                  pushPage={match && { id: match.path as RoutePathType, params: match.params }}>
                <span className="fs-13 m-0 text-accent">
                    {name}
                </span>
            </Link>

            <If is={!!email}>
                <a href={`mailto:${email}`} className="fs-13 text-accent no-underline m-0 !cursor-pointer">
                    {email}
                </a>
            </If>

            <If is={!!phone}>
                <a href={`tel:${phone}`} className="fs-16 text-accent no-underline m-0 !cursor-pointer">
                    {phone}
                </a>
            </If>
        </div>
    );
};


export { FooterContactItemPart };
