/**
 * Platform store
 *
 * @author: exode <hello@exode.ru>
 */

import { observer } from 'mobx-react';
import { makeAutoObservable } from 'mobx';

import { CommonPlatformConfig } from '@/shared/types';


class Platform {

    constructor() {
        makeAutoObservable(this);
    }

    COMMON: CommonPlatformConfig | null = window.exode.common;

    get legalDocs() {
        return this.COMMON?.organization.legal.docs;
    }

    /**
     * Установка значений в поля store
     * @param partial
     */
    merge(partial: Partial<Platform>) {
        Object.assign(this, partial);
    }

}

const PlatformStore = new Platform();


export { observer, PlatformStore };
