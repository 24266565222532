/**
 * DomainSwitcher
 *
 * @author: exode <hello@exode.ru>
 */

import { ReactNode } from 'react';

import { SERVE_DOMAIN } from '@/root/src/env';

import { AppServeDomain } from '@/shared/types';


interface Props {
    [AppServeDomain.Ru]?: ReactNode;
    [AppServeDomain.Uz]?: ReactNode;
    [AppServeDomain.Kz]?: ReactNode;
    [AppServeDomain.Global]?: ReactNode;
    /** @deprecated forbidden, use <Platform.School/> */
    [AppServeDomain.Biz]?: ReactNode;
}


const DomainSwitcher = (props: Props) => {
    return (
        <>
            {props[SERVE_DOMAIN || AppServeDomain.Global] || props[AppServeDomain.Global]}
        </>
    );
};


export { DomainSwitcher };
