/**
 * CourseMeta
 *
 * @author: exode <hello@exode.ru>
 */

import { PageParams } from 'router.tsx';

import { gql } from '@apollo/client';

import { MetaPropsType } from '@/types/page';
import { RoutePathType } from '@/router/paths';
import { MetaRequestOptions } from '@/root/src/meta';

import { serverApolloClient } from '@/api/ssr-api';

import { educationSubjects } from '@/types/subject';
import { CourseEntity, EducationSubject } from '@/codegen/graphql';


export const courseMetaRequest = async (
    id: RoutePathType,
    params: PageParams,
    options?: MetaRequestOptions,
): Promise<Partial<MetaPropsType>> => {
    const query = gql`
        query CourseFindOne($courseId: Int, $alias: String) {
            courseFindOne(courseId: $courseId, alias: $alias) {
                name
                description
                seoTags
                tags
                subject
                image {
                    main
                }
            }
        }
    `;

    const variables = params.courseId.match('[a-zA-Z]+')
        ? { alias: params.courseId }
        : { courseId: +params.courseId };

    const { data: { courseFindOne } } = await serverApolloClient.query({
        query,
        variables,
        /** Added no-cache due to course preview logic */
        fetchPolicy: options?.fetchPolicy || 'no-cache',
    });

    const { name, description, tags, seoTags, subject, image } = courseFindOne as CourseEntity;

    return {
        title: [ name, educationSubjects[subject as EducationSubject] ].join(' • '),
        description,
        keywords: [ name, ...tags, ...seoTags ].join(', '),
        image: image?.main,
    };
};
