/**
 * UseProductParticipant
 *
 * @author: exode <hello@exode.ru>
 */

import { apolloClient } from '@/api/graphql';

import {
    CourseFindManyDocument,
    CourseProgressLessonStatus,
    ProductAccessParticipantStatus,
    useProductAccessUpdateParticipantStatusMutation,
} from '@/codegen/graphql';


export const useProductParticipant = (productId: number) => {

    const [
        _updateParticipantStatus,
        { loading: updateParticipantStatusLoading },
    ] = useProductAccessUpdateParticipantStatusMutation();

    const finishCourse = (
        lessonId: number,
        accessId?: number,
        myProgressId?: number,
    ) => {
        return _updateParticipantStatus({
            variables: {
                productId,
                participant: {
                    completeFinalLesson: true,
                    participantStatus: ProductAccessParticipantStatus.Completed,
                },
            },
            update: (cache) => {
                cache.modify({
                    id: `ProductAccessEntity:${accessId}`,
                    fields: {
                        participantStatus: () => ProductAccessParticipantStatus.Completed,
                    },
                });

                for (const entity of [ 'CourseLessonPreview', 'CourseLessonEntity' ]) {
                    cache.modify({
                        id: `${entity}:${lessonId}`,
                        fields: {
                            myProgressStatus: () => CourseProgressLessonStatus.Completed,
                        },
                    });
                }

                if (myProgressId) {
                    cache.modify({
                        id: `CourseProgressEntity:${myProgressId}`,
                        fields: {
                            status: () => CourseProgressLessonStatus.Completed,
                        },
                    });
                }

                apolloClient.refetchQueries({
                    include: [
                        CourseFindManyDocument,
                    ],
                });
            },
        });
    };

    return { finishCourse, updateParticipantStatusLoading };
};
