/**
 * Permissions groups
 *
 * @author: exode <hello@exode.ru>
 */

import { Permission } from '@/libs/modules/auth/auth.rbac';


export type UserPermissions = typeof userPermissions[number];
export type SellerPermissions = typeof sellerPermissions[number];

/**
 * Global sections of admin and seller permissions
 */

/** Permissions that can be assigned to user */
export const userPermissions = [

    /** Course section */
    Permission.CourseManage,
    Permission.CourseView,
    Permission.CourseCurator,
    Permission.CourseReportingView,
    Permission.CourseCommentsManage,
    Permission.CourseStudentManage,

    /** Product section */
    Permission.ProductManage,

    /** School section */
    Permission.SchoolManageSettings,
    Permission.SchoolCustomizeSite,

    /** Seller section */
    Permission.SellerManage,
    Permission.SellerSales,
    Permission.SellerFinances,
    Permission.SellerRefunds,
    Permission.SellerManageManagers,

    /** Admin section */
    Permission.AdminSellerVerify,
    Permission.AdminCoursePublish,
    Permission.AdminCompanyTeamMember,
    Permission.AdminCompanyItDeveloper,

    /** Task section */
    Permission.TaskView,
    Permission.TaskManage,

    /** Tournament section */
    Permission.TournamentCreateOfficial,

    /** User section */
    Permission.UserView,
    Permission.UserHavePartnerAccount,
    Permission.UserIsAdmin,
    Permission.UserIsBot,
    Permission.UserIsAdministrationAccount,

] as const;

/** Permissions that can be assigned to seller */
export const sellerPermissions = [

    /** Course section */
    Permission.CourseManage,
    Permission.CourseView,
    Permission.CourseCurator,
    Permission.CourseReportingView,
    Permission.CourseCommentsManage,
    Permission.CourseStudentManage,

    /** Product section */
    Permission.ProductManage,

    /** School section */
    Permission.SchoolManageSettings,
    Permission.SchoolCustomizeSite,
    Permission.SchoolManageUsers,
    Permission.SchoolManagePages,

    /** Seller section */
    Permission.SellerManage,
    Permission.SellerSales,
    Permission.SellerFinances,
    Permission.SellerRefunds,
    Permission.SellerManageManagers,

    /** Task section */
    Permission.TaskView,
    Permission.TaskManage,

    /** Tournament section */
    Permission.TournamentCreateOfficial,
] as const;


/**
 * Local sections for certain business logic permissions
 */

/** User who can review and publish course */
export const courseReviewer = [
    Permission.UserIsAdmin,
    Permission.AdminCoursePublish,
];

/** User who can view and verify seller */
export const adminSellerVerifier = [
    Permission.UserIsAdmin,
    Permission.AdminSellerVerify,
];
