/**
 * Use plyr api
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { useEffect, useRef } from 'react';

import toast from 'react-hot-toast';

import { Provider } from 'plyr';
import Plyr, { APITypes } from 'plyr-react';

import { Storage } from '@/api/storage';
import { ScrollHelper } from '@/helpers/ui';
import { FileUtil, Time, Url } from '@/utils';
import { DocumentEvent } from '@/types/window';
import { useI18n } from '@/hooks/core/useI18n';

import { CourseService } from '@/services/Course/Course';

import { Icon24DeleteClockOutline } from '@vkontakte/icons';


interface Props {
    videoLink?: string | null;
    onPlay?: (plyr?: Plyr) => void;
    onPause?: (plyr?: Plyr) => void;
    onReady?: (plyr?: Plyr) => void;
    onVolumeChange?: (plyr?: Plyr) => void;
    saveProgressKey?: string;
}


const config = {
    timeout: {
        remoteSyncInterval: 5 * 60 * 1000,
        localSyncInterval: 2000,
    },
};


/**
 * Create plyr api instance
 * @param {Props} props
 */
export const usePlyrApi = (props: Props = {}) => {

    const { t } = useI18n('hooks.core');

    const plyrVideoRef = useRef<APITypes>(null);
    const timeoutRef = useRef<NodeJS.Timeout>();

    const getPlyrApi = () => plyrVideoRef.current?.plyr;

    const saveProgressKey = props.saveProgressKey || Url.parseVideoUrl(props.videoLink || '').id;

    const saveProgressToStore = () => {
        const plyr = getPlyrApi();

        try {
            if (!saveProgressKey || !plyr || !_.isFinite(+plyr.currentTime)) {
                return;
            }

            Storage.set('plyr:progresses', {
                ...(Storage.get('plyr:progresses') || []),
                [saveProgressKey]: {
                    time: _.toInteger(plyr.currentTime),
                    syncedAt: new Date().getTime(),
                },
            });
        } catch (e) {
            console.log(e);
        }
    };

    const forceStartPlay = async () => {
        const plyr = getPlyrApi();

        if (!plyr) {
            return;
        }

        await Time.timer(100);

        plyr.muted = false;

        plyr.togglePlay(true);
        plyr.toggleControls(true);
    };

    const onTimeCodeClick = async (e: any) => {
        const plyr = getPlyrApi();

        if (plyr && _.isFinite(e?.detail?.time)) {
            const time = e?.detail?.time;

            if (plyr.duration < time) {
                return toast(t('timeCodeExceedsVideoTime'), {
                    icon: <Icon24DeleteClockOutline fill="var(--accent)"/>,
                });
            }

            ScrollHelper.to(0, true);

            await forceStartPlay();

            plyr.currentTime = e?.detail?.time;
        }
    };

    /** Event handlers */
    const onPlay = () => {
        props.onPlay?.(getPlyrApi());

        timeoutRef.current = setInterval(
            () => CourseService.mergeVideoProgress(),
            config.timeout.remoteSyncInterval,
        );

        _.throttle(saveProgressToStore, config.timeout.localSyncInterval);
    };

    const onPause = () => {
        props.onPause?.(getPlyrApi());
        timeoutRef.current && clearInterval(timeoutRef.current);

        _.throttle(saveProgressToStore, config.timeout.localSyncInterval);
    };

    const onReady = () => {
        const plyr = getPlyrApi();
        props.onReady?.(plyr);

        if (saveProgressKey && plyr) {
            const savedTime = Storage.get('plyr:progresses')?.[saveProgressKey]?.time;

            if (savedTime) {
                plyr.currentTime = savedTime;

                plyr?.once?.('canplay', () => {
                    plyr.currentTime = savedTime;
                });
            }
        }
    };

    const onVolumeChange = () => {
        props.onVolumeChange?.(getPlyrApi());
    };

    const onTimeUpdate = _.throttle(saveProgressToStore, config.timeout.localSyncInterval);

    /** Handle timecodes */
    useEffect(() => {
        document.addEventListener(DocumentEvent.PlyrClickTimecode, onTimeCodeClick);

        return () => {
            document.removeEventListener(DocumentEvent.PlyrClickTimecode, onTimeCodeClick);
        };
    }, [ plyrVideoRef.current?.plyr ]);

    const removePlyrListeners = (plyr: Plyr | undefined) => {
        plyr?.off?.('ready', onReady);
        plyr?.off?.('canplay', onReady);
        plyr?.off?.('play', onPlay);
        plyr?.off?.('pause', onPause);
        plyr?.off?.('volumechange', onVolumeChange);
        plyr?.off?.('timeupdate', onTimeUpdate);
    };

    /** Handle and save progress, restore on player ready */
    useEffect(() => {
        setImmediate(() => {
            const plyr = getPlyrApi();

            removePlyrListeners(plyr);

            plyr?.on?.('ready', onReady);
            plyr?.once?.('canplay', onReady);
            plyr?.on?.('play', onPlay);
            plyr?.on?.('pause', onPause);
            plyr?.on?.('volumechange', onVolumeChange);
            plyr?.on?.('timeupdate', onTimeUpdate);
        });

        return () => {
            removePlyrListeners(getPlyrApi());

            timeoutRef.current && clearInterval(timeoutRef.current);
        };
    }, [ plyrVideoRef.current, saveProgressKey ]);

    return { plyrVideoRef, getPlyrApi, forceStartPlay };
};

/**
 * Parse link to plyr source
 * @param {string} link
 * @returns {{provider: any, src: any}[] | {src: string}[]}
 */
export const useParseToPlyrSources = (link?: string | null): Plyr.SourceInfo => {
    const { id: providerLinkId, provider } = Url.parseVideoUrl(link || '');

    const extension = FileUtil.getFileUrlExtension(link || '');

    const sources = providerLinkId && provider
        ? [ {
            type: 'video',
            provider: provider as Provider,
            src: providerLinkId,
        } ]
        : [ { type: `video/${extension}`, src: link || '' } ];

    return {
        sources,
        type: 'video',
    };
};
