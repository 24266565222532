/**
 * Use copy hook
 *
 * @author: exode <hello@exode.ru>
 */

import { useRef } from 'react';

import { useI18n } from '@/hooks/core/useI18n';

import { Notify } from '@/cutils';


const useCopy = (withMessage = '') => {

    const { t } = useI18n('hooks.core');

    const ref = useRef<HTMLDivElement>(null);

    const copy = async (value?: string) => {
        withMessage && onCopy(withMessage);

        if (value) {
            return navigator.clipboard.writeText(value)
                .catch(e => console.error(e));
        }

        if (ref.current) {
            return navigator.clipboard.writeText(ref.current.innerText)
                .catch(e => console.error(e));
        }
    };

    const onCopy = (message: string = t('copied')) => {
        Notify.toast.success(message);
    };

    return { copy, onCopy, ref };
};


export { useCopy };
