/**
 * LogoIcon component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { SVGProps } from 'react';


const LogoIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={120} height={21} fill="none" viewBox="0 0 94 16"
             {...props}
        >
            <path fill="#000"
                  d="M12.49 9.824c0-3.357-2.59-5.908-6.245-5.908C2.591 3.916 0 6.445 0 9.958S2.59 16 6.245 16c2.498 0 4.857-1.141 5.76-3.357l-2.499-.402c-.578.828-1.688 1.41-3.1 1.41-2.034 0-3.376-1.209-3.63-3h9.668a8.49 8.49 0 0 0 .046-.827Zm-6.245-3.67c1.665 0 2.914 1.03 3.354 2.484H2.937c.44-1.5 1.689-2.484 3.308-2.484ZM15.91 15.776l2.752-4.408h.44l2.567 4.408h3.1l-3.817-6.109 3.377-5.527H21.39l-2.359 3.916h-.44L16.35 4.14h-3.007l3.377 5.684-3.816 5.952h3.007ZM31.615 16c3.77 0 6.43-2.506 6.43-6.042s-2.66-6.042-6.43-6.042c-3.77 0-6.43 2.506-6.43 6.042S27.845 16 31.615 16Zm0-2.35c-2.174 0-3.654-1.499-3.654-3.692s1.48-3.692 3.654-3.692 3.655 1.499 3.655 3.692-1.48 3.692-3.655 3.692ZM45.539 16a5.923 5.923 0 0 0 4.556-2.104l.555 1.88h1.99V0h-2.66v5.908a5.836 5.836 0 0 0-4.441-1.992c-3.447 0-5.875 2.529-5.875 6.042S42.092 16 45.539 16Zm.647-2.305c-2.22 0-3.747-1.544-3.747-3.737s1.527-3.737 3.747-3.737c2.244 0 3.794 1.544 3.794 3.737s-1.55 3.737-3.794 3.737ZM67.558 9.824c0-3.357-2.59-5.908-6.245-5.908-3.654 0-6.245 2.529-6.245 6.042S57.658 16 61.313 16c2.498 0 4.858-1.141 5.76-3.357l-2.498-.402c-.579.828-1.689 1.41-3.1 1.41-2.035 0-3.377-1.209-3.631-3h9.668c.023-.245.046-.514.046-.827Zm-6.245-3.67c1.666 0 2.915 1.03 3.354 2.484h-6.661c.44-1.5 1.688-2.484 3.307-2.484ZM70.573 16c.925 0 1.642-.694 1.642-1.566 0-.895-.717-1.59-1.642-1.59-.948 0-1.642.695-1.642 1.59 0 .872.694 1.566 1.642 1.566ZM77.893 15.776v-6.87c0-1.477.74-2.305 2.359-2.305h1.526l-.23-2.461h-.51c-1.41 0-2.498.38-3.307 1.678l-.51-1.678h-1.988v11.636h2.66ZM87.917 16a4.173 4.173 0 0 0 3.562-1.97l.532 1.746H94V4.14h-2.66v6.736c0 1.655-1.133 2.82-2.683 2.82-1.526 0-2.59-1.165-2.59-2.82V4.14h-2.66v7.026c0 2.798 1.943 4.834 4.51 4.834Z"/>
        </svg>
    );
};


export { LogoIcon };
