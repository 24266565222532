/**
 * BlitzCard component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Card, Text, Title } from '@exode.ru/vkui';
import { Icon28ChevronRightOutline } from '@vkontakte/icons';

import { Link } from '@/cutils';

import { IconDoubleSwords } from '@/images/icons';
import { BlurImageWrapper } from '@/components/Atoms/BlurImageWrapper';

import { CardShadow, Container } from './TournamentBlitzCard.styled';


const TournamentBlitzCard = () => {
    return (
        <Link pushPageMobile={{ id: '/tournaments/blitz-select' }} pushModal={{ id: 'blitz-subject-select' }}>
            <Container>
                <CardShadow className="bg-gradient-to-br from-green-700 to-lime-600 shadow"/>

                <Card className="p-3 bg-gradient-to-br from-green-600 to-lime-500 shadow-inner">
                    <div className="flex flex-1 justify-center h-full">
                        <BlurImageWrapper className="shadow-inner rounded-full"
                                          image={<IconDoubleSwords height={34} width={34}/>}/>

                        <div className="flex justify-between items-center flex-1 my-auto ml-4">
                            <div className="flex flex-col justify-between items-start">
                                <Title level="1" weight="3" className="text-lg text-white -mt-1">
                                    Блиц-турнир
                                </Title>

                                <Text weight="regular" className="text-sm text-white text-opacity-80">
                                    Игра 1 на 1 со ставкой 100 ex.
                                </Text>
                            </div>

                            <div className="mr-0.5">
                                <div className="rounded-full bg-white p-1.5 shadow-inner">
                                    <Icon28ChevronRightOutline fill="var(--accent)" style={{ marginLeft: 2 }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </Container>
        </Link>
    );
};


export { TournamentBlitzCard };
