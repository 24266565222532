/**
 * UseChatUnread
 *
 * @author: exode <hello@exode.ru>
 */

import { useEffect } from 'react';

import { ChatFolderType } from '@/types/chat';

import { useChatGetUnreadCountQuery } from '@/codegen/graphql';

import { SubscriptionStore } from '@/store/subscription/subscription';


export const useChatUnread = (folder: ChatFolderType) => {

    const {
        data: { chatGetUnreadCount: unreadCountChat } = {},
        loading: unreadCountChatLoading,
    } = useChatGetUnreadCountQuery({
        variables: folder === 'All' ? undefined : { types: [ folder ] },
    });

    useEffect(() => {
        SubscriptionStore.subscribeToChatTypeUnreadCount(folder);
    }, []);

    return {
        unreadCountChat,
        unreadCountChatLoading,
    };
};
