/**
 * Window types
 *
 * @author: exode <hello@exode.ru>
 */

import 'react';

import { SchoolEntity } from '@/codegen/graphql';

import { CommonPlatformConfig, MarketplaceConfig } from '@/shared/types';


/**
 * Глобальные интерфейсы
 */

declare global {

    interface Window {
        ym: any;
        YM_ID: any;
        StickySidebar: any;
        ReactNativeWebView: any;
        onTgAuth: (user?: {
            auth_date: number;
            first_name: string;
            hash: string;
            id: number;
            photo_url: string;
            username: string;
        }) => void | undefined;

        exode: {
            school: SchoolEntity | null;
            marketplace: MarketplaceConfig | null;
            common: CommonPlatformConfig | null;
        };
    }

}


declare module 'react' {
    interface CSSProperties {
        [key: `--${string}`]: string | number;
    }
}

export enum DocumentEvent {
    /** Core */
    AuthStateChanged = 'auth:state-changed',
    FcmWebGetToken = 'fcm:web-get-token',
    FcmWebTokenReceived = 'fcm:web-token-received',
    FcmNativeTokenReceived = 'fcm:native-token-received',
    FcmWebTokenDeclined = 'fcm:web-token-declined',
    PreferenceSchemeChanged = 'preference:scheme-changed',
    RouterBeforeUpdate = 'router:before:update',
    RouterAfterUpdate = 'router:after:update',
    PlyrClickTimecode = 'plyr:click-timecode',
    SystemWindowInteracted = 'system:window-interacted',
    WsConnected = 'ws-connected',
    WsConnectedAfterDisconnect = 'ws-connected-after-disconnect',
    WsDisconnected = 'ws-disconnected',
    PreviewUpdateCompleted = 'preview:update-completed',
    VkUiHapticEventFired = 'VKWebAppTapticImpactOccurred',

    /** Module based */
    SellerIdIsChanged = 'seller:id-is-changed',
    TournamentOnStateChange = 'tournament:on-state-change',
    ChatBeforeNewMessageAddInCache = 'chat:before-new-message-add-in-cache',
    ChatAfterNewMessageAddInCache = 'chat:after-new-message-add-in-cache',
    ChatAfterDeleteMessageInCache = 'chat:after-delete-message-in-cache',
    ProfilePersonalInfoFilledSuccess = 'profile:personal-info-filled-success',
    CourseManageLessonCreated = 'course:manage-lesson-created',
}
