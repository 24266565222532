/**
 * AvatarBlackout styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';

import { AvatarBlackoutProps } from './AvatarBlackout';


export const Wrapper = styled.div<AvatarBlackoutProps>`
  border-radius: ${(props) => props?.blackout?.borderRadius || 'var(--vkui--size_border_radius_paper--regular)'};
  background-image: ${(props) => props?.blackout?.background || 'linear-gradient(42deg, #00000055, #00000005)'};
`;

