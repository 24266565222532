/**
 * Lazy with retry helper
 *
 * @author: exode <hello@exode.ru>
 */

import React, { lazy } from 'react';

import { Storage } from '@/api/storage';


const lazyWithRetry = (componentImport: () => Promise<any>) => (
    lazy(async () => {

        const pageForceRefreshed = Storage.get('page-error-forced-refreshed', false);

        try {
            const component = await componentImport();

            Storage.set('page-error-forced-refreshed', false);

            return component;
        } catch (error) {
            if (!pageForceRefreshed) {

                caches.keys().then((names) => {
                    names.forEach((name) => {
                        caches.delete(name);
                    });
                });

                Storage.set('page-error-forced-refreshed', true);

                return window.location.reload();
            }

            throw error;
        }
    })
);


export { lazyWithRetry };
