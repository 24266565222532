/**
 * AppPreloader component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core';

import { LogoIcon, MainIcon } from '@/images/brand';

import { Container, styles } from './AppPreloader.styled';


interface Props {
    dark: boolean;
}


const AppPreloader = (props: Props) => {

    const { t } = useI18n('components.App.AppPreloader');

    const background = props.dark ? '#19191a' : '#ffffff';

    return (
        <Container>
            <meta name="theme-color" content={background}/>

            <div className="component-app-preloader" style={styles.appPreloader}>
                <div className="component-app-preloader__icon" style={styles.appPreloaderIcon}>
                    <MainIcon width={96} height={96}/>
                </div>

                <div className="component-app-preloader__logo" style={styles.appPreloaderLogo}>
                    <LogoIcon/>
                </div>

                <div className="component-app-preloader__spinner" style={styles.appPreloaderSpinner}>
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path fill="#99a2ad"
                              d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z">
                            <animateTransform attributeName="transform"
                                              type="rotate"
                                              opacity=".85"
                                              dur="0.8s"
                                              values="0 12 12;360 12 12"
                                              repeatCount="indefinite"/>
                        </path>
                    </svg>
                </div>

                <div className="component-app-preloader__text" style={styles.appPreloaderText}>
                    {t('yourPlatformForDevelopment')}
                </div>

                <div className="component-app-preloader__subtext" style={styles.appPreloaderSubtext}>
                    Powered by EXODE LTD
                </div>
            </div>
        </Container>
    );

};


const AppPreloaderClientSideScript = !process.env.REACT_APP_NATIVE && `
<script>
    (function () {
        const scheme = localStorage.getItem('preference:scheme');
        const isDark = scheme.includes('space_gray');
        const setScheme = isDark ? 'space_gray' : 'bright_light';
        const color = isDark ? '#19191a' : '#ffffff';

        document.body.setAttribute('scheme', setScheme);
        document.querySelector('[name="theme-color"]')?.setAttribute('content', color);
    })();
</script>`;


export { AppPreloader, AppPreloaderClientSideScript };
