/**
 * FooterPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer, SchoolStore } from '@/store/platform';

import { RoutePathType } from '@/router/paths';

import { Platform } from '@/cutils';
import { schoolContactRole } from '@/types/school';
import { SchoolContactRole } from '@/codegen/graphql';

import { marketplaceSections } from './SectionsPart';
import { FooterSocialsPart } from './FooterSocialsPart';
import { FooterBlockItemPart } from './FooterBlockItemPart';


const FooterPart = observer(() => {

    const schoolContactRoles = schoolContactRole();

    return (
        <>
            <Platform.Market>
                {marketplaceSections().map((section, index) => (
                    <FooterBlockItemPart key={index} section={section}/>
                ))}

                <FooterSocialsPart/>
            </Platform.Market>

            <Platform.School>
                {SchoolStore?.blocks?.footer?.map((section, index) => (
                    <FooterBlockItemPart key={index} section={{
                        ...section,
                        items: section.items.map(item => ({
                            ...item,
                            link: item.link as RoutePathType,
                        })),
                    }}/>
                ))}

                {SchoolStore?.school?.contacts.map((contact, index) => (
                    <FooterBlockItemPart key={index} section={{
                        title: 'Контакты',
                        items: [
                            {
                                mode: 'contact',
                                link: contact.link as RoutePathType,
                                contact: {
                                    role: schoolContactRoles[contact.role || SchoolContactRole.Support],
                                    name: contact.name,
                                    phone: contact.phone || '',
                                    email: contact.email || '',
                                },
                            },
                        ],
                    }}/>
                ))}
            </Platform.School>
        </>
    );
});


export { FooterPart };
