/**
 * CreateCourseInput
 *
 * @author: exode <hello@exode.ru>
 */

import { IsEnum, IsInt, IsNotEmpty, IsString, MinLength } from 'class-validator';

import { isMinMax } from '@/libs/class-validator/constants';

import { EducationSubject } from '@/codegen/graphql';


export class CreateCourseInput {

    @IsString()
    @MinLength(5, { message: isMinMax(5, 'Название', 'min', 'должно') })
    readonly name: string;

    @IsEnum(EducationSubject)
    readonly subject: string;

    @IsInt()
    @IsNotEmpty()
    readonly contentCategoryId: number;

}
