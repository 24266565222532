/**
 * FieldBottomLimiter component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If } from '@/components/Utils';


interface Props {
    maxLength: number | undefined | null;
    value: string | undefined | null;
    message?: React.ReactNode;
}


const FieldBottomLimiter = (props: Props) => {

    const { maxLength, value, message } = props;

    return (
        <If is={!!maxLength}>
            <div className="flex justify-between items-center">
                <span>{message}</span>
                <span className="flex justify-end">{(value || '').length} / {maxLength}</span>
            </div>
        </If>
    );
};


export { FieldBottomLimiter };
