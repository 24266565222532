/**
 * AppPreloader styled container
 *
 * @author: exode <hello@exode.ru>
 */

import { CSSProperties } from 'react';

import styled from 'styled-components';


export const AppPreloaderStylesString = `
<style>
    body, html {
        margin: 0
    }

    body[scheme="space_gray"] .component-app-preloader {
        background-color: #19191a !important;
        color: #ffffff !important;
    }

    body[scheme="space_gray"] .component-app-preloader__logo {
        filter: invert(1);
    }
    
    .component-app-preloader__logo img {
        display: none !important;
    }
</style>`;

export const Container = styled.div`
  .component-app-preloader {
    body[scheme="space_gray"] & {
      background-color: #19191a !important;
      color: #ffffff !important;
    }
  }

  .component-app-preloader__logo {
    body[scheme="space_gray"] & {
      filter: invert(1);
    }
  }
`;

export const styles: { [name: string]: CSSProperties } = {
    appPreloader: {
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        zIndex: 999999,
        color: '#000000',
        backgroundColor: '#ffffff',
        fontFamily: '"VK Sans Display",-apple-system,system-ui,Helvetica Neue,Roboto,sans-serif',
    },
    appPreloaderIcon: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 'auto',
        marginBottom: 30,
        paddingTop: 60,
    },
    appPreloaderLogo: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 60,
    },
    appPreloaderSpinner: {
        display: 'flex',
        alignItems: 'center',
    },
    appPreloaderText: {
        fontSize: 16,
        fontWeight: 400,
        marginTop: 'auto',
        marginBottom: 30,
    },
    appPreloaderSubtext: {
        fontSize: 13,
        opacity: 0.5,
        marginBottom: 60,
    },
};
