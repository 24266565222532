/**
 * ManageFooterItem component
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { If } from '@/cutils';
import { useI18n, useI18nStatic } from '@/hooks/core';

import { CourseManageFragmentFragment, ProductLaunchByDateStatus, ProductLaunchEntity } from '@/codegen/graphql';

import { Caption } from '@exode.ru/vkui';
import { Icon24Article } from '@vkontakte/icons';
import { TextTooltip } from '@exode.ru/vkui/unstable';

import { gradient } from '@/styles/modules/gradient';
import { InnerHtml } from '@/components/Atoms/InnerHtml';
import { LaunchBadgeStatusIcon } from '@/components/Product/LaunchBadgeStatusIcon';


interface Props {
    course: CourseManageFragmentFragment;
}


const ManageFooterItem = (props: Props) => {

    const { t } = useI18n('components.Course.CourseCard');
    const { t: tStatic } = useI18nStatic('static.types');

    const {
        course: {
            product,
            countLessons: {
                totalCount = 0,
                Draft: draft = 0,
                Published: published = 0,
                ReadyToPublish: readyToPublish = 0,
            },
        },
    } = props;

    const countLaunchesToShow = 3;

    const launches = (product?.launches || []) as ProductLaunchEntity[];

    const groupedAndCountedLaunches = _.transform(
        _.fromPairs(_.sortBy(_.toPairs(_.groupBy(launches, 'saleStatus')), [ 0 ])),
        (r: Record<any, any>, v: Array<ProductLaunchEntity>, k: string) => r[k] = v.length,
    );

    const countLaunchStatuses = Object.entries(groupedAndCountedLaunches).length;

    const getTooltipLessonText = (count: number | null) => (
        `<b>${count || 0}</b> ${t('lessonsHover', { count: count || 0 })}`
    );

    const lessonTooltipText = [
        published && `${getTooltipLessonText(published)} ${t('inStatus', {
            lessonStatus: tStatic('courseLessonStatus.Published').toLowerCase(),
        })}`,
        draft && `${getTooltipLessonText(draft)} ${t('inStatus', {
            lessonStatus: tStatic('courseLessonStatus.Draft').toLowerCase(),
        })}`,
        readyToPublish && `${getTooltipLessonText(readyToPublish)} ${t('inStatus', {
            lessonStatus: tStatic('courseLessonStatus.ReadyToPublish').toLowerCase(),
        })}`,
    ].filter(e => e);

    if (!lessonTooltipText?.length) {
        lessonTooltipText.push([
            getTooltipLessonText(totalCount),
            t('total'),
        ].join(' '));
    }

    return (
        <div className="flex items-center">
            <TextTooltip placement="left"
                         offsetDistance={2}
                         hidden={countLaunchStatuses === 0}
                         offsetSkidding={-5 * (countLaunchStatuses - 1)}
                         text={(
                             <InnerHtml content={(
                                 Object.entries(groupedAndCountedLaunches)
                                     .map((g) => [
                                         `<b>${g[1]}</b>`,
                                         t('launches', { count: g[1] }),
                                         '',
                                         t('inLaunchStatus', {
                                             launchStatus: tStatic(`product.productLaunchByDateStatus.${g[0]}`).toLowerCase(),
                                         }),
                                     ].join(' '))
                                     .join('<br/>')
                             )}/>
                         )}>
                <div className="flex gap-2 items-center">
                    {Object.entries(groupedAndCountedLaunches).slice(0, countLaunchesToShow).map((v) => (
                        <div className="flex items-center gap-1.5">
                            <LaunchBadgeStatusIcon size={18} status={v[0] as ProductLaunchByDateStatus}/>

                            <Caption className="text-subhead line-clamp-2">
                                {v[1]}
                            </Caption>
                        </div>
                    ))}

                    <If is={countLaunchStatuses > countLaunchesToShow}>
                        <Caption className="text-subhead">
                            {t('andAlso')} {countLaunchStatuses - countLaunchesToShow}
                        </Caption>
                    </If>

                    <If is={countLaunchStatuses === 0}>
                        <Caption className="text-subhead">
                            {t('launchesNotCreated')}
                        </Caption>
                    </If>
                </div>
            </TextTooltip>

            <span className="text-subhead mx-2.5 mb-[1px]">|</span>

            <TextTooltip placement="left"
                         offsetDistance={2}
                         offsetSkidding={-5 * (lessonTooltipText.length - 1)}
                         text={(
                             <InnerHtml content={lessonTooltipText.join('<br/>')}/>
                         )}>
                <div className="flex items-center">
                    <div className={[
                        'bg-gradient-to-br p-1 rounded-full',
                        gradient.blue400ToBlue700Gradient,
                    ].join(' ')}>
                        <Icon24Article width={14} height={14} fill="var(--white)"/>
                    </div>

                    <Caption className="text-subhead mx-2 line-clamp-2 mt-[2px]"
                             data-test="course.countLessons.totalCount"
                             data-value={totalCount}>
                        {t('lessons', { count: totalCount || 0 })}
                    </Caption>
                </div>
            </TextTooltip>
        </div>
    );
};


export { ManageFooterItem };
