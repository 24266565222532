/**
 * Field util
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { FormEventHandler } from 'react';

import { FormikErrors, FormikHandlers, FormikTouched } from 'formik';


const fieldStatus = <T, >(
    errors: FormikErrors<T>,
    touched: FormikTouched<T>,
    name: keyof T,
) => {
    return _.get(touched, name) && _.get(errors, name) ? 'error' : 'default';
};

const fieldMessage = <T, >(
    errors: FormikErrors<T>,
    touched: FormikTouched<T>,
    name: keyof T,
    placeholder = ' ',
) => {
    return _.get(touched, name) && _.get(errors, name)
        ? _.get(errors, name)
        : placeholder;
};

const nestedFieldStatus = <T, >(
    errors: FormikErrors<T>,
    touched: FormikTouched<T>,
    nestedName: string,
) => {
    const [ field, name ] = nestedName.split('.');

    const touchedN = touched[field as keyof T] as any;
    const errorsN = errors[field as keyof T] as any;

    if (touchedN && errorsN) {
        return touchedN[name as keyof typeof touched[keyof T]] && errorsN[name as keyof typeof errors[keyof T]]
            ? 'error'
            : 'default';
    }

    return 'default';
};

const nestedFieldMessage = <T, >(
    errors: FormikErrors<T>,
    touched: FormikTouched<T>,
    nestedName: string,
) => {
    const [ field, name ] = nestedName.split('.');

    const touchedN = touched[field as keyof T] as any;
    const errorsN = errors[field as keyof T] as any;

    if (touchedN && errorsN) {
        return touchedN[name as keyof typeof touched[any]] && errorsN[name as keyof typeof errors[any]]
            ? errorsN[name as keyof typeof errors[any]]
            : ' ';
    }

    return ' ';
};

const ignoreSpaceOnChange = (handleChange: FormikHandlers['handleChange']) => {
    return ((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.target.value?.trim() === '') {
            e.target.value = '';
        }

        if (e.target.value) {
            e.target.value = e.target.value
                .replace(/ {2}/g, ' ')
                .replace(/\n{3,}/g, '\n\n');
        }

        return handleChange(e);
    }) as FormEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};


export const Field = {
    status: fieldStatus,
    message: fieldMessage,
    nested: {
        status: nestedFieldStatus,
        message: nestedFieldMessage,
    },
    transform: {
        ignoreSpaceOnChange,
    },
};
