/**
 * FcmProvider component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { createContext, FC, useEffect, useState } from 'react';

import { APP_VERSION, IS_DEV, IS_NATIVE, IS_WEB, LAUNCHER } from '@/root/src/env';

import { ConfigStore } from '@/store/core/config';

import { i18n } from '@/libs/i18n';
import { useLocation } from '@/router/index';

import { Storage } from '@/api/storage';
import { DocumentEvent } from '@/types/window';
import { observer, UserAuthStore } from '@/store/user/auth';

import { UpdateMetaSessionInput, useUpdateSessionMetaMutation } from '@/codegen/graphql';


export interface IFcmContext {
    granted: boolean;
    getWebFcmToken: () => void;
}


export const FcmContext = createContext<IFcmContext>({
    granted: false,
    getWebFcmToken: () => {},
});


export const FcmProvider: FC = observer((props) => {

    const { route: { pageId } } = useLocation();

    const [ updateSessionMeta ] = useUpdateSessionMetaMutation();

    const [ granted, setGranted ] = useState(
        !('Notification' in window)
        || [ 'granted' ].includes(Notification.permission)
        || IS_DEV
        || !IS_WEB
        || Storage.get('fcm-notifications:asked'),
    );

    const getWebFcmToken = () => {
        const event = new CustomEvent(DocumentEvent.FcmWebGetToken);

        document.dispatchEvent(event);

        /** Remove delay on click */
        setGranted(true);
        Storage.set('fcm-notifications:asked', true);
    };

    const partialSessionMeta: () => UpdateMetaSessionInput = () => ({
        launcher: LAUNCHER,
        appLocation: pageId,
        appVersion: APP_VERSION,
        userAgent: navigator.userAgent,
        language: ConfigStore.language,
        timezone: new Date().getTimezoneOffset() / 60,
    });

    const onReceiveToken = async (e: any) => {
        const { token } = e?.detail || {};

        if (!granted) {
            setGranted(true);
        }

        if (!UserAuthStore.isLoggedIn) {
            return;
        }

        await updateSessionMeta({
            variables: {
                sessionMeta: {
                    ...partialSessionMeta(),
                    fcmToken: token,
                },
            },
        });
    };

    const onDeclinedToken = () => {
        setGranted(true);
    };

    const handleUpdateSessionMeta = async () => {
        if (!UserAuthStore.isLoggedIn) {
            return;
        }

        setImmediate(async () => {
            await updateSessionMeta({
                variables: {
                    sessionMeta: partialSessionMeta(),
                },
            });
        });
    };

    useEffect(() => {
        i18n.on('languageChanged', handleUpdateSessionMeta);
        document.addEventListener(DocumentEvent.FcmWebTokenReceived, onReceiveToken);
        document.addEventListener(DocumentEvent.FcmWebTokenDeclined, onDeclinedToken);

        if (IS_NATIVE) {
            document.addEventListener(DocumentEvent.FcmNativeTokenReceived, onReceiveToken);
        }

        /** Получили разрешение на запрос */
        if (granted) {
            getWebFcmToken();
        }

        return () => {
            i18n.off('languageChanged', handleUpdateSessionMeta);
            document.removeEventListener(DocumentEvent.FcmWebTokenReceived, onReceiveToken);
            document.removeEventListener(DocumentEvent.FcmWebTokenDeclined, onDeclinedToken);

            if (IS_NATIVE) {
                document.removeEventListener(DocumentEvent.FcmNativeTokenReceived, onReceiveToken);
            }
        };
    }, [ granted ]);

    useEffect(() => {
        handleUpdateSessionMeta().catch();
    }, [ pageId ]);

    return (
        <FcmContext.Provider value={{ granted, getWebFcmToken }}>
            {props.children}
        </FcmContext.Provider>
    );
});


