/**
 * App chat subscriptions
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer, SubscriptionStore } from '@/store/subscription/subscription';

import { ChatSubscription } from './ChatSubscription';
import { ChatSyncAfterConnect } from './ChatSyncAfterConnect';
import { ChatHandleDisconnect } from './ChatHandleDisconnect';
import { ChatTypingSubscription } from './ChatTypingSubscription';
import { ChatMessageSubscription } from './ChatMessagesSubscription';
import { ChatFolderUnreadCountTotalSubscription } from './ChatFolderUnreadCountTotalSubscription';


const AppChatSubscriptions = observer(() => {
    return (
        <>
            <ChatSubscription/>
            <ChatHandleDisconnect/>
            <ChatSyncAfterConnect/>

            {[ ...SubscriptionStore.subscribedChatTypeUnreadCount ].map((folder) => (
                <ChatFolderUnreadCountTotalSubscription key={folder} folder={folder}/>
            ))}

            {[ ...SubscriptionStore.subscribedChatIds ].map((id) => (
                <ChatMessageSubscription key={id} chatId={id}/>
            ))}

            {[ ...SubscriptionStore.subscribedChatTypingsIds ].map((id) => (
                <ChatTypingSubscription key={id} chatId={id}/>
            ))}
        </>
    );
});


export { AppChatSubscriptions };
