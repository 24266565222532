/**
 * InvitationBanner
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Auth, Link } from '@/cutils';
import { useI18n } from '@/hooks/core';

import { InnerHtml } from '@/components/Atoms/InnerHtml';
import { GradientBanner, GradientBannerImage } from '@/components/Atoms/GradientBanner';

import { Button } from '@exode.ru/vkui';
import { Icon24LinkedOutline } from '@vkontakte/icons';


const InvitationBanner = () => {

    const { t } = useI18n('components.Promotion.ContextBanners');

    return (
        <Auth>
            <GradientBanner imageTheme="light"
                            gradient="bg-content"
                            header={t('friendsAreNotInExodeYet')}
                            subheader={<InnerHtml content={t('inviteFriends&Get500ex')}/>}
                            image={(
                                <GradientBannerImage className="mt-10 -ml-12 banner__image">
                                    <Icon24LinkedOutline width={140} height={140} style={{ marginRight: -20 }}/>
                                </GradientBannerImage>
                            )}
                            actions={(
                                <Link pushModal={{ id: 'user-invite' }}>
                                    <Button mode="primary" size="m">{t('invite')}</Button>
                                </Link>
                            )}/>
        </Auth>
    );
};


export { InvitationBanner };
