/**
 * ConfigProvider component
 *
 * @author: exode <hello@exode.ru>
 */

import moment from 'moment';

import React, { createContext, Dispatch, ReactElement, SetStateAction, useState } from 'react';

import { APP_VERSION, IS_NATIVE, OS_PLATFORM } from '@/root/src/env';

import { DbConfigKey, useConfigGetServerTimeQuery, useGetDbConfigByKeyQuery } from '@/codegen/graphql';


export interface ConfigState {
    clientTimeOffset: number;
    digitalIsOnSale: {
        ios: boolean;
        android: boolean;
        web: boolean;
        vk: boolean;
    },
    openTabsState: {
        course: {
            [key: string]: null | Window
        };

        lesson: {
            [key: string]: null | Window
        };

        practice: {
            [key: string]: null | Window
        };

        storage: {
            [key: string]: null | Window
        };

        school: {
            [key: string]: null | Window
        };
    };
}

interface ConfigStateProps {
    children: ReactElement | ReactElement[];
}

export interface UseConfigContext {
    state: ConfigState;
    setState: (patch: Partial<ConfigState> | ((previousState: ConfigState) => Partial<ConfigState>)) => void;
}


/**
 * Initial state
 */
const initialConfigState = {
    clientTimeOffset: 0,
    digitalIsOnSale: {
        ios: true,
        android: true,
        web: true,
        vk: true,
    },
    openTabsState: {
        course: {},
        lesson: {},
        practice: {},
        storage: {},
        school: {},
    },
};

/**
 * Creating config context
 * @returns {ISocketContext}
 */
export const ConfigContext = createContext({
    state: initialConfigState,
    setState: Function as Dispatch<SetStateAction<any>>,
});

/**
 * Config provider
 * @param {ConfigStateProps} props
 * @constructor
 */
const ConfigProvider = (props: ConfigStateProps) => {

    const [ state, setState ] = useState(initialConfigState);

    useGetDbConfigByKeyQuery({
        skip: !IS_NATIVE,
        notifyOnNetworkStatusChange: true,
        variables: {
            version: APP_VERSION,
            key: OS_PLATFORM === 'ios'
                ? DbConfigKey.IosIsOnSale
                : DbConfigKey.AndroidIsOnSale,
        },
        onCompleted: ({ configGetOneByKey }) => {
            /** Set is on sale state */
            setState((prev) => ({
                ...prev,
                digitalIsOnSale: {
                    ...prev.digitalIsOnSale,
                    [OS_PLATFORM]: configGetOneByKey,
                },
            }));
        },
        onError: error => console.error(error),
    });

    useConfigGetServerTimeQuery({
        onError: error => console.error(error),
        onCompleted: ({ configGetServerTime }) => {
            const clientTimeOffset = moment().diff(moment(configGetServerTime));

            /** Set client time offset state */
            setState((prev) => ({
                ...prev,
                clientTimeOffset,
            }));
        },
    });

    return (
        <ConfigContext.Provider value={{ state, setState }}>
            {props.children}
        </ConfigContext.Provider>
    );
};


export { ConfigProvider };
