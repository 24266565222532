/**
 * Use upload files  hook
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { nanoid } from 'nanoid';

import { ChangeEvent, useState } from 'react';

import { Notify } from '@/cutils';
import { useI18n } from '@/hooks/core/useI18n';
import { FileImageDimensions, FileTypeByMime, FileUtil } from '@/utils';


interface Props {
    maxFiles: number;
}

export interface FileInfo {
    name: string;
    size: string;
    fileExtension: string;
    duration?: number;
    dimensions?: FileImageDimensions;
}

export interface UploadedFilePreviews {
    id: string;
    type: FileTypeByMime;
    file: File;
    src: string;
    info: FileInfo | null,
}


const useUploadFiles = (props: Props) => {

    const { t } = useI18n('hooks.core');

    const [ previews, setPreviews ] = useState<UploadedFilePreviews[]>([]);

    const loadFilesWithPreviews = (e: ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target;

        e.preventDefault();
        e.stopPropagation();

        if (!files) {
            return;
        }

        if (files && files.length > props.maxFiles
            || (previews.length + files.length) > props.maxFiles
        ) {
            return Notify.toast.error(
                t('maxFileUploadError', { max: props.maxFiles }),
                { icon: '⚠️' },
            );
        }

        _.forEach(files, async (file) => {
            const info = await getFilesInfo(file);

            const type = FileUtil.getFileTypeByMimeType(file.type);

            if (type === FileTypeByMime.Image) {
                const fileReader = new FileReader();

                fileReader.readAsDataURL(file);

                fileReader.onloadend = () => (
                    setPreviews((prev) => ([
                        ...prev,
                        {
                            file,
                            info,
                            type,
                            id: nanoid(),
                            src: fileReader.result as string,
                        },
                    ]))
                );
            } else {
                setPreviews((prev) => ([
                    ...prev,
                    {
                        file,
                        info,
                        type,
                        src: '',
                        id: nanoid(),
                    },
                ]));
            }
        });
    };

    return { previews, loadFilesWithPreviews, setPreviews, getFilesInfo };
};

const setupFilesInfo = async (files: FileList | File[]) => {
    const fileInfos: FileInfo[] = [];

    for (const file of files || []) {
        const result = await getFilesInfo(file);

        fileInfos.push(result);
    }

    return fileInfos;
};

const getFilesInfo = async (file: File): Promise<FileInfo> => {
    const { name, size, type } = file;

    const [ __, extension ] = type.split('/');

    const fileExtension = extension?.includes('vnd')
        ? 'docx'
        : extension?.includes('plain') ? 'txt' : extension;

    const dimensions = type?.includes('image')
        ? await FileUtil.getImageDimensions(file)
        : undefined;

    const duration = type?.includes('video')
        ? await FileUtil.getVideoDurationByFile(file)
        : undefined;

    return _.omitBy({
        name,
        fileExtension,
        duration,
        dimensions,
        size: FileUtil.getFileSize(size),
    }, _.isUndefined) as unknown as FileInfo;
};


export {
    useUploadFiles,
    setupFilesInfo,
    getFilesInfo,
};
