/**
 * Task types
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { useI18n } from '@/hooks/core/useI18n';

import { GqlResult } from '@/types/graphql';

import {
    TaskAnswerType,
    TaskComplexity,
    TaskContentType,
    TaskFindOneQueryResult,
    TaskVisibility,
} from '@/codegen/graphql';

import {
    Icon20CheckBoxOn,
    Icon20ChevronLeft2,
    Icon20ChevronLeftOutline,
    Icon20CircleSmallOutline,
    Icon20DocumentPlusOutline,
    Icon20LineBottom,
    Icon20RadioOn,
    Icon20TableHeaderOutline,
    Icon20WriteSquareOutline,
} from '@vkontakte/icons';


/**
 * Тип контента задания
 */
export const taskContentType = () => {

    const { t } = useI18n('types.task');

    return {
        [TaskContentType.Course]: t('course'),
        [TaskContentType.Tournament]: t('tournament'),
    } as const;
};

/**
 * Иконка сложности контента задания
 */
export const taskComplexityIcon: { [key in TaskComplexity]: ReactElement } = {
    [TaskComplexity.VeryLow]: <Icon20ChevronLeft2 className="text-green-400" style={{ transform: 'rotate(-90deg)' }}/>,
    [TaskComplexity.Low]: <Icon20ChevronLeftOutline className="text-green-600"
                                                    style={{ transform: 'rotate(-90deg)' }}/>,
    [TaskComplexity.Medium]: <Icon20CircleSmallOutline className="text-accent"/>,
    [TaskComplexity.High]: <Icon20ChevronLeftOutline className="text-red-400" style={{ transform: 'rotate(90deg)' }}/>,
    [TaskComplexity.VeryHigh]: <Icon20ChevronLeft2 className="text-red-600" style={{ transform: 'rotate(90deg)' }}/>,
} as const;

/**
 * Сложность контента задания
 */
export const taskComplexity: { [key in TaskComplexity]: string } = {
    [TaskComplexity.VeryHigh]: 'Очень высокая',
    [TaskComplexity.High]: 'Высокая',
    [TaskComplexity.Medium]: 'Средняя',
    [TaskComplexity.Low]: 'Низкая',
    [TaskComplexity.VeryLow]: 'Очень низкая',
} as const;

/**
 * Типы ответа задания
 */
export const taskAnswerType: { [key in TaskAnswerType]: string } = {
    [TaskAnswerType.Single]: 'Единичный выбор',
    [TaskAnswerType.Multiple]: 'Множественный выбор',
    [TaskAnswerType.Short]: 'Точное совпадение',
    [TaskAnswerType.Mapping]: 'Таблица соответствия',
    [TaskAnswerType.Arrange]: 'Заполнить пропуски',
    [TaskAnswerType.Detailed]: 'Развернутый ответ',
};

/**
 * Иконка типа ответа задания
 */
export const taskAnswerTypeIcon: { [key in TaskAnswerType]: ReactElement } = {
    [TaskAnswerType.Single]: <Icon20RadioOn className="text-accent"/>,
    [TaskAnswerType.Multiple]: <Icon20CheckBoxOn className="text-accent"/>,
    [TaskAnswerType.Short]: <Icon20WriteSquareOutline className="text-accent"/>,
    [TaskAnswerType.Arrange]: <Icon20LineBottom className="text-accent"/>,
    [TaskAnswerType.Mapping]: <Icon20TableHeaderOutline className="text-accent"/>,
    [TaskAnswerType.Detailed]: <Icon20DocumentPlusOutline className="text-accent"/>,
};

/**
 * Видимость задания
 */
export const taskVisibility = () => {

    const { t } = useI18n('types.task');

    return {
        [TaskVisibility.All]: t('all'),
        [TaskVisibility.Personal]: t('personal'),
        [TaskVisibility.Organization]: t('organization'),
    } as const;
};

/**
 * Режим просмотра задания
 */
export enum TaskViewMode {
    Create = 'Create',
    Preview = 'Preview',
    Answer = 'Answer',
    TaskPage = 'TaskPage',
    Result = 'Result',
}

/**
 * Задание турнира
 */

export type TaskFindOneResult = GqlResult<TaskFindOneQueryResult>['taskFindOne'];
export type TaskFindOneWithoutAnswer = Omit<NonNullable<TaskFindOneResult>, 'answer'>;
