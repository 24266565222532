/**
 * Placeholder component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { SvgComponent } from '@/cutils';
import { Placeholder as VkUiPlaceholder } from '@exode.ru/vkui';

import { Container } from './Placeholder.styled';


export interface PlaceholderProps {
    header: string | ReactElement;
    content: string | ReactElement;
    className?: string;
    icon?: ReactElement;
    action?: string | ReactElement;
    innerPadding?: string;
    iconSize?: 36 | 48 | 72 | 96 | 120 | 160;
}


const Placeholder = (props: PlaceholderProps) => {

    const {
        content,
        header,
        action,
        className,
        iconSize = 72,
    } = props;

    const icon = props.icon
        ? <SvgComponent element={props.icon} svgProps={{ width: iconSize, height: iconSize }}/>
        : undefined;

    return (
        <Container {...props}>
            <VkUiPlaceholder icon={icon}
                             className={[ className, 'm-auto pt-6 pb-8' ].join(' ')}
                             action={(action && <div className="mt-6">{action}</div>)}
                             header={(
                                 <span className={icon && 'block mt-2.5'}>
                                     {header}
                                 </span>
                             )}>
                <div className="px-3">
                    {content}
                </div>
            </VkUiPlaceholder>
        </Container>
    );
};


export { Placeholder };
