/**
 * FieldPart
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { FormikProps } from 'formik';

import { Field } from '@/cutils';
import { getTranslated } from '@/hooks/core';
import { TranslateJson } from '@/shared/types';

import { Icon24CheckBoxOff, Icon24CheckBoxOn } from '@vkontakte/icons';
import { Checkbox, FormItem, Input, Select, Textarea } from '@exode.ru/vkui';

import { InnerHtml } from '@/components/Atoms/InnerHtml';
import { ContentCard } from '@/components/Atoms/ContentCard';
import { CopyToClipboard } from '@/components/Atoms/CopyToClipboard';
import { FieldBottomLimiter } from '@/components/Atoms/FieldBottomLimiter';

import { DynamicFormProps } from '../DynamicForm';


interface Props {
    index: number;
    config: FormikProps<Record<string, string>>;
    field: DynamicFormProps['fields'][number];
}


const FieldPart = (props: Props) => {

    const { field, index, config } = props;

    const {
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
    } = config;

    const {
        type,
        name,
        title,
        hidden,
        hideIf,
        options,
        placeholder,
        description,
    } = field;

    const top = field.top
        ? <InnerHtml content={getTranslated(field.top as TranslateJson)}/>
        : undefined;

    if (hidden || _.some(hideIf || {}, (e: any[], key) => e?.includes(values[key]))) {
        return <></>;
    }

    switch (type) {
        case 'input':
            return (
                <FormItem key={index}
                          top={top}
                          className="p-0"
                          status={Field.status(errors, touched, name)}
                          bottom={Field.message(errors, touched, name)} {...field.props}>
                    <Input name={name}
                           onBlur={handleBlur}
                           value={values[name]}
                           placeholder={getTranslated(placeholder as TranslateJson)}
                           onChange={Field.transform.ignoreSpaceOnChange(handleChange)}
                           {...field.props}/>
                </FormItem>
            );

        case 'textarea':
            return (
                <FormItem key={index} top={top} className="p-0" status={Field.status(errors, touched, name)} bottom={(
                    <FieldBottomLimiter value={values[name]}
                                        maxLength={field.maxLength}
                                        message={Field.message(errors, touched, name)}/>
                )}>
                    <Textarea name={name}
                              rows={field.rows}
                              value={values[name]}
                              maxLength={field.maxLength}
                              placeholder={getTranslated(placeholder as TranslateJson)}
                              onChange={Field.transform.ignoreSpaceOnChange(handleChange)}
                              {...field.props}/>
                </FormItem>
            );

        case 'select':
            return (
                <FormItem key={index}
                          top={top}
                          className="p-0"
                          status={Field.status(errors, touched, name)}
                          bottom={Field.message(errors, touched, name)}>
                    <Select name={name}
                            onBlur={handleBlur}
                            value={values[name]}
                            onChange={(e) => setFieldValue(name, e.target.value)}
                            placeholder={getTranslated(placeholder as TranslateJson)}
                            options={(
                                _.map(options, ({ value, label }) => ({
                                    value,
                                    label: getTranslated(label as TranslateJson) || '',
                                }))
                            )}
                            {...field.props}/>
                </FormItem>
            );

        case 'checkbox':
            return (
                <ContentCard compact
                             key={index}
                             mode="outline"
                             hasHover={false}
                             hasActive={false}
                             activeAfter={false}
                             innerCardClassName="bg-hover"
                             className="!rounded-lg select-none mt-1 mb-4"
                             header={(
                                 <Checkbox name={name}
                                           hasHover={false}
                                           hasActive={false}
                                           onChange={handleChange}
                                           checked={!!values[name]}
                                           className="cursor-pointer bg-input thin-border"
                                           onIcon={(
                                               <Icon24CheckBoxOn fill="var(--dynamic_green)"/>
                                           )}
                                           offIcon={(
                                               <Icon24CheckBoxOff fill="var(--text_secondary)"/>
                                           )}
                                           description={(
                                               <InnerHtml className="text-primary" content={(
                                                   getTranslated(description as TranslateJson)
                                               )}/>
                                           )} {...field.props}>
                                     <InnerHtml content={getTranslated(title as TranslateJson)}/>
                                 </Checkbox>
                             )}/>
            );

        case 'banner':
            return (
                <ContentCard transparent mode="outline" activeAfter={false} className="vk-rounded overflow-hidden"
                             {...field.props} header={(
                    field.props?.header && (
                        <InnerHtml content={getTranslated(field.props.header)}/>
                    )
                )} subtitle={(
                    field.props?.subtitle && (
                        <InnerHtml content={getTranslated(field.props.subtitle)}/>
                    )
                )} caption={(
                    field.props?.caption && (
                        <InnerHtml content={getTranslated(field.props.caption)}/>
                    )
                )}/>
            );

        case 'copy-input':
            return (
                <CopyToClipboard link={field.props?.link} {...field.props}/>
            );

        case 'custom-html':
            return (
                <InnerHtml {...field.props}/>
            );

        default:
            return <></>;
    }
};


export { FieldPart };
