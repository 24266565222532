/**
 * Subscription shared types
 *
 * @author: exode <hello@exode.ru>
 */


/**
 * ClientEvent - what client should listen
 */
export class ClientEvent {

    /** User and client */

    static userOnlinePing = 'user:online.ping';
    static clientOnlineCount = 'client:online.count';

    static userOnline(id: string | number) {
        return `user:${id}.online`;
    }

    /** Chat */

    static chatNewListEvent(userId: number) {
        return `chat-list:${userId}.new-event`;
    }

    static chatNewMessage(chatId: number) {
        return `chat:${chatId}.new-message`;
    }

    static chatNewTotalUnreadCount(
        userId: number,
        folder?: string,
    ) {
        return [
            `chat:${userId}.new-total-unread-count`,
            folder ? folder : '',
        ].filter(e => e).join('.');
    }

    static chatNewTypingState(chatId: number) {
        return `chat:${chatId}.new-typing-state`;
    }

    /** Cart */

    static cartNewState(userId: number) {
        return `cart:${userId}.new-state`;
    }

    /** Tournament */

    static tournamentNewState(id: number) {
        return `tournament:${id}.new-state`;
    }

    static tournamentNewMember(id: number) {
        return `tournament:${id}.new-member`;
    }

    static tournamentNewPlayer(id: number) {
        return `tournament:${id}.new-player`;
    }

    static tournamentNewMessage(id: number) {
        return `tournament:${id}.new-message`;
    }

    static tournamentNewMemberProgress(id: number) {
        return `tournament:${id}.new-member-progress`;
    }

    static tournamentBlitzLobbyNewState(id: number) {
        return `tournament:${id}.blitz-lobby-new-state`;
    }

    /** Notification */

    static notificationNewItemOrUpdate(userId: number) {
        return `notification:${userId}.new-item`;
    }

    static notificationNewUnreadCount(userId: number) {
        return `notification:${userId}.new-count`;
    }

    /** Profile */

    static profileListenNewState(profileId: number) {
        return `profile:${profileId}.new-state`;
    }

    /** Session */

    static sessionExit(sessionId: number) {
        return `session:${sessionId}.exit`;
    }

    /** Wallet */

    static walletNewBalance(userId: number) {
        return `wallet:${userId}.new-balance`;
    }
}

/**
 * ClientEmit - what server should listen
 */
export class ClientEmit {

    /** User and client */

    static userOnlinePong = 'user:online.pong';
    static userOnlineStatus = 'user:online.status';

}
