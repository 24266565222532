/**
 * Use file paste hook
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { nanoid } from 'nanoid';

import { useEffect } from 'react';

import { FileUtil } from '@/utils';
import { getFilesInfo, UploadedFilePreviews } from '@/hooks/core';


interface Props {
    onPaste?: (event: ClipboardEvent) => void;
    onFilePaste?: (value: UploadedFilePreviews[]) => void;
}


const useFilePaste = (props: Props) => {

    const { onPaste, onFilePaste } = props;

    const handlePaste = async (event: ClipboardEvent) => {
        onPaste?.(event);

        const resultFiles: UploadedFilePreviews[] = [];

        const eventFiles = _.map(event.clipboardData?.files, (f) => f) as File[];

        if (eventFiles.length) {
            event.preventDefault();
        }

        const promises = eventFiles.map((file) => new Promise(async (resolve) => {
            const info = await getFilesInfo(file);
            const type = FileUtil.getFileTypeByMimeType(file.type);

            const fileReader = new FileReader();

            fileReader.onloadend = () => {
                resultFiles.push({
                    file,
                    info,
                    type,
                    id: nanoid(),
                    src: fileReader.result as string,
                });

                resolve(true);
            };

            fileReader.readAsDataURL(file);
        }));

        await Promise.all(promises);

        if (resultFiles.length) {
            onFilePaste?.(resultFiles);
        }
    };

    useEffect(() => {
        document.addEventListener('paste', handlePaste);

        return () => {
            document.removeEventListener('paste', handlePaste);
        };
    }, []);
};


export { useFilePaste };
