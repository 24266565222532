/**
 * ProfilePage store
 *
 * @author: exode <hello@exode.ru>
 */

import { makeObservable } from 'mobx';
import { observer, PageStore } from '@/pages/Core';


class ProfilePage extends PageStore {

    constructor() {
        super();
        makeObservable(this);
    }

    sellerId: number | undefined;

    setSeller(id: number) {
        this.sellerId = id;
    }

}

const ProfilePageStore = new ProfilePage();


export { observer, ProfilePageStore };