/**
 * User auth store
 *
 * @author: exode <hello@exode.ru>
 */

import { makeAutoObservable } from 'mobx';
import { observer } from 'mobx-react';
import { PageParams } from 'router.tsx';

import { RouterStore } from '@/store/core/router';
import { ProfileStore } from '@/store/user/profile';
import { SellerStore } from '@/store/user/seller';
import { PreferenceStore } from '@/store/preference/preference';

import { Time } from '@/utils';
import { Storage } from '@/api/storage';
import { apolloClient } from '@/api/graphql';
import { reconnectSocket } from '@/api/socket';
import { HttpStatus } from '@/shared/types';
import { DocumentEvent } from '@/types/window';
import { LocalStorageKeyType } from '@/types/config';

import { Permission, UserEntity } from '@/codegen/graphql';

import { Router } from '@/services/Utils/Router';
import { UserAuthService } from '@/services/User/Auth';
import { ProfileService } from '@/services/Profile/Profile';


const isLoggedIn = !!Storage.get('user:auth-token');

class Auth {

    constructor() {
        makeAutoObservable(this);
    }

    /** Состояние авторизации в приложении */
    isLoggedIn: boolean = isLoggedIn;

    /** Текущий залогиненный пользователь */
    user?: UserEntity;

    /** Получение прав пользователя */
    get permissions() {
        return this.user?.permissions || [];
    }

    /** Объединение прав менеджера и продавца */
    get combinedPermissions(): Permission[] {
        return [ ...SellerStore.permissions, ...UserAuthStore.permissions ];
    }

    /**
     * Clean user data from app
     * @param {boolean} redirectToLogin
     */
    cleanOnLogout(redirectToLogin: boolean) {
        this.merge({ isLoggedIn: false });

        PreferenceStore.merge({ spinner: false });

        const clearLocalStorageKeys: LocalStorageKeyType[] = [
            'user:auth-token',
            'user:referral-uuid',
            'store:user-auth',
            'store:user-profile',
            'store:seller',
            'store:shop',
            'seller:id',
            'seller:become-partner-status',
            'chat:message-draft',
            'draft:become-partner',
            'plyr:progresses',
        ];

        for (const key of clearLocalStorageKeys) {
            Storage.destroy(key);
        }

        redirectToLogin && Router.replacePage('/login');
        window.location.reload();
    }

    /**
     * Trigger document event on auth state changed
     */
    triggerAuthStateDocumentEvent() {
        const event = new CustomEvent(DocumentEvent.AuthStateChanged);

        document.dispatchEvent(event);
    }

    /**
     * Вход через редирект с token авторизации
     * - токен будет передан в URL
     * @param {string} token
     * @param {PageParams} params
     * @returns {Promise<void>}
     */
    async handleRedirectTokenAfterLogin(
        token: string,
        params?: PageParams,
    ) {
        const isSuccess = await UserAuthStore.authByToken(token);

        if (isSuccess) {
            await Router.openAfterLogin(params);
        }
    }

    /**
     * Авторизация через токен (или повторный запрос для обновления данных)
     * @param {string} token
     * @returns {Promise<boolean>}
     */
    async authByToken(token?: string): Promise<boolean> {
        token && Storage.set('user:auth-token', token);

        const { success, payload, unsent, exception } = await ProfileService.getSelfProfileInfo();

        const logoutExceptions = [
            HttpStatus.UNAUTHORIZED,
            HttpStatus.BAD_REQUEST,
        ];

        if (exception && logoutExceptions.includes(exception.code)) {
            this.cleanOnLogout(false);

            Router.replacePage('/login', {
                to: RouterStore.pageId,
                params: Router.urlQueryParams,
            });

        } else if (success) {
            const { user, profile } = payload;

            ProfileStore.merge({ ...profile, user });
            UserAuthStore.merge({ user, isLoggedIn: true });

            /** Reconnect to socket with new token */
            reconnectSocket();

            /** Trigger auth state changed */
            this.triggerAuthStateDocumentEvent();

        } else if (unsent) {
            await Time.timer(5 * 1000);

            return this.authByToken(token);
        }

        return success;
    }

    /**
     * Выход пользователя
     * @returns {Promise<void>}
     */
    async logout() {
        try {
            PreferenceStore.merge({ spinner: true });

            await apolloClient.resetStore();

            await UserAuthService.logout();
        } catch (e) {}

        reconnectSocket();

        this.cleanOnLogout(true);
        this.triggerAuthStateDocumentEvent();
    }

    /**
     * Merge and persist
     * @param {Partial<Auth>} auth
     */
    merge(auth: Partial<Auth>) {
        Object.assign(UserAuthStore, auth);

        Storage.persistStore(UserAuthStore, 'store:user-auth');
    }

}

const UserAuthStore = new Auth();

Storage.recoverStore(UserAuthStore, 'store:user-auth');


export { observer, UserAuthStore };
