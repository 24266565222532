/**
 * Manage drawer
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { TFunction } from 'i18next';

import { IS_SCHOOL } from '@/root/src/env';

import { PageAction, Router } from '@/services/Utils/Router';

import { CourseType, Permission } from '@/codegen/graphql';
import { PageSpace, SellerPermissions } from '@/shared/types';

import {
    Icon28CoinsOutline,
    Icon28CrownOutline,
    Icon28CubeBoxOutline,
    Icon28FireOutline,
    Icon28ListBulletSquareOutline,
    Icon28MessagesOutline,
    Icon28SchoolOutline,
    Icon28Users3Outline,
    Icon28UsersOutline,
    Icon28UserTagOutline,
} from '@vkontakte/icons';


export type DrawerSectionType = keyof ReturnType<typeof manageDrawerSections>;

export type SellerManagerPermissions = Array<{
    value: SellerPermissions,
    title: string,
    description: string
}>

export interface ManageServiceItem {
    title: string;
    description: string;
    permissions: Permission[];
    icon: ReactElement;
    spaces?: PageSpace[];
    path?: PageAction;
    subfields?: Array<
        ManageServiceItem & { type: 'item', path: PageAction }
        | { type: 'section'; section?: string; divider?: boolean; }
    >;
}


export const manageDrawerSections: (
    tStatic: TFunction<string, undefined>,
) => Record<
    'commercial' | 'interactions' | 'content' | 'administration',
    {
        name: string;
        items: Record<string, ManageServiceItem>
    }
> = (tStatic) => Object.freeze({
    commercial: {
        name: tStatic('commercial'),
        items: {
            products: {
                title: tStatic('products'),
                description: tStatic('productManagement'),
                permissions: Router.getContextMenuSectionPermissions('products'),
                icon: <Icon28CubeBoxOutline/>,
                spaces: [ PageSpace.ProductsRightMenu ],
                path: {
                    id: '/manage/courses/:page([0-9]+)',
                    params: { type: CourseType.VideoCourse },
                },
            },
            ...IS_SCHOOL
                ? null
                : {
                    seller: {
                        title: tStatic('seller'),
                        description: tStatic('sellerManagement'),
                        permissions: Router.getContextMenuSectionPermissions('seller'),
                        icon: <Icon28UserTagOutline/>,
                        spaces: [ PageSpace.SellerRightMenu ],
                        path: {
                            id: Router.getFirstContextMenuItem('seller')?.path?.id,
                        },
                    },
                },
            salesAndFinances: {
                title: IS_SCHOOL ? tStatic('sales') : tStatic('finance'),
                description: tStatic('purchases&WithdrawalOfFunds'),
                permissions: Router.getContextMenuSectionPermissions('sales'),
                icon: <Icon28CoinsOutline/>,
                spaces: [ PageSpace.FinancesRightMenu ],
                path: {
                    id: Router.getFirstContextMenuItem('sales')?.path?.id,
                },
            },
        },
    },
    interactions: {
        name: tStatic('interaction'),
        items: {
            questions: {
                title: tStatic('practice'),
                description: tStatic('questions&AnswersFromStudents'),
                permissions: [ Permission.CourseCurator ],
                icon: <Icon28MessagesOutline/>,
                spaces: [ PageSpace.PracticeRightMenu ],
                path: {
                    id: '/manage/practice',
                    params: { folder: 'Practice' },
                },
            },
            users: {
                title: tStatic('schoolUsers'),
                description: tStatic('manageSchoolUsers'),
                permissions: [ Permission.SchoolManageUsers ],
                icon: <Icon28UsersOutline/>,
                spaces: [ PageSpace.UsersRightMenu ],
                path: { id: '/manage/school/users/:page([0-9]+)' },
            },
            students: {
                title: tStatic('myStudents'),
                description: tStatic('studentManagement'),
                permissions: [
                    Permission.CourseStudentManage,
                    Permission.SchoolManageUsers,
                ],
                icon: <Icon28Users3Outline/>,
                spaces: [ PageSpace.StudentsRightMenu ],
                path: { id: '/manage/students/:page([0-9]+)' },
            },
        },
    },
    content: {
        name: tStatic('content'),
        items: {
            exercises: {
                title: tStatic('taskBase'),
                description: tStatic('taskBaseManagement'),
                permissions: [
                    Permission.TaskView,
                    Permission.TaskManage,
                    Permission.UserIsAdmin,
                ],
                icon: <Icon28ListBulletSquareOutline/>,
                spaces: [ PageSpace.TaskBaseRightMenu ],
                path: { id: '/manage/tasks/:page([0-9]+)' },
            },
            promotion: {
                title: tStatic('promotion'),
                description: tStatic('createTournamentsWithOwnTasks'),
                permissions: [
                    Permission.UserIsAdmin,
                ],
                icon: <Icon28FireOutline/>,
                spaces: [ PageSpace.PromotionRightMenu ],
                path: { id: '/manage/shorts' },
            },
        },
    },
    administration: {
        name: tStatic('administrate'),
        items: {
            admin: {
                title: tStatic('admin'),
                description: tStatic('adminManagement'),
                permissions: [ Permission.UserIsAdmin ],
                icon: <Icon28CrownOutline/>,
                spaces: [ PageSpace.AdminRightMenu ],
                path: { id: '/admin/users/:page([0-9]+)' },
            },
            ...IS_SCHOOL
                ? {
                    school: {
                        title: tStatic('mySchool'),
                        description: tStatic('schoolProfileManagement'),
                        permissions: Router.getContextMenuSectionPermissions('school'),
                        icon: <Icon28SchoolOutline/>,
                        spaces: [ PageSpace.SchoolRightMenu ],
                        path: { id: '/manage/school/settings/basic' },
                    },
                } : null,
        },
    },
});
