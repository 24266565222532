/**
 * Storage types
 *
 * @author: exode <hello@exode.ru>
 */

export enum FileExtension {
    All = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document,'
        + 'application/msword,text/plain,video/*,image/*,application/pdf',
    Video = 'video/*',
    Image = 'image/*',
    ImageAndVideo = 'video/*,image/*',
    Docs = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document,'
        + 'application/msword,text/plain,application/pdf',
}
