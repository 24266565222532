/**
 * Task plurals
 *
 * @author: exode <hello@exode.ru>
 */

import plural from 'plural-ru';


/**
 * Склонение "задание"
 * @param {number} length
 * @param wrapper
 * @returns {string}
 */
export const pluralTask = (length: number, wrapper: Function = (w: string) => w) => (
    wrapper(plural(length, 'задание', 'задание', 'задания'))
);

/**
 * Склонение "категория"
 * @param {number} length
 * @param wrapper
 * @returns {string}
 */
export const pluralCategory = (length: number, wrapper: Function = (w: string) => w) => (
    wrapper(plural(length, 'категория', 'категории', 'категорий'))
);