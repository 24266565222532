/**
 * CourseService
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import moment from 'moment';

import { Maybe } from 'graphql/jsutils/Maybe';

import { Storage } from '@/api/storage';
import { apolloClient } from '@/api/graphql';
import { getUserState } from '@/hooks/apollo';

import { CourseLessonItems } from '@/types/course';

import {
    CourseProgressLessonStatus,
    CourseProgressType,
    UpdateUserStateDocument,
    UpdateUserStateMutationVariables,
    UserStateKey,
} from '@/codegen/graphql';

import { BaseService } from '@/services/Core/Base';


class CourseService extends BaseService {

    /**
     * Регистрация сервиса
     */
    static register() {
        window.addEventListener('beforeunload', async () => {
            await this.mergeVideoProgress();
        });
    }

    /**
     * Инициализация сервиса
     */
    static init() {
        this.refetchRemoteVideoProgress().finally();
    }

    /** Состояние прогрессов видео с сервера */
    static remoteVideoProgresses = {};

    /**
     * Получение серверного состояния прогрессов видео
     * @returns {Promise<void>}
     */
    static async refetchRemoteVideoProgress() {
        if (!Storage.get('user:auth-token')) {
            return;
        }

        try {
            const { data, error } = await getUserState(UserStateKey.PlyrVideoProgress);

            if (error) {
                return;
            }

            this.remoteVideoProgresses = data.userStateGetByKey || {};

            await this.mergeVideoProgress();

        } catch (error) {
            console.error('refetchRemoteVideoProgress error', error);
        }
    }

    /**
     * Объединяем локальное состояние прогресса с серверным
     * @returns {Promise<void>}
     */
    static async mergeVideoProgress() {
        if (!Storage.get('user:auth-token')) {
            return;
        }

        try {
            const localProgresses = Storage.get('plyr:progresses');

            const merged: Record<any, any> = _.mergeWith(
                localProgresses,
                this.remoteVideoProgresses,
                (a, b) => {
                    if (!a || !b) {
                        return !a ? b : a;
                    }

                    return a.syncedAt > b.syncedAt ? a : b;
                },
            );

            Storage.set('plyr:progresses', merged);

            await apolloClient.mutate<UpdateUserStateMutationVariables>({
                mutation: UpdateUserStateDocument,
                variables: {
                    key: UserStateKey.PlyrVideoProgress,
                    input: { value: merged },
                },
            });
        } catch (error) {
            console.error('mergeVideoProgress error', error);
        }
    }

    /**
     * Преобразование длительности из секунд в часы + минуты или минуты + секунды
     * @param {number} seconds
     * @return {string}
     */
    static formatDuration(seconds: number) {
        return moment.utc(seconds * 1000).format(seconds < 3200 ? 'mm:ss' : 'HH:mm:ss');
    }

    /**
     * Получение продолжительности курса
     * @param {Date} from
     * @param {Date} to
     * @return {string}
     */
    static getDuration(
        from: Date,
        to: Date,
    ) {
        return moment(to).diff(moment(moment() > moment(from) ? undefined : from), 'days');
    }

    /**
     * Проверка возможности начать следующий урок (если он FeatureDisabled)
     * @param {{progressType: }} course
     * @param {{withPractice: boolean, canStartNextLesson: boolean} | undefined | null} previous
     * @param { | undefined | null} progressStatus
     * @param {{withDemo?: boolean}} options
     */
    static canStartNextFeatureDisabled(
        course: {
            progressType: CourseProgressType;
        },
        previous: {
            withPractice: boolean;
            canStartNextLesson: boolean;
        } | undefined | null,
        progressStatus: CourseProgressLessonStatus | undefined | null,
        options: { withDemo?: boolean } = {},
    ) {
        return previous?.canStartNextLesson
            && progressStatus === CourseProgressLessonStatus.FeatureDisabled
            && !this.nextLessonStartManually(course, previous)
            || options?.withDemo && (
                progressStatus === CourseProgressLessonStatus.AvailableAsDemo
            );
    }

    /**
     * Start the next lesson only manually
     * @param {{progressType: }} course
     * @param {Maybe<{withPractice: boolean}>} currentLesson
     */
    static nextLessonStartManually(
        course: {
            progressType: CourseProgressType;
        },
        currentLesson: Maybe<{
            withPractice: boolean;
        }>,
    ) {
        return course.progressType === CourseProgressType.AfterCheck
            && !!currentLesson?.withPractice;
    }

    /**
     * Вычисление order относительно списка урока
     * @param {CourseLessonItems} lessons
     * @param {number | null} currentLessonId
     */
    static getLessonPublishedOrder(
        lessons: CourseLessonItems,
        currentLessonId?: number | null,
    ) {
        const index = _.findIndex(lessons, { id: currentLessonId || 0 });

        return _.isFinite(index) ? index + 1 : 0;
    }

    /**
     * Кол-во завершенных уроков
     * @param {CourseLessonItems} lessons
     */
    static getCompletedLessonsCount(
        lessons: Array<{
            myProgressStatus?: CourseLessonItems[number]['myProgressStatus']
        }>,
    ) {
        const completedLessons = _.filter(lessons, {
            myProgressStatus: CourseProgressLessonStatus.Completed,
        });

        return completedLessons.length;
    }

    /**
     * Урок завершен
     * @param {{myProgressStatus?:  | null}} lesson
     */
    static lessonIsCompleted(
        lesson?: {
            myProgressStatus?: CourseProgressLessonStatus | null
        },
    ) {
        return lesson?.myProgressStatus === CourseProgressLessonStatus.Completed;
    }

}


export { CourseService };
