/**
 * TournamentSubscription component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useBatchUpdateCacheFields } from '@/hooks/core';

import { TournamentMemberEntity, useTournamentListenMembersProgressSubscription } from '@/codegen/graphql';


interface Props {
    id: number;
}


const TournamentMembersProgressSubscription = (props: Props) => {

    const { id } = props;

    useTournamentListenMembersProgressSubscription({
        variables: { tournamentId: id },
        onData: ({ data: { data }, client: { cache } }) => {
            if (!data) {
                return;
            }

            const { tournamentListenNewMemberProgress: progress } = data;

            cache.modify({
                id: cache.identify(progress),
                fields: useBatchUpdateCacheFields<TournamentMemberEntity>(progress),
            });
        },
    });

    return (<></>);
};


export { TournamentMembersProgressSubscription };
