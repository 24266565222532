/**
 * App user subscriptions
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { UserOnlineCountSubscription } from './UserOnlineCountSubscription';
import { UserWalletBalanceSubscription } from './UserWalletBalanceSubscription';


const AppUserSubscriptions = () => {
    return (
        <>
            <UserOnlineCountSubscription/>
            <UserWalletBalanceSubscription/>
        </>
    );
};


export { AppUserSubscriptions };
