/**
 * ManageMailingPage store
 *
 * @author: exode <hello@exode.ru>
 */

import { makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import { PageStore } from '@/pages/Core';


class ManageMailingPage extends PageStore {

    constructor() {
        super();
        makeObservable(this);
    }

    state = {
        list: {
            skip: 0,
            take: 10,
        },
        filter: {},
        sort: {},
    };

}

const ManageMailingPageStore = new ManageMailingPage();


export { observer, ManageMailingPageStore };
