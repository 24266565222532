/**
 * Manage permission
 *
 * @author: exode <hello@exode.ru>
 */

/**
 * Тип разрешения пользователя
 */

import { Permission } from '@/codegen/graphql';
import { UserPermissions } from '@/shared/types';
import { SellerManagerPermissions } from '@/types/manage';


interface PermissionText {
    title: string;
    description: string;
}

interface UserPermissionsManage {
    verified: PermissionText;
    banned: PermissionText;
    permissions: Array<PermissionText & { value: UserPermissions }>;
}

/**
 * Управление пользователем и его правами доступа
 */
export const userManage: UserPermissionsManage = {
    verified: {
        title: 'Пользователь "подтвержден"',
        description: 'Галочка во всех отображения профиля',
    },
    banned: {
        title: 'Пользователь заблокирован',
        description: 'Выход из всех сессий и ограничение доступа к платформе',
    },
    permissions: [
        {
            value: Permission.CourseManage,
            title: 'Управление курсами',
            description: 'Пользователь сможет управлять курсами, разделами и уроками, добавлять и редактировать контент курса.',
        },
        {
            value: Permission.CourseView,
            title: 'Просмотр курсов',
            description: 'Пользователь сможет видеть списки всех курсов для предварительного просмотра их содержания.',
        },
        {
            value: Permission.CourseCurator,
            title: 'Куратор курсов',
            description: 'Пользователь сможет проверять домашние задания студентов, помогать им в практике и отвечать на их вопросы.',
        },
        {
            value: Permission.CourseReportingView,
            title: 'Просмотр отчетов по курсам',
            description: 'Пользователь сможет просматривать отчеты по всем курсам.',
        },
        {
            value: Permission.CourseCommentsManage,
            title: 'Управление комментариями курса',
            description: 'Пользователь сможет управлять комментариями, оставленными студентами в лекциях курса и обзорах курсов.',
        },
        {
            value: Permission.CourseStudentManage,
            title: 'Управление студентами курса',
            description: 'Пользователь сможет просматривать информацию о студентах.',
        },
        {
            value: Permission.ProductManage,
            title: 'Управление продуктами',
            description: 'Пользователь сможет создавать и управлять запусками, ценами, купонами, публиковать или снимать с публикации в каталоге.',
        },
        {
            value: Permission.SchoolManageSettings,
            title: 'Управление настройками школы',
            description: 'Пользователь сможет изменять общие настройки школы.',
        },
        {
            value: Permission.SchoolCustomizeSite,
            title: 'Управление оформлением школы',
            description: 'Пользователь сможет вносить изменения в оформлении школы, включая тему, навигацию по сайту и создание пользовательских страниц.',
        },
        {
            value: Permission.SellerManage,
            title: 'Управление продавцом',
            description: 'Пользователь сможет видеть и обновлять информацию о продавце, реквизиты выплат.',
        },
        {
            value: Permission.SellerSales,
            title: 'Продажи продавца',
            description: 'Пользователь сможет просматривать историю оплат, а также просматривать финансовые отчеты.',
        },
        {
            value: Permission.SellerFinances,
            title: 'Финансы продавца',
            description: 'Пользователь сможет просматривать текущий баланс школы, отправлять запросы на выплату, просматривать историю выплат и отчеты.',
        },
        {
            value: Permission.SellerRefunds,
            title: 'Возвраты продавца',
            description: 'Пользователь сможет видеть список возвратов, общаться с пользователями по вопросам возврата и подтверждать возвраты.',
        },
        {
            value: Permission.SellerManageManagers,
            title: 'Управление сотрудниками продавца',
            description: 'Пользователь сможет управлять другими сотрудниками для текущего продавца.',
        },
        {
            value: Permission.AdminSellerVerify,
            title: 'Верификация партнеров',
            description: 'Пользователь сможет проверять запросы верификации от партнеров.',
        },
        {
            value: Permission.AdminCoursePublish,
            title: 'Публикация курса администратором',
            description: 'Пользователь сможет публиковать запрошенные запуски в общем каталоге проектов.',
        },
        {
            value: Permission.AdminCompanyTeamMember,
            title: 'Член команды компании',
            description: 'Пользователь сможет просто иметь эту роль и гордиться этим.',
        },
        {
            value: Permission.AdminCompanyItDeveloper,
            title: 'Разработчик IT компании',
            description: 'Пользователь сможет просто иметь эту роль и гордиться этим.',
        },
        {
            value: Permission.TaskView,
            title: 'Просмотр базы заданий',
            description: 'Пользователь сможет просматривать задания из базы заданий.',
        },
        {
            value: Permission.TaskManage,
            title: 'Управление базой заданий',
            description: 'Пользователь сможет создавать и обновлять задачу.',
        },
        {
            value: Permission.TournamentCreateOfficial,
            title: 'Создание официального турнира',
            description: 'Пользователь сможет создавать официальные турниры (включая трансляции).',
        },
        {
            value: Permission.UserView,
            title: 'Просмотр данных пользователя',
            description: 'Пользователь сможет видеть все данные пользователя, включая личные поля, такие как электронная почта, телефон и т.д.',
        },
        {
            value: Permission.UserHavePartnerAccount,
            title: 'Аккаунт партнера',
            description: 'Пользователь сможет иметь партнерский аккаунт (маркер для всех продавцов).',
        },
        {
            value: Permission.UserIsAdmin,
            title: 'Администратор',
            description: 'Пользователь сможет иметь суперадминский аккаунт (маркер для суперадмина).',
        },
        {
            value: Permission.UserIsBot,
            title: 'Бот',
            description: 'Пользователь сможет иметь аккаунт бота (маркер для бота).',
        },
        {
            value: Permission.UserIsAdministrationAccount,
            title: 'Административный аккаунт',
            description: 'Пользователь сможет иметь административный аккаунт (маркер для администрации).',
        },
    ],
};

export const sellerGlobalManagerPermissions: SellerManagerPermissions = [
    {
        value: Permission.CourseManage,
        title: 'Управление курсами',
        description: 'Пользователь сможет управлять курсами, разделами и уроками, добавлять и редактировать контент курсов.',
    },
    {
        value: Permission.CourseView,
        title: 'Просмотр курсов',
        description: 'Пользователь сможет видеть списки всех курсов для предварительного просмотра их содержания.',
    },
    {
        value: Permission.CourseCurator,
        title: 'Куратор курсов',
        description: 'Пользователь сможет проверять домашние задания студентов, помогать им в практике и отвечать на их вопросы.',
    },
    {
        value: Permission.CourseReportingView,
        title: 'Просмотр отчетов по курсам',
        description: 'Пользователь сможет просматривать отчеты по всем курсам.',
    },
    {
        value: Permission.CourseCommentsManage,
        title: 'Управление комментариями курса',
        description: 'Пользователь сможет управлять комментариями, оставленными студентами в уроках и обзорах курса.',
    },
    {
        value: Permission.CourseStudentManage,
        title: 'Управление студентами курса',
        description: 'Пользователь сможет просматривать информацию о студентах.',
    },
    {
        value: Permission.ProductManage,
        title: 'Управление продуктами',
        description: 'Пользователь сможет создавать и управлять запусками, ценами, купонами, публиковать или снимать с публикации в каталоге.',
    },
    {
        value: Permission.SchoolManageSettings,
        title: 'Управление настройками школы',
        description: 'Пользователь сможет изменять общие настройки школы.',
    },
    {
        value: Permission.SchoolCustomizeSite,
        title: 'Управление оформлением школы',
        description: 'Пользователь сможет вносить изменения в оформлении школы, включая тему, навигацию по сайту и создание пользовательских страниц.',
    },
    {
        value: Permission.SellerManage,
        title: 'Управление продавцом',
        description: 'Пользователь сможет видеть и обновлять информацию о продавце, реквизиты выплат.',
    },
    {
        value: Permission.SellerSales,
        title: 'Продажи продавца',
        description: 'Пользователь сможет просматривать историю оплат, а также просматривать финансовые отчеты.',
    },
    {
        value: Permission.SellerFinances,
        title: 'Финансы продавца',
        description: 'Пользователь сможет просматривать текущий баланс школы, отправлять запросы на выплату, просматривать историю выплат и отчеты.',
    },
    {
        value: Permission.SellerRefunds,
        title: 'Возвраты продавца',
        description: 'Пользователь сможет видеть список возвратов, общаться с пользователями по вопросам возврата и подтверждать возвраты.',
    },
    {
        value: Permission.SellerManageManagers,
        title: 'Управление сотрудниками продавца',
        description: 'Пользователь сможет управлять другими сотрудниками для текущего продавца.',
    },
    {
        value: Permission.TaskView,
        title: 'Просмотр базы заданий',
        description: 'Пользователь сможет просматривать задания из базы заданий.',
    },
    {
        value: Permission.TaskManage,
        title: 'Управление базой заданий',
        description: 'Пользователь сможет создавать и обновлять задания из базы заданий.',
    },
    {
        value: Permission.TournamentCreateOfficial,
        title: 'Создание официального турнира',
        description: 'Пользователь сможет создавать официальные турниры (включая трансляции).',
    },
];

export const sellerProductManagerPermissions: SellerManagerPermissions = [
    {
        value: Permission.CourseManage,
        title: 'Управление курсом',
        description: 'Пользователь сможет управлять курсом, разделами и уроками, добавлять и редактировать контент курса.',
    },
    {
        value: Permission.CourseView,
        title: 'Просмотр курса',
        description: 'Пользователь сможет видеть курс в списке для предварительного просмотра их содержания.',
    },
    {
        value: Permission.CourseCurator,
        title: 'Куратор курса',
        description: 'Пользователь сможет проверять домашние задания студентов, помогать им в практике и отвечать на их вопросы.',
    },
    {
        value: Permission.CourseReportingView,
        title: 'Просмотр отчетов по курсу',
        description: 'Пользователь сможет просматривать отчеты по текущему курсу.',
    },
    {
        value: Permission.CourseCommentsManage,
        title: 'Управление комментариями курса',
        description: 'Пользователь сможет управлять комментариями, оставленными студентами в уроках и обзорах курса.',
    },
    {
        value: Permission.ProductManage,
        title: 'Управление продуктовой частью',
        description: 'Пользователь сможет создавать и управлять запусками, ценами, купонами, публиковать или снимать с публикации в каталоге.',
    },
];

export const sellerManagerPermissions = [
    ...sellerGlobalManagerPermissions,
    ...sellerProductManagerPermissions,
];

