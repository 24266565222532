/**
 * FloatingAgoraButtons styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


export const Container = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  z-index: 150;
`;
