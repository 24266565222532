/**
 * AppErrorBoundary component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { Component, ErrorInfo, ReactElement } from 'react';

import { ErrorFallback } from '@/components/App/ErrorFallback';


interface Props {
    children: ReactElement | ReactElement[];
    fallbackComponent?: ReactElement;
}

interface State {
    isError: boolean;
}


class ErrorBoundary extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = { isError: false };
    }

    static getDerivedStateFromError(error: Error) {
        return error && { isError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.log(error, errorInfo);
    }

    render() {
        if (this.state.isError) {
            return (
                this.props.fallbackComponent
                || <ErrorFallback isBoundary onClick={() => window.location.reload()}/>
            );
        }

        return this.props.children;
    }
}


export { ErrorBoundary };
