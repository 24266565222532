/**
 * Mail types
 *
 * @author: exode <hello@exode.ru>
 */

export enum Mailer {
    Info = 'info',
    Support = 'support',
    Notification = 'notification',
}
