/**
 * UploadButtonPart component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { FileInfo } from '@/hooks/core';

import { FileExtension } from '@/shared/types';
import { Icon28AddCircleFillBlue } from '@vkontakte/icons';

import { FileUploadProps } from '@/components/Atoms/FileUpload';


interface Props extends FileUploadProps {
    filesInfo: FileInfo[] | null;
    uploadProgress: number;
    isUploading: boolean;
    children?: ReactElement;
}


const UploadButtonPart = (props: Props) => {

    const { filesInfo, uploadProgress, isUploading, children, addIconSize = 56 } = props;

    if (filesInfo && filesInfo.some(({ fileExtension }) => fileExtension !== FileExtension.Docs)) {
        return (<></>);
    }

    if (!isUploading && (uploadProgress === 0 || uploadProgress === 100)) {
        return children || (
            <Icon28AddCircleFillBlue width={addIconSize} height={addIconSize} fill="var(--accent)"/>
        );
    }

    return (<></>);
};


export { UploadButtonPart };
