/**
 * RightPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer } from '@/store/user/profile';

import { Auth, Link, Unauth } from '@/cutils';

import { Icon28UserCircleOutline } from '@vkontakte/icons';
import { Avatar as VkUiAvatar, Search } from '@exode.ru/vkui';

import { HeaderItem } from '@/components/Desktop/Panel';
import { DesktopNavigationContextMenuDrawer } from '@/components/Desktop/Navigation';

import { SettingsDropdownParts } from './SettingsDropdownParts';


const RightPart = observer(() => {
    return (
        <div className="viewport__right-menu vkuiSplitCol--spaced h-full">
            <div className="h-full flex items-center justify-between">
                <Link pushPage={{ id: '/friends' }}>
                    <Search after="" style={{ width: 220 }}/>
                </Link>

                <Auth>
                    <SettingsDropdownParts/>
                </Auth>

                <Unauth>
                    <Link pushPage={{ id: '/login' }}>
                        <HeaderItem className="bg-transparent ml-1">
                            <VkUiAvatar size={35} shadow={false}>
                                <Icon28UserCircleOutline className="text-accent"/>
                            </VkUiAvatar>
                        </HeaderItem>
                    </Link>
                </Unauth>

                <DesktopNavigationContextMenuDrawer/>
            </div>
        </div>
    );
});


export { RightPart };
