/**
 * StickyButton component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import styles from './StickyButton.module.scss';


interface Props {
    children: ReactElement;
    isTab?: boolean;
    className?: string;
}


const StickyButton = (props: Props) => {

    const { children, isTab = false } = props;

    return (
        <div className={[
            'mt-6',
            props.className,
            '-ml-[0.04em] -mr-[0.04em]',
            isTab ? styles.stickyButtonAtTab : styles.stickyButton,
        ].join(' ')}>
            {children}
        </div>
    );
};


export { StickyButton };
