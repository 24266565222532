/**
 * LanguageSwitcher
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer } from 'mobx-react';

import { ConfigStore } from '@/store/core/config';

import { useI18n, usePrompt } from '@/hooks/core';

import { Icon28HieroglyphCharacterOutline } from '@vkontakte/icons';

import { HeaderItem } from '@/components/Desktop/Panel';

import { LanguageOptionsPart } from './parts/LanguageOptionsPart';


interface Props {
    component?: Function;
    componentProps?: Record<any, any>;
}


const LanguageSwitcher = observer((props: Props) => {

    const { component: Component = HeaderItem, componentProps = {} } = props;

    const { t } = useI18n('components.Atoms.LanguageSwitcher');

    const { openPrompt: openChangeLanguage } = usePrompt({
        onConfirm: () => {},
        title: t('selectLanguage'),
        subtitle: t('selectLanguageDescription'),
        children: <LanguageOptionsPart/>,
        buttons: {
            cancel: { hidden: true },
            confirm: { hidden: true },
        },
    });

    return (
        <Component onClick={openChangeLanguage}
                   children={<Icon28HieroglyphCharacterOutline fill="var(--accent)"/>}
                   className={[ 'bg-transparent', !ConfigStore.isDesktop && 'vk-rounded' ].join(' ')}
                   {...componentProps}/>
    );
});


export { LanguageSwitcher };
