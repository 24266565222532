/**
 * EducationStoryCard styled container
 *
 * @author: exode <hello@exode.ru>
 */

import { EducationStoryCardProps } from './EducationStoryCard';

import styled from 'styled-components';


export const Container = styled.div<EducationStoryCardProps>`
    .education-card-base {
        position: relative;
        width: 125px;
        height: 125px;
        padding: 0 6px;
    }

    .Avatar {
        width: 125px !important;
        height: 125px !important;
        border-radius: 18px !important;
        padding: 4px;
        color: transparent;
        box-shadow: ${({ hasView }) => !hasView
                ? ' inset 0 0 0 2px var(--accent), inset 0 0 0 4px var(--background_content)'
                : ' inset 0 0 0 2px var(--text_secondary), inset 0 0 0 4px var(--background_content)'};
    }

    .vkuiAvatar__img {
        border-radius: var(--vkui--size_border_radius_paper--regular) !important;
    }

    .education-card-base__title {
        position: absolute;
        bottom: 15px;
        left: 23px;
        color: white;
        font-size: 13px;
        line-height: 1.1;
        text-shadow: 0 0 2px #333, 2px 2px 10px #4343aa, 0 0 13px #000000;
    }
`;
