/**
 * Interfaces
 *
 * @author: exode <hello@exode.ru>
 */

export enum FileUploadVideoMode {
    Local = 'Local',
    Vimeo = 'Vimeo',
    Youtube = 'Youtube',
}