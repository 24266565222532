/**
 * Array
 *
 * @author: exode <hello@exode.ru>
 */

class ArrayUtil {

    /**
     * Move array item to another index
     * @param {any[]} array
     * @param {number} from
     * @param {number} to
     * @returns arr
     */
    static moveByIndex(
        array: any[],
        from: number,
        to: number,
    ) {
        if (array.length === 1) {
            return array;
        }

        if (from === 0 && to === -1) {
            return array;
        }

        if (to === (array.length + 1)) {
            return array;
        }

        array.splice(to, 0, array.splice(from, 1)[0]);

        return array;
    }

    /**
     * Reorder list
     * @param {{from: number, to: number}} indexes
     * @param {any[]} list
     * @returns {any[]}
     */
    static reorderList(
        indexes: { from: number, to: number },
        list: any[],
    ) {
        const { from, to } = indexes;

        const items = [ ...list ];

        items.splice(from, 1);
        items.splice(to, 0, list[from]);

        return items;
    }


    /**
     * Join last separately
     * @param {any[]} array
     * @param {string[]} glues
     * @returns {string}
     */
    static joinLastSeparately(
        array: any[],
        glues = [ ', ', ' и ' ],
    ) {
        const newArray = [ ...array ];

        const last = newArray.pop();

        return [ newArray.join(glues[0]), last ].join(glues[1]);
    }

}


export { ArrayUtil };
