/**
 * InputSideInfo
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactNode } from 'react';


interface Props {
    side: 'before' | 'after';
    children: ReactNode;
    className?: string;
}


const InputSideInfo = (props: Props) => {

    const { children, side, className } = props;

    return (
        <div className={[
            className,
            side === 'before' ? 'ml-1' : 'mr-1',
            'bg-selected px-2.5 py-1 rounded-lg text-accent',
        ].join(' ')}>
            {children}
        </div>
    );
};


export { InputSideInfo };
