/**
 * Use open or focus tab hook
 *
 * @author: exode <hello@exode.ru>
 */

import { useCallback } from 'react';

import { useConfigContext } from '@/hooks/core/useConfig';


export interface UseOpenOrFocusTabProps {
    id: string;
    url: string;
    type: 'course' | 'lesson' | 'practice' | 'storage' | 'school';
}


const useOpenOrFocusTab = () => {
    const { state, setState } = useConfigContext();

    const openOrFocusTab = useCallback(({ url, type, id }: UseOpenOrFocusTabProps) => {

        const isSameUrl = new URL(url).href === state.openTabsState[type][id]?.location.href;

        const newTab = isSameUrl && state.openTabsState[type][id] && !state.openTabsState[type][id]?.closed
            ? state.openTabsState[type][id]
            : window.open(url);

        setState((previousState) => ({
            ...previousState,
            openTabsState: {
                ...previousState.openTabsState,
                [type]: {
                    ...previousState.openTabsState[type],
                    [id]: newTab,
                },
            },
        }));

        state.openTabsState[type][id]?.focus();
    }, [ state ]);

    return [ openOrFocusTab ];
};


export { useOpenOrFocusTab };
