/**
 * Apollo ws link
 *
 * @author: exode <hello@exode.ru>
 */

import { print } from 'graphql';
import { Client, ClientOptions, createClient } from 'graphql-ws';

import { ApolloLink } from '@apollo/client';
import { FetchResult, Observable, Operation } from '@apollo/client/core';


interface WsRestartableClient extends Client {
    restart: () => void;
}

/**
 * WebSocketLinkHelper
 */
export class WebSocketLinkHelper extends ApolloLink {

    private readonly client: WsRestartableClient;

    private restartRequested = false;

    private restart() {
        this.restartRequested = true;
    }

    constructor(options: ClientOptions) {
        super();
        const client = createClient({
            ...options,
            on: {
                ...options.on,
                opened: (socket: any) => {
                    options.on?.opened?.(socket);

                    this.restart = () => {
                        if (socket.readyState === WebSocket.OPEN) {
                            socket.close(4205, 'Client Restart');
                        } else {
                            this.restartRequested = true;
                        }
                    };

                    if (this.restartRequested) {
                        this.restartRequested = false;
                        this.restart();
                    }
                },
            },
        });

        this.client = {
            ...client,
            restart: () => this.restart(),
        };
    }

    get getClient() {
        return this.client;
    }

    request(operation: Operation): Observable<FetchResult> {
        return new Observable((sink) => (
            this.client.subscribe<FetchResult>(
                { ...operation, query: print(operation.query) },
                {
                    next: sink.next.bind(sink),
                    complete: sink.complete.bind(sink),
                    error: (err) => {
                        if (Array.isArray(err)) {
                            return sink.error(
                                new Error(err.map(({ message }) => message).join(', ')),
                            );
                        }

                        if (err instanceof CloseEvent) {
                            return sink.error(
                                new Error(`Socket closed with event ${err.code} ${err.reason || ''}`),
                            );
                        }

                        return sink.error(err);
                    },
                },
            )
        ));
    }
}
