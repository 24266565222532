/**
 * Use rating hook
 *
 * @author: exode <hello@exode.ru>
 */

import { useState } from 'react';

import { useI18n } from '@/hooks/core/useI18n';


const useRating = (initialRating?: number | null) => {

    const { t } = useI18n('hooks.core');

    const [ hover, setHover ] = useState(-1);
    const [ rate, setRate ] = useState<number | null>(initialRating ?? 0);

    const labels: { [key: number]: string } = {
        0: t('onRatingWait'),
        0.5: t('05WasteOfTime'),
        1.0: t('10Terrible'),
        1.5: t('15Bad'),
        2.0: t('20WontRecommend'),
        2.5: t('25WorseThanAverage'),
        3.0: t('30Average'),
        3.5: t('35NotBad'),
        4.0: t('40Good'),
        4.5: t('45Great'),
        5.0: t('50TheBest'),
    };

    return { rate, setRate, hover, setHover, labels };
};


export { useRating };
