/**
 * MenuTabHeaderPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Page } from '@/pages/Core';

import { PanelHeader } from '@exode.ru/vkui';
import { PreferenceThemeToggle } from '@/components/Preference/Theme';


const MenuTabHeaderPart = () => {

    const after = <PreferenceThemeToggle/>;

    return (
        <PanelHeader after={after} separator={false} className="tab-title-gradient">
            <Page.HeaderContentWrapper>
                <> exode.ru</>
            </Page.HeaderContentWrapper>
        </PanelHeader>
    );
};


export { MenuTabHeaderPart };
