/**
 * FooterBlock component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useEffect } from 'react';

import { IS_VK, IS_WEB } from '@/root/src/env';

import { observer, PageStore } from '@/store/core/page';

import { useI18n } from '@/hooks/core';

import { useLocation } from '@/router/index';
import { RoutePathType } from '@/router/paths';
import { If, Platform, Responsive, Router } from '@/cutils';

import { LanguageSwitcher } from '@/components/Atoms/LanguageSwitcher';

import { FooterPart } from './parts/FooterPart';
import { FooterLegalPart } from './parts/FooterLegalPart';

import { FooterWrapper, Wrapper } from './FooterBlock.styled';


const FooterBlock = observer(() => {

    const { t } = useI18n('components.Navigation.Block.Footer');

    const { route: { pageId } } = useLocation();

    const ignore: RoutePathType[] = [
        '/chat',
    ];

    useEffect(() => {
        PageStore.merge({ footer: !ignore.includes(pageId) });

        return () => {
            PageStore.merge({ footer: false });
        };
    }, [ pageId ]);

    return (
        <If is={IS_WEB && !IS_VK}>
            <Router ignore={ignore}>
                <div className="bg-page-initial">
                    <FooterWrapper className={[
                        'viewport bg-content-50 thin-border-top z-30',
                        'm:bg-gradient-to-t from-transparent to-gray-100 dark:to-gray-900',
                    ].join(' ')}>
                        <div className="viewport__wrapper flex-col max-w-[1200px]">
                            <Wrapper className={[
                                'd:grid grid-cols-4 gap-2 flex d:justify-around m:items-center m:flex-col',
                                'm:gap-8 p-4 pt-10',
                            ].join(' ')}>
                                <FooterPart/>
                            </Wrapper>

                            <Responsive.Mobile>
                                <div className="px-4">
                                    <LanguageSwitcher componentProps={{ className: 'thin-border vk-rounded' }}/>
                                </div>
                            </Responsive.Mobile>

                            <div className="p-4 d:pb-10 m:pb-16 fs-12">
                                <div className="flex d:justify-between m:flex-col">
                                    <FooterLegalPart/>
                                </div>

                                <Platform.Market>
                                    <div className="text-secondary mt-4 pb-safe">
                                        {t('copyrightProtected')}
                                    </div>
                                </Platform.Market>
                            </div>
                        </div>
                    </FooterWrapper>
                </div>
            </Router>
        </If>
    );
});


export { FooterBlock };
