/**
 * LegalDocsListMenuView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer, PlatformStore } from '@/store/platform';

import { Page } from '@/pages/Core';

import { getTranslated, useI18n } from '@/hooks/core';
import { Link, Responsive, Router, useRedirect } from '@/cutils';

import { Button, Cell, Header, List } from '@exode.ru/vkui';
import { Icon28ArticleOutline, Icon28BankOutline, Icon28MailOutline, Icon28ShareOutline } from '@vkontakte/icons';


const LegalDocsListMenuView = observer(() => {

    const { legalDocs } = PlatformStore;

    const { t } = useI18n('pages.Company.About');

    const { redirect } = useRedirect();

    return (
        <Responsive.DesktopContext>
            <Page.Row>
                <Header mode="secondary">
                    {t('legalInformation')}
                </Header>
                <List>
                    <Link pushModal={{ id: 'company-details' }}>
                        <Cell expandable before={<Icon28ArticleOutline/>} subtitle={t('companyDataContacts')}>
                            {t('companyRequisites')}
                        </Cell>
                    </Link>

                    <Link onClick={() => redirect(
                        getTranslated(legalDocs?.typical.DataConsentTransfer),
                        true,
                    )}>
                        <Cell expandable before={<Icon28ShareOutline/>} subtitle={t('dataConsentTransfer')}>
                            {t('dataTransfer')}
                        </Cell>
                    </Link>

                    <Link onClick={() => redirect(
                        getTranslated(legalDocs?.typical.DataConsentNews),
                        true,
                    )}>
                        <Cell expandable before={<Icon28MailOutline/>} subtitle={t('dataConsentNews')}>
                            {t('gettingMail')}
                        </Cell>
                    </Link>
                </List>
            </Page.Row>

            <Router ignore={[ '/about' ]}>
                <Link pushPage={{ id: '/about' }}>
                    <Button size="l" stretched mode="primary" before={<Icon28BankOutline/>}>
                        {t('aboutCompany')}
                    </Button>
                </Link>
            </Router>
        </Responsive.DesktopContext>
    );
});


export { LegalDocsListMenuView };
