/**
 * MainContentView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer, PlatformStore } from '@/store/platform';

import { Page } from '@/pages/Core';
import { useI18n } from '@/hooks/core';
import { Graphql, If, Link, Responsive } from '@/cutils';
import { CompanyGetApiVersionQuery } from '@/codegen/graphql';

import { APP_VERSION, BUILD_HASH } from '@/root/src/env';

import { Cell, ContentCard, Footer, Header, List, Spacing } from '@exode.ru/vkui';

import {
    Icon28BankOutline,
    Icon28BrainOutline,
    Icon28BugOutline,
    Icon28LifebuoyOutline,
    Icon28LightbulbStarOutline,
    Icon28NftHeptagonOutline,
} from '@vkontakte/icons';

import { CompanyMissionView } from './CompanyMissionView';
import { LegalDocsListMenuView } from './LegalDocsListMenuView';
import { CompanyHeaderPlaceholderView } from './CompanyHeaderPlaceholderView';


export const aboutCompanyText = (
    <>
        Мы стремимся сделать образование доступным для всех, независимо от местоположения или социального статуса.
        Объединяя учащихся, преподавателей и ученых со всего мира - мы создаем
        инклюзивное и вдохновляющее образовательное сообщество, способствующее личностному росту и прогрессу общества.

    </>
);


const MainContentView = observer(() => {

    const { COMMON } = PlatformStore;

    const { t } = useI18n('pages.Company.About.views');

    return (
        <>
            <Page.Row>
                <CompanyHeaderPlaceholderView/>
                
                <Responsive.Desktop>
                    <Footer className="mt-auto mb-4">
                        {t('appVersion', { version: APP_VERSION })}
                    </Footer>
                </Responsive.Desktop>
            </Page.Row>

            <Page.Row>
                <Responsive.Mobile>
                    <Link pushModal={{ id: 'company-about-text' }}>
                        <CompanyMissionView/>
                    </Link>
                </Responsive.Mobile>

                <Responsive.Desktop>
                    <CompanyMissionView/>
                </Responsive.Desktop>
            </Page.Row>

            <Page.Row className="text-white" pageLine="m">
                <ContentCard subtitle={<span className="text-white opacity-70">{t('ourMission')}</span>}
                             caption={<span className="text-white opacity-70">In exode we trust</span>}
                             className="text-white mx-2 mt-2 mb-4 shadow-lg bg-gradient-to-br from-purple-600 to-blue-700"
                             text={(
                                 <span className="text-white">
                                     {t('creatingInnovativeEducationalSocialNetwork')}
                                 </span>
                             )}/>

                <ContentCard subtitle={<span className="text-white opacity-70">Open source</span>}
                             caption={<span className="text-white opacity-70">Open source we love </span>}
                             className="text-white mx-2 mb-2 shadow-lg bg-gradient-to-br from-green-400 to-green-700"
                             text={(
                                 <span className="text-white">
                                     {t('exodeIsTechnologicalCompany')}
                                 </span>
                             )}/>
            </Page.Row>

            <LegalDocsListMenuView/>

            <Page.Row>
                <Header mode="secondary">{t('additional')}</Header>
                <List>
                    <Link pushPage={{ id: '/support' }}>
                        <Cell expandable
                              before={<Icon28LifebuoyOutline/>}
                              subtitle={t('ifYouHaveAnyQuestionsOrDifficulties')}>
                            {t('support')}
                        </Cell>
                    </Link>

                    <Link toOuter="https://t.me/bakha_17">
                        <Cell expandable before={<Icon28BankOutline/>} subtitle={t('contactManagement')}>
                            {t('forInvestors&Partners')}
                        </Cell>
                    </Link>

                    <Link toOuter="https://exode.betteruptime.com/">
                        <Cell expandable
                              before={<Icon28NftHeptagonOutline/>}
                              subtitle={t('availabilityOfExodeInTheWorld')}>
                            {t('serverStatuses')}
                        </Cell>
                    </Link>

                    <Link toOuter={COMMON?.organization.info.messengerSupportUrl}>
                        <Cell expandable before={<Icon28BugOutline/>} subtitle={t('youCanGetCoolBonusesForThis')}>
                            {t('becomingTesterInExode')}
                        </Cell>
                    </Link>

                    <Link toOuter={COMMON?.organization.info.messengerSupportUrl}>
                        <Cell expandable before={<Icon28BrainOutline/>} subtitle={t('doYouWantToBeWithNumberOnes?')}>
                            {t('becomePartOfTheTeam')}
                        </Cell>
                    </Link>

                    <Link toOuter={COMMON?.organization.info.messengerSupportUrl}>
                        <Cell expandable
                              before={<Icon28LightbulbStarOutline/>}
                              subtitle={t('doYouWantToOfferKillerFeature?')}>
                            {t('iHaveBrilliantIdea')}
                        </Cell>
                    </Link>
                </List>
            </Page.Row>

            <Spacing size={16}/>

            <Footer className="mt-auto mb-6">
                {t('appVersion', { version: APP_VERSION })}
                <If is={!!BUILD_HASH}>
                    <br/><br/>

                    <span className="fs-10">
                        {t('buildNumber', { hash: BUILD_HASH })}
                    </span>

                    <br/>

                    <CompanyGetApiVersionQuery children={(result) => (
                        <>
                            <Graphql.Loading result={result}>
                                <>###</>
                            </Graphql.Loading>

                            <Graphql.Success result={result}>
                                {({ companyGetApiVersion: apiVersion }) => (
                                    <span className="fs-10">API: {apiVersion}</span>
                                )}
                            </Graphql.Success>
                        </>
                    )}/>

                </If>
            </Footer>
        </>
    );
});


export { MainContentView };
