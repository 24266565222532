/**
 * DownloadAppBanner
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { IS_VK, IS_WEB } from '@/root/src/env';

import { useLocation } from '@/router/index';
import { useBanner, useI18n } from '@/hooks/core';

import { RoutePathType } from '@/router/paths';
import { PromoBannerLsKey } from '@/types/promotion';
import { LandingRoutes } from '@/router/routes/Landing';

import { If, Link, Responsive } from '@/cutils';
import { Button, Text } from '@exode.ru/vkui';

import styles from '../style.module.scss';


interface Props {
    showTabbar: boolean;
}


const DownloadAppBanner = (props: Props) => {

    const { t } = useI18n('components.Promotion.MobileBanners.DownloadAppBanner');

    const { route: { pageId, params } } = useLocation();

    const { show, closeBanner } = useBanner(
        'promo:banners',
        PromoBannerLsKey.DownloadAppReminderInWeb,
    );

    const blackListPages: RoutePathType[] = [
        '/',
        '/login',
        '/catalog',
        '/login/app',
        '/login/email/login',

        /** All landing keys */
        ..._.keys(LandingRoutes) as RoutePathType[],
    ];

    const blackListParams: string[] = [
        'no-download-app-banner',
    ];

    const isAllowedPage = !blackListPages.includes(pageId as RoutePathType)
        && blackListParams.every(param => _.isNil(params[param]));

    return (
        <If is={IS_WEB && !IS_VK && show && isAllowedPage}>
            <Responsive.Mobile>
                <div className={`sticky ${props.showTabbar ? styles.withoutTabbarBottom : styles.withTabbarBottom} left-0 right-0 z-[100] flex `
                    + `justify-between items-center p-4 blue-light-blue-gradient`}>
                    <div>
                        <Text weight="2" className="text-white">
                            {t('itsMoreConvenientInTheApp')}
                        </Text>

                        <Text weight="3" className="text-white fs-12 underline" onClick={closeBanner}>
                            {t('continueInTheBrowser')}
                        </Text>
                    </div>

                    <Link pushPage={{ id: '/app' }}>
                        <Button onClick={closeBanner} size="s" mode="overlay_primary">
                            <span className="text-accent">
                                {t('open')}
                            </span>
                        </Button>
                    </Link>
                </div>
            </Responsive.Mobile>
        </If>
    );
};


export { DownloadAppBanner };
