/**
 * NotParticipantView
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useState } from 'react';

import { Page } from '@/pages/Core';
import { useI18n } from '@/hooks/core';
import { Graphql, If } from '@/cutils';
import { CourseFindOneQuery } from '@/codegen/graphql';

import { Caption, Header, Text, Title } from '@exode.ru/vkui';
import { Icon16ArticleBoxOutline, Icon16Sparkle, Icon28DiamondOutline, Icon28GiftOutline } from '@vkontakte/icons';

import { PricesView } from '@/pages/Course/Information/views/PricesView';
import { EnrollBannerItem } from '@/pages/Course/Information/items/EnrollBannerItem';

import { Placeholder } from '@/components/Atoms/Placeholder';
import { LessonCard, LessonCardInfoWrapper, LessonCardProps } from '@/components/Course/LessonCard';


interface Props {
    mode: 'no-access' | 'offer';
    placement: 'modal' | 'page';
    courseId: number;
    lessonId: number;
    hideHeader?: boolean;
}


const NotParticipantView = (props: Props) => {

    const { mode, placement, courseId, lessonId, hideHeader } = props;

    const { t } = useI18n('pages.Course.Study.views');

    const [ lesson, setLesson ] = useState<LessonCardProps['lesson'] | undefined>();

    return (
        <>
            <CourseFindOneQuery onCompleted={({ courseFindOne: course }) => {
                setLesson(course.lessons?.find(({ id }) => lessonId === id) as LessonCardProps['lesson']);
            }} children={(result) => (
                <>
                    <Graphql.Loading result={result}>
                        <Page.Spinner/>
                    </Graphql.Loading>

                    <Graphql.Success result={result}>
                        {({ courseFindOne: course }) => (
                            <>
                                {/** Paid and mode === 'no-access' and not hideHeader: */}
                                <If is={mode === 'no-access' && !hideHeader && !course.product.isFree}>
                                    {/** Placement: for modal */}
                                    <If is={placement === 'modal'}>
                                        <Page.Row className="header-aside-top-5">
                                            <div className="d:mt-2"/>

                                            <Text className={[
                                                'flex items-center justify-center mt-4',
                                                'text-center px-3 fs-14 d:mb-5',
                                            ].join(' ')}>
                                                <span className="text-marker">
                                                    {t('chooseTariffToAccessAllLessons')}
                                                </span>
                                            </Text>
                                        </Page.Row>
                                    </If>

                                    {/** Placement: for page */}
                                    <If is={placement === 'page'}>
                                        <Page.Row>
                                            <Placeholder iconSize={96}
                                                         innerPadding="0px"
                                                         className="my-auto"
                                                         header={t('availableRates')}
                                                         content={t('chooseTariffToAccessAllLessons')}
                                                         icon={(
                                                             <div className="relative">
                                                                 <Icon16Sparkle width={12}
                                                                                height={12}
                                                                                className="absolute"
                                                                                fill="var(--dynamic_purple)"/>

                                                                 <Icon28DiamondOutline width={96}
                                                                                       height={96}
                                                                                       fill="var(--accent)"/>

                                                                 <Icon16Sparkle fill="var(--dynamic_purple)"
                                                                                className="absolute right-0 bottom-2.5"/>
                                                             </div>
                                                         )}/>
                                        </Page.Row>
                                    </If>
                                </If>

                                {/** Free: */}
                                <If is={course.product.isFree}>
                                    <Page.Row>
                                        <Placeholder iconSize={96}
                                                     innerPadding="0px"
                                                     className="my-auto"
                                                     header={t('oneClick')}
                                                     content={t('enrollAndStartEducation')}
                                                     icon={(
                                                         <div className="relative">
                                                             <Icon16Sparkle width={12}
                                                                            height={12}
                                                                            className="absolute"
                                                                            fill="var(--dynamic_purple)"/>

                                                             <Icon28GiftOutline width={96}
                                                                                height={96}
                                                                                fill="var(--accent)"/>

                                                             <Icon16Sparkle fill="var(--dynamic_purple)"
                                                                            className="absolute right-0 bottom-2.5"/>
                                                         </div>
                                                     )}/>
                                    </Page.Row>
                                </If>

                                <div className="flex flex-col gap-4">
                                    <If is={!course.product.isFree}>
                                        <If is={mode === 'offer'}>
                                            <div className="text-center">
                                                <If is={placement === 'page'}>
                                                    <Header mode="primary" className="flex justify-center">
                                                        <Title level="2" weight="3">
                                                            {t('availableRates')}
                                                        </Title>
                                                    </Header>
                                                </If>

                                                <Text className="mt-4 mb-2">
                                                    <span className="text-marker">
                                                        {(
                                                            lesson
                                                                ? t('chooseTariffToAccessThisAndOtherLessons')
                                                                : t('chooseTariffToAccessAllLessons')
                                                        )}
                                                    </span>
                                                </Text>
                                            </div>
                                        </If>
                                    </If>

                                    <If is={!!lesson}>
                                        <LessonCardInfoWrapper fullWidthOnDesktop={placement === 'page'}>
                                            <LessonCard active
                                                        course={course}
                                                        className="bg-content"
                                                        lesson={lesson as LessonCardProps['lesson']}/>

                                            <Caption className="break-words mt-4 mb-2 flex gap-2 text-secondary">
                                                <div className="min-w-[16px]">
                                                    <Icon16ArticleBoxOutline fill="var(--dynamic_gray)"/>
                                                </div>

                                                {lesson?.description}
                                            </Caption>
                                        </LessonCardInfoWrapper>
                                    </If>

                                    <If is={!course.product.isFree}>
                                        <PricesView flexColReverse noHeader={mode === 'offer'} course={course}/>
                                    </If>

                                    <If is={course.product.isFree}>
                                        <Page.Row>
                                            <div className={[
                                                'p-4 pt-0',
                                                placement === 'modal' ? 'pb-2' : 'pb-3',
                                            ].join(' ')}>
                                                <EnrollBannerItem course={course}/>
                                            </div>
                                        </Page.Row>
                                    </If>
                                </div>
                            </>
                        )}
                    </Graphql.Success>

                    <Graphql.Error result={result}/>
                </>
            )} variables={{ courseId }}/>
        </>
    );
};


export { NotParticipantView };
