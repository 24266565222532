/**
 * Countdown
 *
 * @author: exode <hello@exode.ru>
 */

import moment from 'moment';

import React from 'react';

import ReactCountdown, { CountdownProps } from 'react-countdown';

import { useConfigContext } from '@/hooks/core';


interface Props extends CountdownProps {

}


const Countdown = (props: Props) => {

    const { state: { clientTimeOffset } } = useConfigContext();

    return (
        <ReactCountdown {...props} date={moment(props.date).add(clientTimeOffset, 'milliseconds').toDate()}/>
    );
};


export { Countdown };
