/**
 * CourseLessonInput
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { IsString, MinLength, ValidateIf } from 'class-validator';

import { isMinMax } from '@/libs/class-validator/constants';


export class CourseLessonInput {

    @IsString()
    @MinLength(5, { message: isMinMax(5, 'Тема урока', 'min', 'должна') })
    readonly name: string;

    @IsString()
    @ValidateIf((__, v) => !_.isNil(v))
    @MinLength(10, { message: isMinMax(10, 'Описание', 'min', 'должно') })
    readonly description: string;

}

